import axios from "axios";
import {getVatRates} from "../../../SharedComponents/calculations/GetVatApiCall";

export async function editVAT(setOpenEditVat, projectId, setProducts, setExpenses, setVatRates) {

  await getTopLevelProducts(projectId, setProducts)
  await getTopLevelOverheads(projectId, setExpenses)
  await getVatRates(projectId, setVatRates)

  setOpenEditVat(true);

}

async function getTopLevelProducts(projectId, setProducts) {

  var config = {
    method: "get",
    url: "revenuecos/" + projectId,
  };

  await axios(config)
    .then(function (response) {
      //list of products
      setProducts(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

}

async function getTopLevelOverheads(projectId, setExpenses) {

  var config = {
    method: "get",
    url: "overheads/" + projectId,
  };

  await axios(config)
    .then(function (response) {
      //list of products
      setExpenses(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

}