import _ from "lodash";
import {getCurrentProject} from "../ProjectServices";
import {parseNumberOrZero} from "../utils/NumbersUtils";
import {FixedAssetsType} from "../Types";


function calculateCorkscrewForMonth(assumption, assumptionType, i) {
  assumption[assumptionType.categoryListProperty][0][assumptionType.monthListProperty][i].value = assumption[assumptionType.categoryListProperty]
    .find((category) => category.name === "Closing")[assumptionType.monthListProperty][i - 1].value;

  const closing = assumption[assumptionType.categoryListProperty].find((category) => category.name === "Closing");
  const closingIndex = assumption[assumptionType.categoryListProperty].indexOf(closing);

  let total = 0;
  for (let j = 0; j < closingIndex; j++) {
    total += assumption[assumptionType.categoryListProperty][j][assumptionType.monthListProperty][i].value;
  }

  closing[assumptionType.monthListProperty][i].value = parseNumberOrZero(total);
}

/**
 * FOR BALANCE ASSUMPTIONS ONLY
 * (FOR PNL THIS WILL NOT WORK AS ITS LOOKING AT THE BALANCE REFERENCE POINT)
 * @param assumptions
 * @param assumptionType
 */
export function calculateCorkscrew(assumptions, assumptionType) {

  // all the assumptions that are type 'SIMPLE_CORKSCREW'
  const assumptionCorkScrewList = assumptions
    .filter(assumption => assumption[assumptionType.dtoProperty].assumption === "SIMPLE_CORKSCREW");

  // calculate closing value
  // Opening + additions + deductions = closing. This is called a CORKSCREW
  _.forEach(assumptionCorkScrewList, function (assumption) {
    if(assumptionType !== FixedAssetsType) {
     orderSimpleCorkscrewRows(assumption, assumptionType);
    }

    // loop through all 72 months and run the calculations
    _.forEach(assumption[assumptionType.categoryListProperty][0][assumptionType.monthListProperty], function (assumptionMonthDto, i) {

      // only for forecast values
      if (i > getCurrentProject().firstBalanceForecast - 1) {
        calculateCorkscrewForMonth(assumption, assumptionType, i);
      }
    });
  });
}

export function orderSimpleCorkscrewRows(assumption, type) {
  const categories = assumption[type.categoryListProperty];
  const adjustments = categories.filter((category) => category.name === "Adjustment");
  assumption[type.categoryListProperty] = [
    ...categories.slice(0, 3),
    ...adjustments,
    categories.find((category) => category.name.includes("Closing"))
  ];
}