import _ from "lodash";
import {BalanceType, CovenantType, HeadcountType, PNLType, ProductsType} from "../Types";

export function addValueToRestOfYear(cellData, overheads) {
  //loop through the revenue category and sum up the totals per year

  if ([12,24,36,48,60].includes(cellData.monthIndex)) {

    var allValuesZero = checkValuesForYearAreZero(cellData, overheads);

    _.forEach(overheads, function (overhead) {

        if (overhead.overheadDto.id === cellData.overhead) {

          _.forEach(overhead.overheadCategoryDtoList, function (overheadCategoryDto) {

              if (overheadCategoryDto.id === cellData.category) {

                _.forEach(overheadCategoryDto.overheadMonthDtoList, function (overheadMonthDto) {

                    if (overheadMonthDto.monthIndex > cellData.monthIndex
                      && overheadMonthDto.monthIndex <= (cellData.monthIndex + 11)) {

                      if (allValuesZero) {
                        overheadMonthDto.value = cellData.value;
                      }

                    }
                  }
                );
              }
            }
          );
        }
      }
    );
  }

}

function checkValuesForYearAreZero(cellData, overheads) {
  //loop through the revenue category and sum up the totals per year

  var allZeros = true;

  if ([12,24,36,48,60].includes(cellData.monthIndex)) {

    _.forEach(overheads, function (overhead) {

        if (overhead.overheadDto.id === cellData.overhead) {

          _.forEach(overhead.overheadCategoryDtoList, function (overheadCategoryDto) {

              if (overheadCategoryDto.id === cellData.category) {

                _.forEach(overheadCategoryDto.overheadMonthDtoList, function (overheadMonthDto) {

                    if (overheadMonthDto.monthIndex > cellData.monthIndex
                      && overheadMonthDto.monthIndex <= (cellData.monthIndex + 11)) {

                      if (overheadMonthDto.value !== 0) {
                        allZeros = false;
                      }

                    }
                  }
                );
              }
            }
          );
        }
      }
    );
  }

  return allZeros;
}

export function identifyPotentialError(cellData, assumption, type) {
  let item;
  if (type === ProductsType) {
    item = assumption[type.categoryListProperty]
      .filter((category) => ["Direct product costs", "Revenue", "Closing recurring revenue"].includes(category.name))
      .flatMap(category =>
        category[type.monthListProperty]
          .filter(dto => dto.id === cellData.id)
          .map(dto => ({ dto, category }))
      );
    if(item.length > 0) {
      const dtoAndCategory = item[0]
      if(dtoAndCategory.category.name === "Direct product costs") {
        return cellData.value > 0 || cellData.valueOverriden > 0;
      } else {
        return cellData.value < 0 || cellData.valueOverriden < 0;
      }
    }
  } else if(type === HeadcountType) {
    const categories = ["Total base salary costs"];

    if(assumption[HeadcountType.dtoProperty].assumption !== "PRICE_X_QUANTITY") {
      categories.push("Price");
    }

    item = assumption[type.categoryListProperty]
      .filter((category) => categories.includes(category.name))
      .flatMap(category =>
        category[type.monthListProperty]
          .filter(dto => dto.id === cellData.id)
          .map(dto => ({ dto, category }))
      );
  } else if(type && ![PNLType, CovenantType, BalanceType].includes(type)) {
    item = assumption[type.categoryListProperty]
      .slice(-1)
      .flatMap(category =>
        category[type.monthListProperty]
          .filter(dto => dto.id === cellData.id)
      );
  }

  if (item && item.length > 0) {
    if (type.isPositive) {
      if (cellData.value < 0 || cellData.valueOverriden < 0) {
        return true;
      }
    } else {
      if (cellData.value > 0 || cellData.valueOverriden > 0) {
        return true;
      }
    }
  }


  return false;
}