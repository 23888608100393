import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TableCell} from "@mui/material";
import React from "react";


export default function CellKey({open, setOpen}) {
  const tableStyling = {
    minWidth: 90,
    paddingTop: 0,
    paddingBottom: 0,
    marginRight: 1,
    marginBottom: 2,
    textAlign: "right",
    fontWeight: "bolder",
  };

  return (
    <Dialog open={open}
            onClose={(event, reason) => {
              setOpen(false)
            }}
    >
      <DialogTitle>
        Cell Key
      </DialogTitle>
      <DialogContent>
        <div style={{display: "flex"}}>
          <TableCell
            variant={"body"}
            align={"right"}
            sx={{
              ...tableStyling,
              backgroundColor: "#fff2cc",
            }}
          >
            100
          </TableCell>
          Actual
        </div>

        <div style={{display: "flex"}}>
          <TableCell
            variant={"body"}
            align={"right"}
            sx={{
              ...tableStyling,
              backgroundColor: "#E2EFDA",
            }}
          >
            100
          </TableCell>
          Forecast
        </div>

        <div style={{display: "flex"}}>
          <TableCell
            variant={"body"}
            align={"right"}
            sx={{
              ...tableStyling,
              backgroundColor: "#cbc1a2",
            }}
          >
            100
          </TableCell>
          Opening B.S figures
        </div>

        <div style={{display: "flex"}}>
          <TableCell
            variant={"body"}
            align={"right"}
            sx={{
              ...tableStyling,
              backgroundColor: "#f2f2f2",
            }}
          >
            100
          </TableCell>
          Calculated
        </div>

        <div style={{display: "flex"}}>
          <TableCell
            variant={"body"}
            align={"right"}
            sx={{
              ...tableStyling,
              backgroundColor: "#f2f2f2",
              outline: "2px solid blue"
            }}
          >
            100
          </TableCell>
          Can be overridden (hover)
        </div>

        <div style={{display: "flex"}}>
          <TableCell
            variant={"body"}
            align={"right"}
            sx={{
              ...tableStyling,
              backgroundColor: "#FFAF80",
            }}
          >
            100
          </TableCell>
          Has been overridden
        </div>

        <div style={{display: "flex"}}>
          <TableCell
            variant={"body"}
            align={"right"}
            sx={{
              ...tableStyling,
              backgroundColor: "#f2f2f2",
              border: "2px solid #fa6173"
            }}
          >
            100
          </TableCell>
          Potential error
        </div>


      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setOpen(false)
        }} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>

  )
}