import {calculateCorkscrew} from "../../../SharedComponents/calculations/SimpleCorkscrew";
import {EquityType} from "../../../SharedComponents/Types";


/**
 *   for the first 12 months (actuals, we AREN'T applying the calculations)
 *   as these are actual values
 *   we ONLY apply the calculations for the forecast values onwards.
 * @param equity
 */
export function calculateEquity(equity) {
  calculateCorkscrew(equity, EquityType);
}
