import {calculateCorkscrew} from "../../../SharedComponents/calculations/SimpleCorkscrew";
import _ from "lodash";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { calculatePercentageOfTotal } from "../../../SharedComponents/utils/PercentageUtils";
import { createDecimal, parseNumberOrZero } from "../../../SharedComponents/utils/NumbersUtils";
import {DebtType} from "../../../SharedComponents/Types";


function calculateClosingBorrowings(availability, amountDrawn, facilityLimit) {

  if (facilityLimit === 0) {
    return 0;
  }

  if (availability > 0) {
    availability = -availability;
  }

  if (facilityLimit > 0) {
    facilityLimit = -facilityLimit;
  }

  // Use Math.min to find the lowest number among the parameters
  const minBorrowing = Math.max(availability, amountDrawn, facilityLimit);
  return minBorrowing;
}


function calculateCIDDebt(debts, balance) {

  const cidDebts = debts
    .filter(debt => debt.debtDto.assumption === "CID_DEBT");

  // calculate closing value
  // Opening + additions + deductions = closing. This is called a CORKSCREW
  _.forEach(cidDebts, function(assumption) {

    let balanceTradeDebtors = balance.balanceCategoryDtos.find(category => category.overrideName === "Trade debtors");

    let calculatedDebtorsCategory = assumption.debtCategoryDtos.find(category => category.name === 'Calculated debtors');
    let ineligibleCategory = assumption.debtCategoryDtos.find(category => category.name === 'Ineligible');
    let prepaymentsCategory = assumption.debtCategoryDtos.find(category => category.name === 'Prepayment');
    let disallowablesCategory = assumption.debtCategoryDtos.find(category => category.name === 'Disallowables');
    let availabilityDrawnCategory = assumption.debtCategoryDtos.find(category => category.name === 'Availability Drawn');
    let facilityLimitCategory = assumption.debtCategoryDtos.find(category => category.name === 'Facility Limit');
    let availabilityCategory = assumption.debtCategoryDtos.find(category => category.name === 'Availability');
    let closingBorrowingsCategory = assumption.debtCategoryDtos.find(category => category.name === 'Closing Borrowings');
    let interestChargeCategory = assumption.debtCategoryDtos.find(category => category.name === 'Interest Charge');
    let interestChargesCategory = assumption.debtCategoryDtos.find(category => category.name === 'Interest Charges'); //notice the plural, dont get confused with Interest Charge with no (s)

    _.forEach(assumption.debtCategoryDtos[0].debtMonthDtos, function(assumptionMonthDto, i) {

        calculatedDebtorsCategory.debtMonthDtos[i].value = balanceTradeDebtors.balanceMonthDtos[i +1].value;

        //get the calculated debtors value
        let calculatedDebtors = calculatedDebtorsCategory.debtMonthDtos[i].value;

        //get the ineligible value
        let ineligible = ineligibleCategory.debtMonthDtos[i].value;

        //work out the percentage value of the debtors from the ineligibles.
        let calculatedDebtorsMinusIneligables = calculatedDebtors - (calculatedDebtors / 100 * ineligible);

        //then get the prepayments value
        let prepayments = prepaymentsCategory.debtMonthDtos[i].value;

        let disallowables = disallowablesCategory.debtMonthDtos[i].value;

        //work out the percentage value of the prepayments of the new value
        let calculatedDebtorsMinusPrepayments = ((calculatedDebtorsMinusIneligables - disallowables) / 100) * prepayments;

        let availabilityDrawn = availabilityDrawnCategory.debtMonthDtos[i].value;

        let facilityLimit = facilityLimitCategory.debtMonthDtos[i].value;

        //set the availability
        availabilityCategory.debtMonthDtos[i].value = calculatedDebtorsMinusPrepayments; //6 = Availability

        let availability = availabilityCategory.debtMonthDtos[i].value;

        //set the closing borrowing
        // only for forecast values
        if (i >= getCurrentProject().firstBalanceForecast) {
          closingBorrowingsCategory.debtMonthDtos[i].value = calculateClosingBorrowings(availability, availabilityDrawn, facilityLimit)
        }
        let interestCharge = interestChargeCategory.debtMonthDtos[i].value;

        //finally calculate the Interest Charges
        let interestCharges = (closingBorrowingsCategory.debtMonthDtos[i].value / 100 * interestCharge) / 12;

        interestChargesCategory.debtMonthDtos[i].value = interestCharges;

    });
  });

}

function calculateLoan(debts) {

  const loanDebts = debts
    .filter(debt => debt.debtDto.assumption === "LOAN_DEBT");

  // calculate closing value
  // Opening + additions + deductions = closing. This is called a CORKSCREW
  _.forEach(loanDebts, function(loanDebt) {

    let annualBaseRateCategory = loanDebt.debtCategoryDtos.find(category => category.name === 'Annual Base Rate %');
    let annualInterestRateCategory = loanDebt.debtCategoryDtos.find(category => category.name === 'Annual Interest Rate %');
    let interestChargeCategory = loanDebt.debtCategoryDtos.find(category => category.name === 'Interest Charge');
    let openingCategory = loanDebt.debtCategoryDtos.find(category => category.name === 'Opening');
    let additionCategory = loanDebt.debtCategoryDtos.find(category => category.name === 'Addition');
    let deductionCategory = loanDebt.debtCategoryDtos.find(category => category.name === 'Deduction');
    let closingCategory = loanDebt.debtCategoryDtos.find(category => category.name === 'Closing');

    _.forEach(loanDebt.debtCategoryDtos[0].debtMonthDtos, function(assumptionMonthDto, i) {

      // only do the Corkscrew for forecast values
      if (i > getCurrentProject().firstBalanceForecast - 1) {

        openingCategory.debtMonthDtos[i].value = closingCategory.debtMonthDtos[i - 1].value;

        //get the value of the closing, from the previous month
        closingCategory.debtMonthDtos[i].value =
          openingCategory.debtMonthDtos[i].value +
          additionCategory.debtMonthDtos[i].value +
          deductionCategory.debtMonthDtos[i].value;
      }

      // Calculate the interest for every month, including the Actuals
      let totalInterest = annualBaseRateCategory.debtMonthDtos[i].value //base rate
        + annualInterestRateCategory.debtMonthDtos[i].value //interest rate


      let interestCharges = (closingCategory.debtMonthDtos[i].value / 100 * totalInterest) / 12;

      //let totalInterestPerMonth = divideBy12(loanDebt.debtCategoryDtos[7].debtMonthDtos[i].value);


      //Set the "Interest Charge" row
      interestChargeCategory.debtMonthDtos[i].value = interestCharges;
        //-Math.abs(calcPercent(totalInterestPerMonth, totalInterest));

    });
  });

  //
  
}

/**
 * Method to calculate piks
 *
 * I've tried to avoid loops with this method (slightly different to the rest of the calculations)
 *
 * @param debts
 */
function calculatePik(debts) {

  //get the pik debts
  const pikDebts = debts
    .filter(debt => debt.debtDto.assumption === "PIK_DEBT");

  //for each pik debt
  _.forEach(pikDebts, function(pikDebt) {

    let principleCategory = pikDebt.debtCategoryDtos.find(category => category.name === "Principle being PIK'd")

    let pikRateCategory = pikDebt.debtCategoryDtos.find(category => category.name === "PIK rate%")

    let pikCategory = pikDebt.debtCategoryDtos.find(category => category.name === "PIK")

    let newPikCategory = pikDebt.debtCategoryDtos.find(category => category.name === "New PIK")

    //set the Principle being PIK'd row by totally up all the values for the OPENINGS for each selected debt
    const principalOpeingTotals = principalsTotalMonths(debts, pikDebt);
    if (pikDebt.debtDto.pikOnPik) {
      principleCategory.debtMonthDtos.forEach((monthDto, index) => monthDto.value = principalOpeingTotals[index]
        + pikDebt.debtCategoryDtos[4].debtMonthDtos[index].value);
    } else {
      principleCategory.debtMonthDtos.forEach((monthDto, index) => monthDto.value = principalOpeingTotals[index]);
    }


    //calculate the Pik for each row and set that
    if (pikDebt.debtDto.pikOnPik) {
      pikCategory.debtMonthDtos.forEach((monthDto, index) => monthDto.value = calculatePercentageOfTotal(pikRateCategory.debtMonthDtos[index].value, principalOpeingTotals[index]) / 12
        + (calculatePercentageOfTotal(pikDebt.debtCategoryDtos[1].debtMonthDtos[index].value, pikDebt.debtCategoryDtos[4].debtMonthDtos[index].value) / 12)); //(all debt openings added together) + Opening (In corkscrew) + (10% (PIK Rate%) Of the Opening / 12);
    } else {
      pikCategory.debtMonthDtos.forEach((monthDto, index) => monthDto.value = calculatePercentageOfTotal(pikRateCategory.debtMonthDtos[index].value, principalOpeingTotals[index]) / 12);
    }

    //copy over the PIK category into the New PIK category
    newPikCategory.debtMonthDtos.forEach((monthDto, index) => monthDto.value = pikCategory.debtMonthDtos[index].value);

    //now do the corkscrew at the bottom of the pik
    _.forEach(pikDebt.debtCategoryDtos[0].debtMonthDtos, function(pikMonths, i) {
      // only for forecast values
      if (i > getCurrentProject().firstBalanceForecast - 1) {

        pikDebt.debtCategoryDtos[4].debtMonthDtos[i].value = pikDebt.debtCategoryDtos[7].debtMonthDtos[i - 1].value;

        //get the value of the closing, from the previous month
        pikDebt.debtCategoryDtos[7].debtMonthDtos[i].value = parseNumberOrZero(

          createDecimal(pikDebt.debtCategoryDtos[4].debtMonthDtos[i].value).plus(
            createDecimal(pikDebt.debtCategoryDtos[5].debtMonthDtos[i].value)).plus(
            createDecimal(pikDebt.debtCategoryDtos[6].debtMonthDtos[i].value)).toString()

        );


      }
    });

  });

}

/**
 * set the Principle being PIK'd row by totally up all the values for the closing for each selected debt
 *
 * Creates an array of totals for closing values for each debt in the array
 *
 * @param debts
 * @param pikDebt
 * @returns {*[]}
 */
const principalsTotalMonths = (debts, pikDebt) => {

  // Find the first debt with a 'Closing' category to determine the number of months
  const firstClosingDebt = debts.find(debt =>
    debt.debtCategoryDtos.some(category => category.name === 'Opening')
  );
  const totalMonths = firstClosingDebt?.debtCategoryDtos
    .find(category => category.name === 'Opening')?.debtMonthDtos.length || 0;

  // Calculate the totals for each month
  return Array.from({ length: totalMonths }, (_, monthIndex) =>
    debts
      .filter(debt => pikDebt.debtDto.debts.includes(debt.debtDto.id))
      .reduce((acc, debt) => {
        const closingValue = debt.debtCategoryDtos
          .find(category => category.name === 'Opening')
          ?.debtMonthDtos[monthIndex]?.value || 0;
        return acc + closingValue;
      }, 0)
  );
};


function calculateABLDebt(debts, balance) {

  const ablDebts = debts
    .filter(debt => debt.debtDto.assumption === "ABL_DEBT");

  // calculate closing value
  // Opening + additions + deductions = closing. This is called a CORKSCREW
  _.forEach(ablDebts, function(assumption) {

    let balanceCategoriesChosen = balance.balanceCategoryDtos.filter(category => assumption.debtDto.ablAssets.includes(category.id));

    let calculatedDebtorsCategory = assumption.debtCategoryDtos.find(category => category.name === 'Calculated assets');
    let ineligibleCategory = assumption.debtCategoryDtos.find(category => category.name === 'Ineligible');
    let prepaymentsCategory = assumption.debtCategoryDtos.find(category => category.name === 'Prepayment');
    let disallowablesCategory = assumption.debtCategoryDtos.find(category => category.name === 'Disallowables');
    let availabilityDrawnCategory = assumption.debtCategoryDtos.find(category => category.name === 'Availability Drawn');
    let facilityLimitCategory = assumption.debtCategoryDtos.find(category => category.name === 'Facility Limit');
    let availabilityCategory = assumption.debtCategoryDtos.find(category => category.name === 'Availability');
    let closingBorrowingsCategory = assumption.debtCategoryDtos.find(category => category.name === 'Closing Borrowings');
    let interestChargeCategory = assumption.debtCategoryDtos.find(category => category.name === 'Interest Charge');
    let interestChargesCategory = assumption.debtCategoryDtos.find(category => category.name === 'Interest Charges'); //notice the plural, dont get confused with Interest Charge with no (s)

    //sum up all the balance categories
    let results = new Array(72).fill(0);
    if (balanceCategoriesChosen !== undefined) {

      //go through each category and increment the totals
      _.forEach(balanceCategoriesChosen, function(category, y) {

        _.forEach(category.balanceMonthDtos, function(month, i) {

          if (i !== 0) { //skip the opening balance

            results[i-1] += month.value;


          }

        });
      });
    }



    _.forEach(assumption.debtCategoryDtos[0].debtMonthDtos, function(assumptionMonthDto, i) {

      calculatedDebtorsCategory.debtMonthDtos[i].value = results[i];

      //get the calculated debtors value
      let calculatedDebtors = calculatedDebtorsCategory.debtMonthDtos[i].value;

      //get the ineligible value
      let ineligible = ineligibleCategory.debtMonthDtos[i].value;

      //work out the percentage value of the debtors from the ineligibles.
      let calculatedDebtorsMinusIneligables = calculatedDebtors - (calculatedDebtors / 100 * ineligible);

      //then get the prepayments value
      let prepayments = prepaymentsCategory.debtMonthDtos[i].value;

      let disallowables = disallowablesCategory.debtMonthDtos[i].value;

      //work out the percentage value of the prepayments of the new value
      let calculatedDebtorsMinusPrepayments = ((calculatedDebtorsMinusIneligables - disallowables) / 100) * prepayments;

      let availabilityDrawn = availabilityDrawnCategory.debtMonthDtos[i].value;

      let facilityLimit = facilityLimitCategory.debtMonthDtos[i].value;

      //set the availability
      availabilityCategory.debtMonthDtos[i].value = calculatedDebtorsMinusPrepayments; //6 = Availability

      let availability = availabilityCategory.debtMonthDtos[i].value;

      //set the closing borrowing
      // only for forecast values
      if (i >= getCurrentProject().firstBalanceForecast) {
        closingBorrowingsCategory.debtMonthDtos[i].value = calculateClosingBorrowings(availability, availabilityDrawn, facilityLimit)
      }
      let interestCharge = interestChargeCategory.debtMonthDtos[i].value;

      //finally calculate the Interest Charges
      let interestCharges = (closingBorrowingsCategory.debtMonthDtos[i].value / 100 * interestCharge) / 12;

      interestChargesCategory.debtMonthDtos[i].value = interestCharges;

    });
  });
}

export function calculateDebt(debt, balance) {
  try {
    calculateCorkscrew(debt, DebtType);
    calculateLoan(debt);
    calculateCIDDebt(debt, balance);
    calculatePik(debt);
    calculateABLDebt(debt, balance);
  } catch (e) {
    console.log(e)
  }
}

