import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  Divider as MuiDivider
} from "@mui/material";
import { spacing } from "@mui/system";


import Settings from "../../../../components/Settings";
import AddCurrentAsset from "./components/AddCurrentAsset";
import {
  calculateCurrentAssets
} from "./calculations/Calculations";
import CurrentAssetInput from "./components/CurrentAssetInput";
import PageHeader from "../../SharedComponents/PageHeader";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentAssetsState } from "../../SharedState/CurrentAssetsState";
import { vatPackageState } from "../../SharedState/VatPackageState";
import { revenueAndCostState } from "../../SharedState/RevenueAndCostState";
import { overheadsState } from "../../SharedState/OverheadsState";
import { pnlState } from "../../SharedState/PnLState";
import ControlsBar from "../../SharedComponents/ControlsBar";
import UpdatePowdrModel from "../../SharedComponents/UpdatePowdr/UpdatePowdrModel";
import { calculatePercentageComplete } from "../../SharedComponents/utils/PercentageComplete";
import { hasCellValueChanged } from "../../SharedComponents/utils/Utils";
import { processAlert } from "../../SharedComponents/utils/NotificationUtils";
import AssumptionNotification from "../../SharedComponents/AssumptionNotificaiton";
import {  currentAssetsAssumptionsMap } from "../../SharedComponents/AssumptionMaps";
import { headcountsState } from "../../SharedState/HeadcountsState";
import { releaseProfileState } from "../../SharedState/ReleaseProfileState";
import {CurrentAssetsType} from "../../SharedComponents/Types";
import UpdatePowdrModelGlobal from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import UpdatePowdrModelBalance from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelBalance";

const Divider = styled(MuiDivider)(spacing);

function CurrentAssets() {

  const updatePowdrRef = useRef();
  const updatePowdrModelBalanceRef = useRef();
  const updatePowdrModelGlobalRef = useRef();

  const [openAlert, setOpenAlert] = useState(false);
  const [currentAssets, setCurrentAssets] = useState([]);
  const [editTableEnabled, setEditTableEnabled] = useState("");

  const [currentAssetsAtom, setCurrentAssetsAtom] = useRecoilState(currentAssetsState);
  const vatPackageAtom = useRecoilValue(vatPackageState);
  const productsAtom = useRecoilValue(revenueAndCostState);
  const overheadsAtom = useRecoilValue(overheadsState);
  const headcountsAtom = useRecoilValue(headcountsState);
  const pnlAtom = useRecoilValue(pnlState);
  const releaseProfileAtom = useRecoilValue(releaseProfileState);

  async function loadCurrentAssets() {

    // reset the badge
    processAlert(setOpenAlert, 4);

    let currentAssets_ = structuredClone(currentAssetsAtom);

    calculatePercentageComplete(currentAssets_, CurrentAssetsType);

    calculateCurrentAssets(currentAssets_, vatPackageAtom, pnlAtom, releaseProfileAtom, productsAtom);

    setCurrentAssets(currentAssets_);
  }

  function updatePorwdrModel(cellData) {
    updatePowdrRef.current.updatePowdrModel(cellData,
      currentAssets,
      "currentAsset",
      "CURRENT_ASSETS",
      currentAssetsAssumptionsMap);
  }

  function refreshData(cellData) {

    if (hasCellValueChanged(cellData)) {
      calculatePercentageComplete(currentAssets, CurrentAssetsType);

      calculateCurrentAssets(currentAssets, vatPackageAtom, pnlAtom, releaseProfileAtom, productsAtom);

      updatePorwdrModel(cellData);

      let currentAssetsAtomClone = structuredClone(currentAssets);

      setCurrentAssetsAtom(currentAssetsAtomClone);
    }

  }


  useEffect(() => {

    loadCurrentAssets();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAssetsAtom]);

  return (
    <React.Fragment>

      <PageHeader pageName={"Current Assets"} parentName={"Assumptions"}/>

      <AssumptionNotification assumptionName={"current assets"} openAlert={openAlert} setOpenAlert={setOpenAlert}></AssumptionNotification>

      <Divider my={6} />

      <ControlsBar addAssumptionElement={
        <AddCurrentAsset/>
      }
      />

      {!!currentAssets &&
        currentAssets.map((currentAsset) => (
          <CurrentAssetInput
            key={currentAsset.currentAssetDto.id}
            currentAssetItem={currentAsset}
            currentAssets={currentAssets}
            refreshData={refreshData}
            setEditData={setEditTableEnabled}
            editData={editTableEnabled}
            setCurrentAssets={setCurrentAssets}
            loadData={loadCurrentAssets}
            products={structuredClone(productsAtom)}
            overheads={structuredClone(overheadsAtom)}
            salaries={structuredClone(headcountsAtom)}
          ></CurrentAssetInput>
        ))}


      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>

      <UpdatePowdrModelBalance ref={updatePowdrModelBalanceRef}/>

      <UpdatePowdrModel ref={updatePowdrRef}/>
      <Settings/>


    </React.Fragment>
  );
}

export default CurrentAssets;
