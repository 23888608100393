import { getCurrentProject } from "../ProjectServices";
import {parseNumberOrZero} from "../utils/NumbersUtils";

/**
 * FOR BALANCE ASSUMPTIONS ONLY
 * (FOR PNL THIS WILL NOT WORK AS ITS LOOKING AT THE BALANCE REFERENCE POINT)
 * @param assumptions
 * @param itemName
 */
export function calculateCorkscrewWithDA(assumptions, itemName) {

  // all the assumptions that are type 'SIMPLE_CORKSCREW_WITH_INPUT_DA'
  const assumptionCorkScrewDA = assumptions
    .filter(assumption => assumption[itemName + 'Dto'].assumption === "SIMPLE_CORKSCREW_WITH_INPUT_DA");

  // calculate closing value
  // Opening + additions - deductions = closing. This is called a CORKSCREW
  assumptionCorkScrewDA.forEach(assumption => {
    const categories = assumption[itemName + 'CategoryDtos'];
    categories[0][itemName + 'MonthDtos'].forEach((assumptionMonthDto, i) => {

      // only for forecast values
      if (i >= getCurrentProject().firstBalanceForecast) {

        categories[0][itemName + 'MonthDtos'][i].value = categories
          .find((category) => category.name === "Closing")[itemName + 'MonthDtos'][i - 1].value;
        //get the value of the closing, from the previous month

        const closing = categories.find((category) => category.name === "Closing");
        const closingIndex = categories.indexOf(closing);

        let total = 0;
        for(let j = 0; j < closingIndex; j++) {
          total += categories[j][itemName + "MonthDtos"][i].value;
        }

        closing[itemName + 'MonthDtos'][i].value = parseNumberOrZero(total);
      }

    });
  });

}