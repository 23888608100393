import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import {Card, CardContent, Divider as MuiDivider, Typography} from "@mui/material";
import {spacing} from "@mui/system";
import PageHeader from "../../SharedComponents/PageHeader";
import {useRecoilValue} from "recoil";
import {revenueAndCostState} from "../../SharedState/RevenueAndCostState";
import ReviewInput from "./components/ReviewInput";
import {overheadsState} from "../../SharedState/OverheadsState";
import {headcountsState} from "../../SharedState/HeadcountsState";
import {fixedAssetsState} from "../../SharedState/FixedAssetsState";
import {currentAssetsState} from "../../SharedState/CurrentAssetsState";
import {currentLiabilitiesState} from "../../SharedState/CurrentLiabilitiesState";
import {debtsState} from "../../SharedState/DebtsState";
import {equitiesState} from "../../SharedState/EquitiesState";
import {covenantState} from "../../SharedState/CovenantState";

import {
  CurrentAssetsType,
  CurrentLiabilitiesType,
  DebtType,
  EquityType,
  FixedAssetsType,
  HeadcountType,
  OverheadsType,
  ProductsType
} from "../../SharedComponents/Types";
import {getVatRates} from "../../SharedComponents/calculations/GetVatApiCall";
import useProject from "../../../../hooks/useProject";
import {pnlState} from "../../SharedState/PnLState";
import {balanceSheetState} from "../../SharedState/BalanceSheetState";
import {calculateCovenants} from "../Covenance/calculations/Calculations";
import CovenantCard from "./components/CovenantCard";

const Divider = styled(MuiDivider)(spacing);

function ReviewDashboard() {
  const productsAtom = useRecoilValue(revenueAndCostState);
  const overheadsAtom = useRecoilValue(overheadsState);
  const headcountAtom = useRecoilValue(headcountsState);
  const fixedAssetsAtom = useRecoilValue(fixedAssetsState);
  const currentAssetsAtom = useRecoilValue(currentAssetsState);
  const currentLiabilitiesAtom = useRecoilValue(currentLiabilitiesState);
  const debtsAtom = useRecoilValue(debtsState);
  const equityAtom = useRecoilValue(equitiesState);
  const pnlAtom = useRecoilValue(pnlState);
  const balanceAtom = useRecoilValue(balanceSheetState);
  const covenantAtom = useRecoilValue(covenantState)

  const [productsState, setProductsState] = useState(productsAtom);
  const [overheadState, setOverheadsState] = useState(overheadsAtom);
  const [headcountState, setHeadcountState] = useState(headcountAtom);
  const [covenantsState, setCovenantsState] = useState(covenantAtom);

  const [vatTypes, setVatTypes] = useState([]);
  const {project} = useProject();

  useEffect(() => {
    async function fetchVatRates() {
      await getVatRates(project.id, setVatTypes);
    }

    fetchVatRates();
  }, [project]);

  useEffect(() => {
    if (project && project.projectType === "PNL_DIVISIONAL" && productsAtom && productsAtom.length > 0) {
      setProductsState(productsAtom.filter((assumption) => !assumption[ProductsType.dtoProperty].assumption.includes("DIVISIONAL_CONSOLIDATION")));
    }
  }, [project, productsAtom]);

  useEffect(() => {
    if (project && project.projectType === "PNL_DIVISIONAL" && overheadsAtom && overheadsAtom.length > 0) {
      setOverheadsState(overheadsAtom.filter((assumption) => !assumption[OverheadsType.dtoProperty].assumption.includes("DIVISIONAL_CONSOLIDATION")));
    }
  }, [project, overheadsAtom]);

  useEffect(() => {
    if (project && project.projectType === "PNL_DIVISIONAL" && headcountAtom && headcountAtom.length > 0) {
      setHeadcountState(headcountAtom.filter((assumption) => !assumption[HeadcountType.dtoProperty].assumption.includes("DIVISIONAL_CONSOLIDATION")));
    }
  }, [project, headcountAtom]);

  useEffect(() => {
    if (covenantAtom && covenantAtom.length > 0) {
      const covenants = structuredClone(covenantAtom);
      calculateCovenants(covenants, debtsAtom, pnlAtom, balanceAtom);
      setCovenantsState(covenants);
    }
    // eslint-disable-next-line
  }, [covenantAtom]);

  return (
    <React.Fragment>
      <PageHeader pageName={"Review Dashboard"} parentName={"Dashboard"}/>
      <Divider my={6}/>

      <Card sx={{marginBottom: 5}}>
        <CardContent>
          <h4>This dashboard helps you review all assumption types in the model, review comments and spot potential
            errors</h4>
        </CardContent>
      </Card>

      {productsState && productsState.length > 0 &&
        <Card sx={{marginBottom: 5}}>
          <CardContent>
            <Typography variant="h6" marginBottom={6}>Products</Typography>
            <ReviewInput assumptions={productsState} type={ProductsType} vatTypes={vatTypes}/>
          </CardContent>
        </Card>
      }

      {overheadState && overheadState.length > 0 &&
        <Card sx={{marginBottom: 5}}>
          <CardContent>
            <Typography variant="h6" marginBottom={6}>Overheads</Typography>
            <ReviewInput assumptions={overheadState} type={OverheadsType} vatTypes={vatTypes}/>
          </CardContent>
        </Card>
      }

      {headcountState && headcountState.length > 0 &&
        <Card sx={{marginBottom: 5}}>
          <CardContent>
            <Typography variant="h6" marginBottom={6}>Headcount</Typography>
            <ReviewInput assumptions={headcountState} type={HeadcountType} vatTypes={vatTypes}/>
          </CardContent>
        </Card>
      }

      {fixedAssetsAtom && fixedAssetsAtom.length > 0 && project && project.projectType !== "PNL" &&
        <Card sx={{marginBottom: 5}}>
          <CardContent>
            <Typography variant="h6" marginBottom={6}>Fixed Assets</Typography>
            <ReviewInput assumptions={fixedAssetsAtom} type={FixedAssetsType} vatTypes={vatTypes}/>
          </CardContent>
        </Card>
      }

      {currentAssetsAtom && currentAssetsAtom.length > 0 && project && project.projectType !== "PNL" &&
        <Card sx={{marginBottom: 5}}>
          <CardContent>
            <Typography variant="h6" marginBottom={6}>Current Assets</Typography>
            <ReviewInput assumptions={currentAssetsAtom} type={CurrentAssetsType} vatTypes={vatTypes}/>
          </CardContent>
        </Card>
      }

      {currentLiabilitiesAtom && currentLiabilitiesAtom.length > 0 && project && project.projectType !== "PNL" &&
        <Card sx={{marginBottom: 5}}>
          <CardContent>
            <Typography variant="h6" marginBottom={6}>Current Liabilities</Typography>
            <ReviewInput assumptions={currentLiabilitiesAtom} type={CurrentLiabilitiesType} vatTypes={vatTypes}/>
          </CardContent>
        </Card>
      }

      {debtsAtom && debtsAtom.length > 0 && project && project.projectType !== "PNL" && project && project.projectType !== "PNL" &&
        <Card sx={{marginBottom: 5}}>
          <CardContent>
            <Typography variant="h6" marginBottom={6}>Debt</Typography>
            <ReviewInput assumptions={debtsAtom} type={DebtType} vatTypes={vatTypes}/>
          </CardContent>
        </Card>
      }

      {equityAtom && equityAtom.length > 0 && project && project.projectType !== "PNL" &&
        <Card sx={{marginBottom: 5}}>
          <CardContent>
            <Typography variant="h6" marginBottom={6}>Equity</Typography>
            <ReviewInput assumptions={equityAtom} type={EquityType} vatTypes={vatTypes} project={project}/>
          </CardContent>
        </Card>
      }

      {covenantsState && covenantsState.length > 0 &&
        <Card>
          <CardContent>
            <Typography variant="h6" marginBottom={6}>Covenants</Typography>
            <div style={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: "40px"
            }}>
              {covenantsState.map((covenant) => {
                  return (
                    <CovenantCard covenant={covenant}/>
                  )
                }
              )}
            </div>
          </CardContent>
        </Card>
      }
    </React.Fragment>
  )
}

export default ReviewDashboard;
