import _ from "lodash";
import axios from "axios";
import { calculateCorkscrew } from "../../../SharedComponents/calculations/SimpleCorkscrew";
import { calculateCorkscrewWithDA } from "../../../SharedComponents/calculations/SimpleCorkscrewWithDA";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { calcPercent } from "../../../SharedComponents/utils/PercentageUtils";
import {FixedAssetsType} from "../../../SharedComponents/Types";
import {parseNumberOrZero} from "../../../SharedComponents/utils/NumbersUtils";


function orderFixedAssetRows(fixedAssets) {
  _.forEach(fixedAssets, function(assumption) {
    const categories = assumption[FixedAssetsType.categoryListProperty];

    const adjustments = categories.filter((category) => category.name === "Adjustment");
    // Only need to sort the rows if there are user added adjustments, otherwise they are already ordered by default.
    if (adjustments !== undefined && adjustments.length > 0) {
      const additionsCapex = categories.find((category) => category.name === "Additions/Capex");
      const additionsCapexIndex = categories.indexOf(additionsCapex);

      const aboveAdjustments = categories.slice(0, additionsCapexIndex + 1);

      const deductions = categories.find((category) => category.name === "Deductions/Sale of assets");
      const deductionsIndex = categories.indexOf(deductions);

      const closing = categories.find((category) => category.name === "Closing");
      const closingIndex = categories.indexOf(closing);

      const belowAdjustments = categories.slice(deductionsIndex, closingIndex);

      assumption[FixedAssetsType.categoryListProperty] = [
        ...aboveAdjustments,
        ...adjustments,
        ...belowAdjustments,
        closing
      ];
    }
  });
}

export function calculateFixedAssets(fixedAssets) {
  orderFixedAssetRows(fixedAssets);
  //todo NOTE
  //for the first 12 months (actuals, we AREN'T applying the calculations)
  //as these are actual values
  //we ONLY apply the calculations for the forecast values onwards.

  try {
    calculateCorkscrew(fixedAssets, FixedAssetsType);
    calculateCorkscrewWithDA(fixedAssets, 'fixedAsset');
    calculateCorkscrewWithDAPercentage(fixedAssets);
  }
  catch (e) {
    console.debug(e)
  }
}


function calculateCorkscrewWithDAPercentage(fixedAssets) {

  // take the depreciation rate and deduct
  // calculate the percentage of the the opening balance
  // set that value of the depreciation
  // calculate the closing value

  const fixedAssetsCorkScrewDAPercent = fixedAssets
    .filter(fixedAsset => fixedAsset.fixedAssetDto.assumption === "SIMPLE_CORKSCREW_WITH_DA_PERCENTAGE");

  // calculate closing value
  // Opening + additions - deductions = closing. This is called a CORKSCREW
  _.forEach(fixedAssetsCorkScrewDAPercent, function(fixedAsset) {
    _.forEach(fixedAsset.fixedAssetCategoryDtos[0].fixedAssetMonthDtos, function(fixedAssetMonthDto, i) {
      const categories = fixedAsset.fixedAssetCategoryDtos;

        // set the opening balance from the previous closing balance
      if (i >= getCurrentProject().firstBalanceForecast) {
        categories[2].fixedAssetMonthDtos[i].value =
          categories.find((category) => category.name === "Closing")
            .fixedAssetMonthDtos[i - 1]
            .value;
        }

        //depreciation rate
        //opening balance
      categories.find((category) => category.name === "Depreciation/Amortisation").fixedAssetMonthDtos[i].value =           //deprecation/amortisation
          calcPercent(categories[2].fixedAssetMonthDtos[i].value,
            categories[0].fixedAssetMonthDtos[i].value);

      if (i >= getCurrentProject().firstBalanceForecast) {
        const closing = categories.find((category) => category.name === "Closing");
        const closingIndex = categories.indexOf(closing);

        let total = 0;
        for(let j = 0; j < closingIndex; j++) {
          if(categories[j].name !== "Monthly Depreciation Rate") {
            total += categories[j].fixedAssetMonthDtos[i].value;
          }
        }

        closing.fixedAssetMonthDtos[i].value = parseNumberOrZero(total);
      }
    });
  });
}

export function markAsCompleted(dataSetDto) {

    console.log(dataSetDto)

    dataSetDto.fixedAssetDto.percentageComplete = 0;

    _.forEach(
      dataSetDto.fixedAssetCategoryDtoList,
      function(categoryDto) {

        _.forEach(
          categoryDto.fixedAssetMonthDtoList,
          function(fixedAssetMonthDto) {

            if (fixedAssetMonthDto.cellType === "ENABLED" && fixedAssetMonthDto.touched === false) {
              fixedAssetMonthDto.value = 0;
              fixedAssetMonthDto.touched = true;
            }
          }
        );
      }
    );

    dataSetDto.fixedAssetDto.percentageComplete = 100;

    // do a put update here
    const config = {
      method: "put",
      url: "fixedassets/" + dataSetDto.fixedAssetDto.project,
      data: [dataSetDto],
    };

    axios(config)
      .then(function (response) {
      })
      .catch(function (error) {
        console.log(error);
      });

}
