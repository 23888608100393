import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  Divider as MuiDivider
} from "@mui/material";
import { spacing } from "@mui/system";
import AddHeadCount from "./components/AddHeadCount";
import Settings from "../../../../components/Settings";
import HeadCountInput from "./components/HeadCountInput";
import { calculateHeadCounts} from "./calculations/Calculations";
import PageHeader from "../../SharedComponents/PageHeader";
import { useRecoilState, useRecoilValue } from "recoil";
import { headcountsState } from "../../SharedState/HeadcountsState";
import ControlsBar from "../../SharedComponents/ControlsBar";
import AssumptionNotification from "../../SharedComponents/AssumptionNotificaiton";
import UpdatePowdrModel from "../../SharedComponents/UpdatePowdr/UpdatePowdrModel";
import { hasCellValueChanged } from "../../SharedComponents/utils/Utils";
import { processAlert } from "../../SharedComponents/utils/NotificationUtils";
import { calculatePercentageComplete } from "../../SharedComponents/utils/PercentageComplete";
import UpdatePowdrModelGlobal from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import { balanceSheetState } from "../../SharedState/BalanceSheetState";
import { pnlState } from "../../SharedState/PnLState";
import OverrideValueModal from "../../SharedComponents/OverrideValueModal";
import { revenueAndCostState } from "../../SharedState/RevenueAndCostState";
import { revenueAndCostConsolidationState } from "../../SharedState/RevenueAndCostConsolidationState";
import { headcountsConsolidationState } from "../../SharedState/HeadcountsConsolidationState";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";
import {HeadcountType} from "../../SharedComponents/Types";

const Divider = styled(MuiDivider)(spacing);

function HeadCount() {

  const updatePowdrRef = useRef();
  const updatePowdrModelGlobalRef = useRef();

  const [openAlert, setOpenAlert] = useState(false);
  const [headCounts, setHeadCounts] = useState([]);
  const [editTableEnabled, setEditTableEnabled] = useState("");

  const headcountAtom = useRecoilValue(headcountsState);

  const balanceSheetAtom = useRecoilValue(balanceSheetState);
  const [pnlAtom, setPnlAtom] = useRecoilState(pnlState);
  const productsAtom = useRecoilValue(revenueAndCostState);
  const revenueAndCostConsolidation = useRecoilValue(revenueAndCostConsolidationState);
  const headcountConsolidation = useRecoilValue(headcountsConsolidationState);


  async function loadHeadCounts() {

    // reset the badge
    processAlert(setOpenAlert, 2);

    let headcounts_ = structuredClone(headcountAtom);

    calculatePercentageComplete(headcounts_, HeadcountType);

    calculateHeadCounts(headcounts_, productsAtom, pnlAtom, setPnlAtom, revenueAndCostConsolidation, headcountConsolidation);

    setHeadCounts(headcounts_)
  }

  function updatePorwdrModel() {
    updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceSheetAtom, pnlAtom, headCounts);
  }

  function refreshHeadCountData(cellData) {

    if (hasCellValueChanged(cellData)) {

      calculatePercentageComplete(headCounts, HeadcountType);

      calculateHeadCounts(headCounts, productsAtom, pnlAtom, setPnlAtom, revenueAndCostConsolidation, headcountConsolidation);

      updatePorwdrModel();

    }

  }

  useEffect(() => {
    loadHeadCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headcountAtom]);


  return (
    <React.Fragment>

      <PageHeader pageName={"Head Count"} parentName={"Assumptions"}/>

      <AssumptionNotification assumptionName={"headcounts"} openAlert={openAlert} setOpenAlert={setOpenAlert}></AssumptionNotification>

      <Divider my={6} />

      <ControlsBar addAssumptionElement={
        <AddHeadCount/>
      }/>


      {!!headCounts &&
        headCounts.filter(headCount => !(
          getCurrentProject().hideDefaultGroups === true &&
          headCount.headCountDto.headcounts.length === 0 &&
          headCount.headCountDto.name.endsWith("(Default Headcount Group)"))
        ).map((headCount) => (
          <HeadCountInput
            key={headCount.headCountDto.id}
            headCountItem={headCount}
            headCounts={headCounts}
            refreshData={refreshHeadCountData}
            setEditData={setEditTableEnabled}
            editData={editTableEnabled}
            setHeadCounts={setHeadCounts}
            loadHeadCountData={loadHeadCounts}
          ></HeadCountInput>
        ))}

      <OverrideValueModal items={structuredClone(headCounts)} assumptionType={HeadcountType}/>
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
      <UpdatePowdrModel ref={updatePowdrRef}/>
      <Settings/>

    </React.Fragment>
  );
}

export default HeadCount;
