import React, {useRef, useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {NumberFormatCustom} from "./PowdrCell/PowdrCell";
import {useRecoilState, useRecoilValue} from "recoil";
import {overrideModalCellDataState, overrideModalState} from "../SharedState/OverrideModalState";
import {balanceSheetState} from "../SharedState/BalanceSheetState";
import {pnlState} from "../SharedState/PnLState";
import UpdatePowdrModelGlobal from "./UpdatePowdr/UpdatePowdrModelGlobal";
import {CurrentLiabilitiesType, HeadcountType, OverheadsType, ProductsType} from "./Types";

export default function OverrideValueModal(props) {
  const updatePowdrModelGlobalRef = useRef();

  const { items, assumptionType, callBack } = props;

  const [showSpinner, setShowSpinner] = useState(false);
  const [overriddenValue, setOverriddenValue] = useState('');

  const [overrideModalAtom, setOverrideModalAtom] = useRecoilState(overrideModalState);
  const overrideModalCellDataAtom = useRecoilValue(overrideModalCellDataState);
  const balanceAtom = useRecoilValue(balanceSheetState);
  const pnlAtom = useRecoilValue(pnlState);

  const handleClose = () => {
    setShowSpinner(false);
    setOverrideModalAtom(false);
  };

  function updateGlobalRefs() {
    if (assumptionType === OverheadsType) {
      updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom, null, items, null);
    } else if (assumptionType === ProductsType) {
      updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom, null, null, items);
    } else if (assumptionType === HeadcountType) {
      updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom, items, null, null);
    } else if (assumptionType === CurrentLiabilitiesType) {
      updatePowdrModelGlobalRef.current.updatePowdrModelGlobal(balanceAtom, pnlAtom, null, null, null, items);
    }
  }

  function useCalculatedValue() {

    setShowSpinner(true);

    const monthToUpdate = findMonthToUpdate(assumptionType.categoryListProperty, assumptionType.monthListProperty);

    monthToUpdate.valueOverriden = null;
    monthToUpdate.previousValueOverriden = -9999;
    monthToUpdate.cellType = "CALCULATED";

    //now do a global recalculate
    updateGlobalRefs();

    setTimeout(() => {
      // Code to run after 8 seconds
      setShowSpinner(false);
      setOverrideModalAtom(false);
    }, 4000);
  }

  function findMonthToUpdate(categoryName, monthName) {
    let listOfMonths = [];

    //collect all months from all assumptions
    for (let assumption of items) {
      for (let category of assumption[categoryName]) {
        listOfMonths = listOfMonths.concat(category[monthName]);
      }
    }

    return listOfMonths.find(month => month.id === overrideModalCellDataAtom.id);
  }

  function setValueOverriden() {
    setShowSpinner(true);

    const monthToUpdate = findMonthToUpdate(assumptionType.categoryListProperty, assumptionType.monthListProperty);

    let overridenValue_ = parseFloat(overriddenValue);
     if (!isNaN(overridenValue_)) {
       monthToUpdate.valueOverriden = overridenValue_;
       monthToUpdate.cellType = "OVERRIDEN";
       monthToUpdate.previousValueOverriden = -9999;
       monthToUpdate.touched = true;

       //now do a global recalculate
       updateGlobalRefs();

       setTimeout(() => {
         // Code to run after 8 seconds
         setShowSpinner(false);
         setOverrideModalAtom(false);
       }, 4000);

       if (callBack !== null & callBack !== undefined) {
         callBack();
       }

    } else {
       alert("Please enter a valid number");
       setShowSpinner(false);
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={overrideModalAtom}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
              setShowSpinner(false);
             setOverrideModalAtom(false);
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Override Calculated Value"}
        </DialogTitle>
        <DialogContent>

            <TextField
              id={"overridenValueTextField"}
              value={overrideModalCellDataAtom.valueOverriden}
              onChange={(e) => setOverriddenValue(e.target.value)}
              placeholder={'0'}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />

        </DialogContent>
        <DialogActions>
          <Button onClick={useCalculatedValue} color="primary">
            {showSpinner ? "" : "Undo Override"}
          </Button>
          <Button onClick={() => handleClose()} color="primary">
            {showSpinner ? "" : "Cancel"}
          </Button>
          <Button onClick={setValueOverriden} color="primary" autoFocus>
            {showSpinner ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
    </React.Fragment>
  );
}
