import React, {useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";

import {Card as MuiCard, CardContent, Skeleton, Table, TableBody, TableCell, TableHead, TableRow,} from "@mui/material";
import {spacing} from "@mui/system";
import axios from "axios";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import RowTitle from "../../../SharedComponents/RowTitle";
import MonthHeader, {generateXaxisHeaders} from "../../../SharedComponents/MonthHeader";
import {AssumptionLabel} from "../../../SharedComponents/AssumptionLabel";
import {cardStyles, cellStyles} from "../../../SharedComponents/styles/SharedStyles";
import getProjectId, {getCurrentProject} from "../../../SharedComponents/ProjectServices";
import PowdrNavBar from "../../../SharedComponents/PowdrNavBar";
import EditEquity from "./EditEquity";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {zoomState} from "../../../SharedState/ZoomState";
import {equitiesState} from "../../../SharedState/EquitiesState";
import {balanceSheetState} from "../../../SharedState/BalanceSheetState";
import {EquityType} from "../../../SharedComponents/Types";
import {identifyPotentialError} from "../../../SharedComponents/calculations/Calculations";
import ErrorNoteModal from "../../../SharedComponents/ErrorNoteModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddAdjustment from "../../../Analysis/Covenance/components/AddAdjustment";

const Card = styled(MuiCard)(spacing);

const helpTitles = new Map();

helpTitles.set('Addition', 'Additions should be a positive value');
helpTitles.set('Deduction', 'Deductions should be a negative value');

function EquityInput(props) {
  const { key } = useProject();
  const { project } = useProject();
  const updatePowdrModelGlobalRef = useRef();

  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const tableRef = useRef(null);
  const zoomAtom = useRecoilValue(zoomState);

  const [isLoading, setIsLoading] = useState(true);
  const [showErrorNoteDialog, setShowErrorNoteDialog] = useState(false);
  const [clickedCell, setClickedCell] = useState(null);

  const setEquities = useSetRecoilState(equitiesState);
  const setBalanceSheet = useSetRecoilState(balanceSheetState);

  const [addAdjustmentDialogOpen, setAddAdjustmentDialogOpen] = useState(false);
  const [clickedRowId, setClickedRowId] = useState(null);

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);

  const {
    equityItem,
    equity,
    refreshData,
    setEditData,
    editData,
    setEquity,
    loadData
  } = props;

  const deleteEquity = (handleClose) => {
    const config = {
      method: "delete",
      url: "equity",
      data: equityItem.equityDto,
    };
    axios(config)
      .then(async function(response) {

        setEquity([...response.data]);
        setEquities(structuredClone(response.data));

        let project = await getProjectId();

        await axios.get("balance/" + project.id).then(function(response) {
          setBalanceSheet(response.data);
        });
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteAdjustment = (handleClose) => {
    const config = {
      method: "delete",
      url: "equity/adjustment",
      data: {
        project: project.id,
        categoryId: clickedRowId
      }
    };
    axios(config)
      .then(async function (response) {
        await axios.get("equity/" + project.id).then(function (response) {
          setEquities(response.data);
        });
        updatePowdrModelGlobalRef.current.updatePowdrModelGlobal();
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstBalanceForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }

  const handleRightClick = (e, cellData, assumption, type) => {
    if(e.type === "contextmenu" && identifyPotentialError(cellData, assumption, type)) {
      setClickedCell(cellData);
      setShowErrorNoteDialog(true);
    }
    e.preventDefault();
  }

  const getName = (row) => {
    return (row.overrideName !== null && row.name === "Adjustment") ?
      row.overrideName :
      row.name;
  }

  function renderHeavyContent() {
    return (
      <div>
        <PowdrNavBar
          item={equityItem.equityDto}
          categories={equityItem.equityCategoryDtos}
          deleteMethod={deleteEquity}
          refreshDataMethod={loadData}
          editAssumptionComponent={<EditEquity equityNames={[]}
                                               loadData={loadData}
                                               equity={equityItem.equityDto}
                                               open={openEdit}
                                               setOpen={setOpenEdit}>
          </EditEquity>}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          showExportToExcel={true}
          exportToExcel={{fileName: "Powdr-Equity-" + equityItem.equityDto.name + "-" + new Date().toDateString(),
            sheet : "Product",
            currentTableRef : tableRef.current}}
          shallowUpdateUrl={EquityType.shallowUpdateUrl}
          type={EquityType}
        ></PowdrNavBar>

        <Card mb={6} sx={{...cardStyles}}
              id={equityItem.equityDto.name.replaceAll(" ", "-") + "-table"}>
          <CardContent sx={{ paddingTop: 0 , zoom: zoomAtom}}>
            <Table ref={tableRef}>
              {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
              <TableHead>
                <TableRow
                  key={equityItem.equityDto.name + "-ROW"}
                  id={equityItem.equityDto.name + "-ROW"}
                >
                  <TableCell
                    key={equityItem.equityDto.name + "-BASE"}
                    id={equityItem.equityDto.name + "-BASE"}
                    sx={{...cellStyles}}>
                    <h2>{equityItem.equityDto.name}</h2>
                    <AssumptionLabel label={equityItem.equityDto.assumptionDesc} color="success" />
                  </TableCell>
                  {XaxisHeaders.map((yHeader, index) => (
                    <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={equityItem.equityDto.name} index={index}></MonthHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>

                {equityItem.equityCategoryDtos.map((row) => (

                  <>
                    {row.name === "Closing" && equityItem.equityDto.assumption === "SIMPLE_CORKSCREW" &&
                      <TableRow sx={{
                        justifyContent: 'center',
                        paddingY: "5px"
                      }}>
                        <AddCircleOutlineIcon
                          sx={{
                            cursor: "pointer",
                            color: "black",
                            left: 100,
                            position: "sticky",
                            '&:hover': {
                              color: "#2f66cb",
                            }
                          }}
                          onClick={() => setAddAdjustmentDialogOpen(true)}
                        />
                      </TableRow>}


                    <TableRow key={row.id} id={row.id} onClick={() => handleClick(row.id)}>

                      <RowTitle name={getName(row)}
                                alt={helpTitles.get(row.name)}
                                canDelete={
                                  row.name === "Adjustment" &&
                                  equityItem.equityDto.assumption === "SIMPLE_CORKSCREW"
                                }
                                deleteFunction={deleteAdjustment}
                                rowId={row.id}
                                setSelectedRowId={setClickedRowId}
                      />

                      {row.equityMonthDtos.map((month) => (
                        <PowdrCell
                          key={month.id + "-CELL"}
                          cellData={month}
                          ownerId={row.id}
                          editTableEnabled={editData}
                          items={equity}
                          refreshItemData={refreshData}
                          setItems={setEquity}
                          assumption={equityItem}
                          categoryName={row.name}
                          parentName={equityItem.equityDto.name}
                          category={row}
                          monthIndex={month.monthIndex}
                          monthAttributeName={'equityMonthDtos'}
                          handleRightClick={handleRightClick}
                          type={EquityType}
                        ></PowdrCell>
                      ))}
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        {clickedCell && <ErrorNoteModal open={showErrorNoteDialog} setOpen={setShowErrorNoteDialog}
                                        item={clickedCell} type={EquityType} projectId={project.id}
                                        setItems={setEquities} items={equity}/>}

        <AddAdjustment
          open={addAdjustmentDialogOpen}
          setOpen={setAddAdjustmentDialogOpen}
          assumptionDto={equityItem.equityDto}
          setAssumptionAtom={setEquities}
          assumptionType={EquityType}
        />
      </div>
    )
  }


  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default EquityInput;
