import _ from "lodash";
import { getCurrentProject } from "../../SharedComponents/ProjectServices";
import axios from "axios";


export function actualiseCovenants(covenants) {
  const firstBalanceForecast = getCurrentProject().firstBalanceForecast;

  _.forEach(covenants, (covenant) => {
    _.forEach(covenant.covenantCategoryDtos, (category) => {
      if (_.isEmpty(category.covenantMonthDtos)) {
        return;
      }

      category.covenantMonthDtos[firstBalanceForecast].cellType =
        category.covenantMonthDtos[firstBalanceForecast - 1].cellType;

      category.covenantMonthDtos[firstBalanceForecast].financialTypeEnum =
        category.covenantMonthDtos[firstBalanceForecast - 1].financialTypeEnum;

      category.covenantMonthDtos[firstBalanceForecast].valueOverriden = null;
    });
  });

  return covenants;
}


export async function loadCovenant() {
  let id = getCurrentProject().id;

  const config = {
    method: "get",
    url: "covenant/" + id
  };

  try {
    let response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Error loading covenant:', error);
    // Handle the error appropriately, possibly rethrow it or return a default value
    throw error;
  }
}

