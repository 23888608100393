import _ from "lodash";
import {CovenantType} from "../../../SharedComponents/Types";

function orderLeverageCovenantRows(covenantItem) {
  // Debts
  // Debt adjustment items
  // Debt adjustment total

  // Cash on balance sheet

  // EBITDA
  // EBITDA adjustment items
  // EBITDA adjustment total

  // Everything else
  const categories = covenantItem[CovenantType.categoryListProperty];
  const debtAdjustmentRow = categories.find((category) => category.name === "Debt Adjustment Total");
  const debtAdjustmentRowIndex = categories.indexOf(debtAdjustmentRow);

  const ebitdaRow = categories.find((category) => category.name === "EBITDA");
  const ebitdaRowIndex = categories.indexOf(ebitdaRow);

  const ebitdaAdjustmentRow = categories.find((category) => category.name === "EBITDA Adjustment Total");
  const ebitdaAdjustmentRowIndex = categories.indexOf(ebitdaAdjustmentRow);

  const debtAdjustmentItemRows = categories.filter((category) => category.name === "Debt Adjustment");
  const ebitdaAdjustmentItems = categories.filter((category) => category.name === "EBITDA Adjustment");

  let lastRow = categories.find((category) => category.name === "Headroom");
  if(!lastRow) {
    lastRow = categories.find((category) => category.name === "Breach Indicator")
  }
  const headroomRowIndex = categories.indexOf(lastRow);

  const betweenDebtAndEbitdaAdjustment = categories.slice(debtAdjustmentRowIndex, ebitdaRowIndex);

  const debts = categories.filter((category) => category.name === "Debt");
  const afterEbitdaAdjustment = categories.slice(ebitdaAdjustmentRowIndex, headroomRowIndex)
    .filter((category) => !["Debt Adjustment", "EBITDA Adjustment"].includes(category.name));

  covenantItem[CovenantType.categoryListProperty] = [
    ...debts,
    ...debtAdjustmentItemRows,
    ...betweenDebtAndEbitdaAdjustment,
    ebitdaRow,
    ...ebitdaAdjustmentItems,
    ...afterEbitdaAdjustment,
    lastRow
  ];
}

function calculateLeverageCovenants(covenants, debts, pnl, balance) {

  //find the cash at bank category
  let cashAtBankCategory = balance.balanceCategoryDtos.find(category => category.name === "Cash at bank");

  //find all leverage types
  const covenantsLeverage = covenants
    .filter(covenant => covenant.covenantDto.assumption === "LEVERAGE_COVENANT");

  _.forEach(covenantsLeverage, function (covenant) {
    if(covenant[CovenantType.categoryListProperty].find((category) => category.name === "Debt Adjustment Total")) {
      orderLeverageCovenantRows(covenant);
    }

    let cashOnBalanceSheetCovenanceCategory = covenant.covenantCategoryDtos.find(category => category.name === "Cash On Balance Sheet");

    //now loop through the months for the debt and populate the covenantDebt with those values
    _.forEach(cashOnBalanceSheetCovenanceCategory?.covenantMonthDtos, function (monthDto, i) {
      monthDto.value = cashAtBankCategory.balanceMonthDtos[i + 1].value;
    });

    let debtTotals = new Array(72).fill(0);

    //we need to populate the debt values first
    const covenantDebts = covenant.covenantCategoryDtos.filter(category => ["Debt"].includes(category.name));

    _.forEach(covenantDebts, function (covenantDebt) {

      // find the debt that is linked to this coventantDebt
      const debtAssociated = debts.find(debt => debt.debtDto.id === covenantDebt.debtId);

      // find the debt total category (the bottom line)
      const debtClosing = debtAssociated.debtCategoryDtos.find(category => category.name === "Closing" || category.name === "Closing Borrowings"); //do get correct name

      //now loop through the months for the debt and populate the covenantDebt with those values
      _.forEach(covenantDebt.covenantMonthDtos, function (monthDto, i) {
        monthDto.value = Math.abs(debtClosing.debtMonthDtos[i].value);
        debtTotals[i] += Math.abs(debtClosing.debtMonthDtos[i].value)
      });

    });

    //And now the Debt Adjustment row
    let covenantDebtAdjustment = covenant.covenantCategoryDtos.find(category => ["Debt Adjustment Total"].includes(category.name));
    if(!covenantDebtAdjustment) {
      covenantDebtAdjustment = covenant.covenantCategoryDtos.find(category => ["Debt Adjustment"].includes(category.name));
    }
    const covenantDebtAdjustmentItems = covenant.covenantCategoryDtos.filter(category => "Debt Adjustment" === category.name);

    _.forEach(covenantDebtAdjustment.covenantMonthDtos, function (monthDto, i) {
      if(covenantDebtAdjustment) {
        let total = 0;
        for (const category of covenantDebtAdjustmentItems) {
          total += category[CovenantType.monthListProperty][i].value;
        }
        monthDto.value = total;
      }
      debtTotals[i] += monthDto.value
    });


    if (cashOnBalanceSheetCovenanceCategory) {

      _.forEach(cashOnBalanceSheetCovenanceCategory.covenantMonthDtos, function (monthDto, i) {
        debtTotals[i] = debtTotals[i] + cashOnBalanceSheetCovenanceCategory.covenantMonthDtos[i].value;
      });

    }

    const EBITDA = pnl.pnLCategoryDtoList.find(category => category.name === "EBITDA" && category.overrideName === "");
    let covenant_EBITDA = covenant.covenantCategoryDtos.find(category => category.name === "EBITDA");

    let covenant_EBITDA_Adjustment = covenant.covenantCategoryDtos.find(category => category.name === "EBITDA Adjustment Total");
    if(covenant_EBITDA_Adjustment) {
      const covenant_EBITDA_Adjustment_Items = covenant.covenantCategoryDtos.filter(category => "EBITDA Adjustment" === category.name);

      _.forEach(covenant_EBITDA_Adjustment.covenantMonthDtos, function (monthDto, i) {
        let total = 0;
        for(const category of covenant_EBITDA_Adjustment_Items) {
          total += category[CovenantType.monthListProperty][i].value;
        }
        monthDto.value = total;
      })
    } else {
      covenant_EBITDA_Adjustment = covenant.covenantCategoryDtos.find(category => category.name === "EBITDA Adjustment");
    }

    const EBITDA_Period = covenant[CovenantType.dtoProperty].covenantPeriod;
    let EBITDA_period_string;
    let EBITDA_months;

    switch (EBITDA_Period) {
      case "LAST_1_MONTH": {
        EBITDA_period_string = "L1M EBITDA";
        EBITDA_months = 1;
        break;

      }
      case "LAST_3_MONTHS": {
        EBITDA_period_string = "L3M EBITDA";
        EBITDA_months = 3;
        break;
      }
      case "LAST_6_MONTHS": {
        EBITDA_period_string = "L6M EBITDA";
        EBITDA_months = 6;
        break;
      }
      case "NEXT_12_MONTHS": {
        EBITDA_period_string = "NTM EBITDA";
        EBITDA_months = 12;
        break;
      }
      default: {
        EBITDA_period_string = "LTM EBITDA";
        EBITDA_months = 12;
        break;
      }
    }

    let covenant_EBITDA_FOR_PERIOD = covenant.covenantCategoryDtos.find(category => category.name === EBITDA_period_string);
    let covenant_CALCULATED_RATIO = covenant.covenantCategoryDtos.find(category => category.name === "Calculated Ratio");
    let covenant_LIMIT = covenant.covenantCategoryDtos.find(category => category.name === "Limit");
    let covenant_BREACH_INDICATOR = covenant.covenantCategoryDtos.find(category => category.name === "Breach Indicator");

    _.forEach(covenant.covenantCategoryDtos[0].covenantMonthDtos, function(monthDto, i) {
      //do calculations
      //then set the breach line
      covenant_EBITDA.covenantMonthDtos[i].value = EBITDA.pnLMonthDtoList[i].value; //Set the EBITDA
      const covenantMonthsLimit = covenant_EBITDA_FOR_PERIOD.covenantMonthDtos.length;

      if (EBITDA_Period === "NEXT_12_MONTHS") {
        if (i + EBITDA_months > covenantMonthsLimit) {
          let ebitdaForPeriod = 0;

          for (let x = 0; x + i < covenantMonthsLimit; x++) {
            ebitdaForPeriod += EBITDA.pnLMonthDtoList[i + x].value;
            ebitdaForPeriod += covenant_EBITDA_Adjustment.covenantMonthDtos[i + x].value;
          }

          covenant_EBITDA_FOR_PERIOD.covenantMonthDtos[i].value = ebitdaForPeriod; //Set the Ebitda for the covenant time period
        } else {
          let ebitdaForPeriod = 0;

          for (let x = 0; x < EBITDA_months; x++) {
            ebitdaForPeriod += EBITDA.pnLMonthDtoList[i + x].value;
            ebitdaForPeriod += covenant_EBITDA_Adjustment.covenantMonthDtos[i + x].value;
          }

          covenant_EBITDA_FOR_PERIOD.covenantMonthDtos[i].value = ebitdaForPeriod; //Set the Ebitda for the covenant time period
        }

      } else {
        if (i === 0) {
          covenant_EBITDA_FOR_PERIOD.covenantMonthDtos[i].value = //Set the LTM Ebitda
            covenant_EBITDA.covenantMonthDtos[i].value +  //EBITDA
            covenant_EBITDA_Adjustment.covenantMonthDtos[i].value; //EBITDA Adjustment
        } else if (i < EBITDA_months) {
          covenant_EBITDA_FOR_PERIOD.covenantMonthDtos[i].value = //Set the LTM Ebitda
            covenant_EBITDA_FOR_PERIOD.covenantMonthDtos[i - 1].value + // LTM Ebitda last month
            covenant_EBITDA.covenantMonthDtos[i].value +  //EBITDA
            covenant_EBITDA_Adjustment.covenantMonthDtos[i].value;  //EBITDA Adjustment
        } else {
          let ebitdaForPeriod = 0;

          for (let x = 0; x < EBITDA_months; x++) {
            ebitdaForPeriod += covenant_EBITDA.covenantMonthDtos[i - x].value;
            ebitdaForPeriod += covenant_EBITDA_Adjustment.covenantMonthDtos[i - x].value;
          }

          covenant_EBITDA_FOR_PERIOD.covenantMonthDtos[i].value = ebitdaForPeriod; //Set the Ebitda for the covenant time period
        }
      }

      let ratio = Number( //Set the Calculated Ratio
        debtTotals[i] /  //total debts
        covenant_EBITDA_FOR_PERIOD.covenantMonthDtos[i].value).toFixed(2);

      if (isFinite(ratio)) {
        covenant_CALCULATED_RATIO.covenantMonthDtos[i].value = ratio;
      } else {
        covenant_CALCULATED_RATIO.covenantMonthDtos[i].value = 0;
      }

      covenant_CALCULATED_RATIO.covenantMonthDtos[i].value > covenant_LIMIT.covenantMonthDtos[i].value
        ? covenant_BREACH_INDICATOR.covenantMonthDtos[i].value = 1
        : covenant_BREACH_INDICATOR.covenantMonthDtos[i].value = 0;

      const covenant_HEADROOM = covenant.covenantCategoryDtos.find(category => category.name === "Headroom");
      if (covenant_HEADROOM) {
        const headroomMonth = covenant_HEADROOM.covenantMonthDtos[i];

      if(headroomMonth.financialTypeEnum === "FORECAST") {
        const ebitdaLTM = covenant.covenantCategoryDtos.find(covenant => covenant.name === EBITDA_period_string).covenantMonthDtos[i].value;
        const adjustmentLine =
          covenant.covenantCategoryDtos.find(covenant => covenant.name === "Debt Adjustment Total")
        ? "Debt Adjustment Total" : "Debt Adjustment";
        const netDebt = covenant.covenantCategoryDtos
          .filter(covenant => [adjustmentLine, "Debt", "Cash On Balance Sheet"].includes(covenant.name))
          .map(covenant => covenant.covenantMonthDtos[i].value)
          .reduce((a, b) => a + b);
        const limit = covenant.covenantCategoryDtos.find(covenant => covenant.name === "Limit").covenantMonthDtos[i].value;

          headroomMonth.value = ebitdaLTM - (netDebt * limit);
        }
    }
    });
  });
}

function calculateCashflowCovenants(covenants, balance) {

  const covenantsCashflow = covenants
    .filter(covenant => covenant.covenantDto.assumption === "MINIMUM_CASH_COVENANT");

  //find the cash at bank category
  let cashAtBankCategory = balance.balanceCategoryDtos.find(category => category.name === "Cash at bank");

  _.forEach(covenantsCashflow, function (covenant) {

    let cashOnBalanceSheet = covenant.covenantCategoryDtos.find(category => category.name === "Cash On Balance Sheet");

    //now loop through the months for the debt and populate the covenantDebt with those values
    _.forEach(cashOnBalanceSheet.covenantMonthDtos, function (monthDto, i) {
      monthDto.value = cashAtBankCategory.balanceMonthDtos[i + 1].value;
    });

    const covenant_BREACH_INDICATOR = covenant.covenantCategoryDtos.find(category => category.name === "Breach Indicator");

    _.forEach(covenant.covenantCategoryDtos[0].covenantMonthDtos, function(monthDto, i) {
      const cashPlusAdjustments = covenant.covenantCategoryDtos[0].covenantMonthDtos[i].value +
        covenant.covenantCategoryDtos[1].covenantMonthDtos[i].value;

      const limit = covenant.covenantCategoryDtos[3].covenantMonthDtos[i].value;

      const covenant_HEADROOM = covenant.covenantCategoryDtos.find(category => category.name === "Headroom");
      if(covenant_HEADROOM) {
        const headroomMonth = covenant_HEADROOM.covenantMonthDtos[i];

        if (headroomMonth.financialTypeEnum === "FORECAST") {
          headroomMonth.value = cashPlusAdjustments - limit;
        }
      }
      if (cashPlusAdjustments > limit) {
        covenant_BREACH_INDICATOR.covenantMonthDtos[i].value = 0;
      } else {
        covenant_BREACH_INDICATOR.covenantMonthDtos[i].value = 1;
      }
    });
  });
}

export function calculateCovenants(covenants, debts, pnl, balance) {
  if (Array.isArray(debts) && debts.length === 0) {
    //console.log('debts are empty')
    return
  }

  //calculate leverage covenants
  calculateLeverageCovenants(covenants, debts, pnl, balance);
  //calculate cashflow covenants
  calculateCashflowCovenants(covenants, balance)
}