import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import {
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";

import Settings from "../../../../components/Settings";
import AddEquity from "./components/AddEquity";
import { calculateEquity } from "./calculations/Calculations";
import EquityInput from "./components/EquityInput";
import AssumptionNotification from "../../SharedComponents/AssumptionNotificaiton";
import PageHeader from "../../SharedComponents/PageHeader";
import ControlsBar from "../../SharedComponents/ControlsBar";
import { calculatePercentageComplete } from "../../SharedComponents/utils/PercentageComplete";
import { processAlert } from "../../SharedComponents/utils/NotificationUtils";
import { useRecoilState } from "recoil";
import { equitiesState } from "../../SharedState/EquitiesState";
import UpdatePowdrModel from "../../SharedComponents/UpdatePowdr/UpdatePowdrModel";
import { hasCellValueChanged } from "../../SharedComponents/utils/Utils";
import { equityAssumptionsMap } from "../../SharedComponents/AssumptionMaps";
import {EquityType} from "../../SharedComponents/Types";

const Divider = styled(MuiDivider)(spacing);

function Equity() {

  const updatePowdrRef = useRef();

  const [openAlert, setOpenAlert] = useState(false);
  const [equitys, setEquitys] = useState([]);
  const [editTableEnabled, setEditTableEnabled] = useState("");

  const [equityAtom, setEquityAtom] = useRecoilState(equitiesState);

  function loadEquity() {

    // reset the badge
    processAlert(setOpenAlert, 7);

    let equitys_ = structuredClone(equityAtom);

    calculatePercentageComplete(equitys_, EquityType);

    calculateEquity(equitys_)

    setEquitys(equitys_);
  }

  function updatePowdrModel(cellData) {
    updatePowdrRef.current.updatePowdrModel(cellData,
      equitys,
      "equity",
      "EQUITY",
      equityAssumptionsMap);
  }

  function refreshData(cellData) {

    if (hasCellValueChanged(cellData)) {

      calculatePercentageComplete(equitys, EquityType);

      calculateEquity(cellData.assumptions)

      updatePowdrModel(cellData);

      setEquityAtom(structuredClone(equitys));
    }
  }

  useEffect(() => {
    loadEquity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equityAtom]);

  return (
    <React.Fragment>
        <PageHeader pageName={"Equity"} parentName={"Assumptions"}/>

        <AssumptionNotification assumptionName={"equities"} openAlert={openAlert} setOpenAlert={setOpenAlert}></AssumptionNotification>

        <Divider my={6} />

        <ControlsBar addAssumptionElement={
            <AddEquity/>
        }/>

        {!!equitys &&
          equitys.map((equity) => (
              <EquityInput
                key={equity.equityDto.id}
                equityItem={equity}
                equity={equitys}
                refreshData={refreshData}
                setEditData={setEditTableEnabled}
                editData={editTableEnabled}
                setEquity={setEquitys}
                loadData={loadEquity}
              ></EquityInput>
          ))}

        <UpdatePowdrModel ref={updatePowdrRef}/>
        <Settings/>

    </React.Fragment>
  );
}

export default Equity;
