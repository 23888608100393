import {TableBody, TableCell, TableRow, Typography} from "@mui/material";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import CommentIcon from "@mui/icons-material/Comment";
import CommentModal from "../../../SharedComponents/CommentModal";
import {calculatePotentialErrors, calculateTotalOverrides} from "../calculations/Calculations";
import Tooltip from "@mui/material/Tooltip";
import {
  CurrentAssetsType, CurrentLiabilitiesType, DebtType, EquityType,
  FixedAssetsType,
  HeadcountType,
  OverheadsType,
  ProductsType
} from "../../../SharedComponents/Types";

function ReviewRow({assumption, type, vatTypes}) {
  const navigate = useNavigate();
  const [showCommentModal, setShowCommentModal] = useState(false);

  const assumptionDto = assumption[type.dtoProperty];

  const vatInputRate = assumptionDto.vatInputRate ? vatTypes.find((type) => type.id === assumptionDto.vatInputRate) : null;
  const vatOutputRate = assumptionDto.vatOutputRate ? vatTypes.find((type) => type.id === assumptionDto.vatOutputRate) : null;

  const numOfOverrides = calculateTotalOverrides(assumption, type);
  const potentialErrors = calculatePotentialErrors(assumption, type);

  const handleCellClick = (e) => {
    navigate(type.redirectPath)
  }

  let tooltipText;
  switch (type) {
    case ProductsType:
      tooltipText = "Potential errors are identified by negative numbers in the Revenue row and positive numbers in the Direct Costs row";
      break;
    case OverheadsType:
      tooltipText = "Potential errors are identified by positive numbers in the Value In P&L row and the Overhead Cost row";
      break;
    case HeadcountType:
      tooltipText = "Potential errors are identified by positive numbers in the Total Base Salary Costs section and the Total Costs section";
      break;
    case FixedAssetsType:
      tooltipText = "Potential errors are identified by negative numbers in the Closing row";
      break;
    case CurrentAssetsType:
      tooltipText = "Potential errors are identified by negative numbers in the Closing row";
      break;
    case CurrentLiabilitiesType:
      tooltipText = "Potential errors are identified by positive numbers in the Closing row";
      break;
    case DebtType:
      tooltipText = "Potential errors are identified by positive numbers in the Closing row";
      break;
    case EquityType:
      tooltipText = "Potential errors are identified by negative numbers in the Closing row";
      break;
    default:
      break;
  }

  return (
    <>
      <CommentModal open={showCommentModal}
                    setOpen={setShowCommentModal}
                    item={assumptionDto}
                    shallowUpdateUrl={type.shallowUpdateUrl}
                    type={type}/>
      <TableBody>
        <TableRow sx={{
          backgroundColor: "#F7F9FC",
        }}>
          <TableCell align="left"
                     sx={{
                       color: "#2052c2",
                     }}>
            <Typography
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                },
                fontWeight: "bold",
              }}
              onClick={handleCellClick}>
              {assumptionDto.name}
            </Typography>
          </TableCell>

          <TableCell align="center">
            {assumptionDto.comment &&
              <CommentIcon onClick={() => setShowCommentModal(!showCommentModal)} fontSize="small" sx={{
                cursor: 'pointer',
                color: "#2052c2",
              }}/>}
          </TableCell>

          <TableCell align="center">
            {assumptionDto.assumptionDesc}
          </TableCell>

          <TableCell align="center">
            {vatInputRate ? vatInputRate.name : "-"}
          </TableCell>

          <TableCell align="center">
            {vatOutputRate ? vatOutputRate.name : "-"}
          </TableCell>

          <TableCell align="center">
            {numOfOverrides}
          </TableCell>

          <Tooltip
            arrow
            title={tooltipText}
            placement={"top"}>
            <TableCell align="center">
              <Typography
                onClick={handleCellClick}
                sx={{
                  color: potentialErrors.length > 0 ? "red" : "black",
                  textDecoration: potentialErrors.length > 0 ? "underline" : "",
                  fontWeight: potentialErrors.length > 0 ? "bold" : "",
                  cursor: "pointer"
                }}>
                {potentialErrors.length}
              </Typography>
            </TableCell>
          </Tooltip>
        </TableRow>
      </TableBody>
    </>
  )
}

export default ReviewRow;


