import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import Settings from "../../../../components/Settings";
import AddFixedAsset from "./components/AddFixedAsset";
import {
  calculateFixedAssets,
  markAsCompleted
} from "./calculations/Calculations";
import FixedAssetInput from "./components/FixedAssetInput";
import PageHeader from "../../SharedComponents/PageHeader";
import FixedAssetSummary from "./components/FixedAssetSummary";
import { useRecoilState } from "recoil";
import { fixedAssetsState } from "../../SharedState/FixedAssetsState";
import ControlsBar from "../../SharedComponents/ControlsBar";
import UpdatePowdrModel from "../../SharedComponents/UpdatePowdr/UpdatePowdrModel";
import { hasCellValueChanged } from "../../SharedComponents/utils/Utils";
import AssumptionNotification from "../../SharedComponents/AssumptionNotificaiton";
import { processAlert } from "../../SharedComponents/utils/NotificationUtils";
import { calculatePercentageComplete } from "../../SharedComponents/utils/PercentageComplete";
import { fixedAssetAssumptionsMap } from "../../SharedComponents/AssumptionMaps";
import {FixedAssetsType} from "../../SharedComponents/Types";


const Divider = styled(MuiDivider)(spacing);

function FixedAssets() {

  const updatePowdrRef = useRef();

  const [openAlert, setOpenAlert] = useState(false);
  const [fixedAssets, setFixedAssets] = useState([]);
  const [editTableEnabled, setEditTableEnabled] = useState("");

  const [fixedAssetsAtom, setFixedAssetsAtom] = useRecoilState(fixedAssetsState);

  async function loadFixedAssets() {

    // reset the badge
    processAlert(setOpenAlert, 3);

    let fixedAssets_ = structuredClone(fixedAssetsAtom);

    calculatePercentageComplete(fixedAssets_, FixedAssetsType);

    calculateFixedAssets(fixedAssets_)

    setFixedAssets(fixedAssets_);
  }

  function updatePorwdrModel(cellData) {
    updatePowdrRef.current.updatePowdrModel(cellData,
      fixedAssets,
      "fixedAsset",
      "FIXED_ASSETS",
      fixedAssetAssumptionsMap);
  }

  function refreshData(cellData) {

    if (hasCellValueChanged(cellData)) {

      calculatePercentageComplete(fixedAssets, FixedAssetsType);

      calculateFixedAssets(cellData.assumptions)

      updatePorwdrModel(cellData);

      setFixedAssetsAtom(structuredClone(fixedAssets));
    }
  }

  function markAsComplete(id) {

    markAsCompleted(fixedAssets, id)

    setFixedAssets([...fixedAssets]);
  }

  useEffect(() => {
    loadFixedAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedAssetsAtom]);

  return (
    <React.Fragment>
      <PageHeader pageName={"Fixed Assets"} parentName={"Assumptions"}/>

      <AssumptionNotification assumptionName={"fixed assets"} openAlert={openAlert} setOpenAlert={setOpenAlert}></AssumptionNotification>

      <Divider my={6} />

      <ControlsBar addAssumptionElement={
        <AddFixedAsset/>
      }/>

      <FixedAssetSummary/>


      {!!fixedAssets &&
        fixedAssets.map((fixedAsset) => (
          <FixedAssetInput
            key={fixedAsset.fixedAssetDto.id}
            fixedAssetItem={fixedAsset}
            fixedAssets={fixedAssets}
            refreshData={refreshData}
            setEditData={setEditTableEnabled}
            editData={editTableEnabled}
            setFixedAssets={setFixedAssets}
            loadData={loadFixedAssets}
            markAsComplete={markAsComplete}
          ></FixedAssetInput>
        ))}

      <UpdatePowdrModel ref={updatePowdrRef}/>
      <Settings/>

    </React.Fragment>
  );
}

export default FixedAssets;
