import {CovenantType, HeadcountType, ProductsType} from "../../../SharedComponents/Types";

export function calculatePotentialErrors(assumption, type) {
  if(type === ProductsType) {
     return assumption[type.categoryListProperty]
      .filter((category) => ["Direct product costs", "Revenue", "Closing recurring revenue"].includes(category.name))
      .flatMap(category =>
        category[type.monthListProperty]
          .filter(dto => category.name === "Direct product costs" ? (dto.value > 0 || dto.valueOverriden > 0) : (dto.value < 0 || dto.valueOverriden < 0))
          .map(dto => ({ dto, category }))
      );
  } else if(type === HeadcountType) {
    const categories = ["Total base salary costs"];

    if(assumption[HeadcountType.dtoProperty].assumption !== "PRICE_X_QUANTITY") {
      categories.push("Price");
    }

    return assumption[type.categoryListProperty]
      .filter((category) => categories.includes(category.name))
      .flatMap(category =>
        category[type.monthListProperty]
          .filter(dto => (dto.value > 0 || dto.valueOverriden > 0))
          .map(dto => ({ dto, category })));
  }

  return assumption[type.categoryListProperty]
    .slice(-1)
    .flatMap(category =>
      category[type.monthListProperty]
        .filter(dto => type.isPositive ? (dto.value < 0 || dto.valueOverriden < 0) : (dto.value > 0 || dto.valueOverriden > 0))
        .map(dto => ({ dto, category }))
    );
}

export function calculateTotalOverrides(assumption, type) {
  return assumption[type.categoryListProperty]
    .flatMap(category => category[type.monthListProperty])
    .filter(dto => dto.valueOverriden).length;
}

export function calculateCovenantBreaches(covenant) {
  return covenant[CovenantType.categoryListProperty]
    .filter(category => category.name === "Breach Indicator")
    .flatMap(category => category[CovenantType.monthListProperty])
    .filter(dto => dto.value === 1).length;
}