import React, {useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";

import {Card as MuiCard, CardContent, Skeleton, Table, TableBody, TableCell, TableHead, TableRow,} from "@mui/material";
import {spacing} from "@mui/system";
import axios from "axios";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import CurrentAssetNavBar from "./CurrentAssetNavBar";
import RowTitle from "../../../SharedComponents/RowTitle";
import MonthHeader, {generateXaxisHeaders} from "../../../SharedComponents/MonthHeader";
import {AssumptionLabel} from "../../../SharedComponents/AssumptionLabel";
import {getCurrentProject} from "../../../SharedComponents/ProjectServices";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {zoomState} from "../../../SharedState/ZoomState";
import {revenueAndCostState} from "../../../SharedState/RevenueAndCostState";
import {balanceSheetState} from "../../../SharedState/BalanceSheetState";
import {currentAssetsState} from "../../../SharedState/CurrentAssetsState";
import {identifyPotentialError} from "../../../SharedComponents/calculations/Calculations";
import {CurrentAssetsType} from "../../../SharedComponents/Types";
import ErrorNoteModal from "../../../SharedComponents/ErrorNoteModal";
import AddAdjustment from "../../../Analysis/Covenance/components/AddAdjustment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";


const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};

const helpTitles = new Map();

helpTitles.set('New Aged Debt', 'New aged debtor should be a positive value');
helpTitles.set('Aged debt released', 'Aged debtor release should be a negative value');
helpTitles.set('Percentage of selected costs', 'Percentage of selected costs should be a negative percentage value to generate a positive  (debit balance) current asset ');

function CurrentAssetInput(props) {
  const { key } = useProject();
  const { project } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const tableRef = useRef(null);

  const zoomAtom = useRecoilValue(zoomState);

  const setProductsAtom = useSetRecoilState(revenueAndCostState);
  const setBalanceAtom = useSetRecoilState(balanceSheetState);
  const setCurrentAssetsAtom = useSetRecoilState(currentAssetsState);

  const [isLoading, setIsLoading] = useState(true);
  const [showErrorNoteDialog, setShowErrorNoteDialog] = useState(false);
  const [clickedCell, setClickedCell] = useState(null);

  const [addAdjustmentDialogOpen, setAddAdjustmentDialogOpen] = useState(false);
  const [clickedRowId, setClickedRowId] = useState(null);


  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);

  const {
    currentAssetItem,
    currentAssets,
    refreshData,
    setEditData,
    editData,
    setCurrentAssets,
    loadData,
    products,
    overheads,
    salaries,
  } = props;

  const deleteCurrentAsset = (handleClose) => {
    const config = {
      method: "delete",
      url: "currentassets",
      data: currentAssetItem.currentAssetDto,
    };

    axios(config)
      .then(async function(response) {
        // do something to trigger a redraw
        // console.log(response.data)
        //todo - refresh the balance atom?
        //todo call a mini recalc to update the balance sheet other current assets

        await axios.get("currentassets/" + getCurrentProject().id).then(function(response) {
          setCurrentAssetsAtom(response.data);
          setCurrentAssets([...structuredClone(response.data)]);
        });

        await axios.get("revenuecos/" + getCurrentProject().id).then(function(response) {
          console.log('products should be empty', response.data);
          setProductsAtom(response.data);
        });

        await axios.get("balance/" + getCurrentProject().id).then(function(response) {
          setBalanceAtom(response.data);
        });

        window.location.reload(); //required for Loan Assets as the VatCalculation is out of date and this was an easy enough way to flush it.

        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteAdjustment = (handleClose) => {
    const config = {
      method: "delete",
      url: "currentassets/adjustment",
      data: {
        project: project.id,
        categoryId: clickedRowId
      }
    };
    axios(config)
      .then(async function (response) {
        await axios.get("currentassets/" + project.id).then(function (response) {
          setCurrentAssetsAtom(response.data);
        });
        tableRef.current.updatePowdrModelGlobal();
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstBalanceForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }


  function getName(row) {

    let name;

    if (row.overrideName !== null && (row.name === "Product Name" || row.name === "Adjustment")) {
      name = row.overrideName;
    } else if (row.overrideName !== null && row.name === "Payment") {
      name = "Payment - " + row.overrideName;
    } else if (row.overrideName !== null && row.name === "P&L release") {
      name = "P&L release - " + row.overrideName;
    } else {
      name = row.name;
    }

    if (row.name === 'Days Receivable') {
      name = row.overrideName;
    }

    return name;
  }

  const handleRightClick = (e, cellData, assumption, type) => {
    if(e.type === "contextmenu" && identifyPotentialError(cellData, assumption, type)) {
      setClickedCell(cellData);
      setShowErrorNoteDialog(true);
    }
    e.preventDefault();
  }

  function renderHeavyContent() {
    return (
      <div>
        <CurrentAssetNavBar
          item={currentAssetItem.currentAssetDto}
          categories={currentAssetItem.currentAssetCategoryDtos}
          deleteMethod={deleteCurrentAsset}
          refreshDataMethod={loadData}
          products={products}
          overheads={overheads}
          salaries={salaries}
          exportToExcel={{fileName: "Powdr-CurrentAsset-" + currentAssetItem.currentAssetDto.name + "-" + new Date().toDateString(),
            sheet : "Current Asset",
            currentTableRef : tableRef.current}}
          shallowUpdateUrl={CurrentAssetsType.shallowUpdateUrl}
        ></CurrentAssetNavBar>

        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0,
          }}
          id={currentAssetItem.currentAssetDto.name.replaceAll(" ", "-") + "-table"}
        >
          <CardContent sx={{ paddingTop: 0 , zoom: zoomAtom}}>
            <Table ref={tableRef}>
              {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
              <TableHead>
                <TableRow
                  key={currentAssetItem.currentAssetDto.name + "-ROW"}
                  id={currentAssetItem.currentAssetDto.name + "-ROW"}
                >
                  <TableCell
                    key={currentAssetItem.currentAssetDto.name + "-BASE"}
                    id={currentAssetItem.currentAssetDto.name + "-BASE"}
                    style={{ position: "sticky", left: 0 }}
                    sx={{
                      minWidth: 200,
                      maxWidth: 200,
                      width: 200,
                      backgroundColor: "#F7F9FC",
                      zIndex: 10,
                    }}
                  >
                    <h2>{currentAssetItem.currentAssetDto.name}</h2>
                    <AssumptionLabel label={currentAssetItem.currentAssetDto.assumptionDesc} color="success" />
                  </TableCell>

                  {XaxisHeaders.map((yHeader, index) => (
                    <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={currentAssetItem.currentAssetDto.name} index={index}></MonthHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>

                {currentAssetItem.currentAssetCategoryDtos.map((row) => (
                  <>
                  {(row.name === "Closing" && currentAssetItem.currentAssetDto.assumption === "SIMPLE_CORKSCREW") &&
                    <TableRow sx={{
                      justifyContent: 'center',
                      paddingY: "5px"
                    }}>
                      <AddCircleOutlineIcon
                        sx={{
                          cursor: "pointer",
                          color: "black",
                          left: 100,
                          position: "sticky",
                          '&:hover': {
                            color: "#2f66cb",
                          }
                        }}
                        onClick={() => setAddAdjustmentDialogOpen(true)}
                      />
                    </TableRow>}


                    <TableRow key={row.id} id={row.id} onClick={() => handleClick(row.id)}>

                      <RowTitle name={getName(row)}
                                alt={helpTitles.get(row.name)}
                                canDelete={
                                  row.name === "Adjustment" &&
                                  currentAssetItem.currentAssetDto.assumption === "SIMPLE_CORKSCREW"
                                }
                                deleteFunction={deleteAdjustment}
                                rowId={row.id}
                                setSelectedRowId={setClickedRowId}
                      />

                      {row.currentAssetMonthDtos.map((month) => (
                        <PowdrCell
                          key={month.id + "-CELL"}
                          cellData={month}
                          ownerId={row.id}
                          editTableEnabled={editData}
                          items={currentAssets}
                          refreshItemData={refreshData}
                          setItems={setCurrentAssets}
                          assumption={currentAssetItem}
                          categoryName={row.name}
                          parentName={currentAssetItem.currentAssetDto.name}
                          category={row}
                          monthIndex={month.monthIndex}
                          monthAttributeName={'currentAssetMonthDtos'}
                          handleRightClick={handleRightClick}
                          type={CurrentAssetsType}
                        ></PowdrCell>
                      ))}
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        {clickedCell && <ErrorNoteModal open={showErrorNoteDialog} setOpen={setShowErrorNoteDialog}
                                        item={clickedCell} type={CurrentAssetsType} projectId={project.id}
                                        setItems={setCurrentAssetsAtom} items={currentAssets}/>}

        <AddAdjustment
          open={addAdjustmentDialogOpen}
          setOpen={setAddAdjustmentDialogOpen}
          assumptionDto={currentAssetItem.currentAssetDto}
          setAssumptionAtom={setCurrentAssetsAtom}
          assumptionType={CurrentAssetsType}
        />
      </div>
    )
  }

  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default CurrentAssetInput;
