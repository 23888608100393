import React, { useState, useEffect, useRef } from "react";
import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  FormControlLabel, FormGroup,
  FormLabel,
  Grid, LinearProgress,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Add } from "@mui/icons-material";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { currentAssetsState } from "../../../SharedState/CurrentAssetsState";
import { revenueAndCostState } from "../../../SharedState/RevenueAndCostState";
import { overheadsState } from "../../../SharedState/OverheadsState";
import { headcountsState } from "../../../SharedState/HeadcountsState";
import { balanceSheetState } from "../../../SharedState/BalanceSheetState";
import UpdatePowdrModel from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModel";
import ReleaseSelect from "./ReleaseSelect";
import PaymentFrequencySelect from "./PaymentFrequencySelect";

function AddCurrentAsset({callback}) {
  const [open, setOpen] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState([]);

  const [productsAtom, setProductsAtom] = useRecoilState(revenueAndCostState);
  const overheadsAtom = useRecoilValue(overheadsState);
  const headcountsAtom = useRecoilValue(headcountsState);

  const [products, setProducts] = useState([]);
  const [salaries, setHeadcounts] = useState([])
  const [overheads, setOverheads] = useState([])

  const [productsReleaseProfiles, setProductsReleaseProfiles] = useState([]);
  const [headcountReleaseProfiles, setHeadcountsReleaseProfiles] = useState([])
  const [overheadsReleaseProfiles, setOverheadsReleaseProfiles] = useState([])

  const [paymentFrequencies, setPaymentFrequencies] = useState([])

  const setBalanceSheet = useSetRecoilState(balanceSheetState);

  const [currentAssetsAtom, setCurrentAssetsAtom] = useRecoilState(currentAssetsState);

  const updatePowdrRef = useRef();

  const [assumptionFormatValue, setAssumptionFormatValue] =
    useState("SIMPLE_CORKSCREW");

  useEffect(() => {

    setProducts(structuredClone(productsAtom));
    setHeadcounts(structuredClone(headcountsAtom));
    setOverheads(structuredClone(overheadsAtom));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAtom, headcountsAtom, overheadsAtom]);

  const validationSchema = Yup.object().shape({
    currentAssetName: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(150, "Must be less than 150 characters")
      .required("Required")
      .notOneOf(currentAssetsAtom?.map(function(i) {
        return i?.currentAssetDto?.name;
      }) || [], "Must have a unique name"),
  });

  const initialValues = {
    currentAssetName: "",
    assumptionFormat: "",
    directProductCosts : false,
  };

  const handleChangeProduct = (event) => {

    const productCh = products
      .filter(product => product.productDto.name === event.target.name)[0];

    productCh.productDto.checked = event.target.checked;

    setTriggerRefresh({
      ...triggerRefresh
    })

  };

  const handleChangeOverhead = (event) => {

    const overheadCh = overheads
      .filter(overhead => overhead.overheadDto.name === event.target.name)[0];

    overheadCh.overheadDto.checked = event.target.checked;

    setTriggerRefresh({
      ...triggerRefresh
    })

  };

  const handleChangeSalary = (event) => {

    const salaryCh = salaries
      .filter(salary => salary.headCountDto.name === event.target.name)[0];

    salaryCh.headCountDto.checked = event.target.checked;

    setTriggerRefresh({
      ...triggerRefresh
    })

  };

  function validateAccruals(currentAsset, assumption) {
    // Helper function to validate the existence of an entry in the release profile
    function validateReleaseProfile(items, releaseProfile, itemType) {
      for (let item of items) {
        const matchingProfile = releaseProfile.find(profile => profile.item === item);
        if (!matchingProfile) {
          window.alert(`You Must select a release profile for each Accrual item.`);
          return false;
        }
      }
      return true;
    }

    console.log(currentAsset)

    if (assumption === "ACCRUAL") {

      // Validate products
      if (!validateReleaseProfile(currentAsset.products, currentAsset.productsReleaseProfile, "product")) {
        return false;
      }

      // Validate overheads
      if (!validateReleaseProfile(currentAsset.overheads, currentAsset.overheadsReleaseProfile, "overhead")) {
        return false;
      }

      // Validate salaries
      if (!validateReleaseProfile(currentAsset.salaries, currentAsset.salariesReleaseProfile, "salary")) {
        return false;
      }
    }

    // If all validations pass
    return true;
  }

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {

      let assetValidationObject = {
        products: products.filter(item => item.productDto.checked === true).map(item => item.productDto["id"]),
        overheads: overheads.filter(item => item.overheadDto.checked === true).map(item => item.overheadDto["id"]),
        salaries: salaries.filter(item => item.headCountDto.checked === true).map(item => item.headCountDto["id"]),
        productsReleaseProfile: productsReleaseProfiles,
        overheadsReleaseProfile: overheadsReleaseProfiles,
        salariesReleaseProfile: headcountReleaseProfiles,
      }

      //validate Accruals
      let passed = validateAccruals(assetValidationObject, assumptionFormatValue);
      if (passed === false) {return;}

      setSubmittingForm(true)
      axios
        .post("currentassets", {
          name: values.currentAssetName,
          project: getCurrentProject().id,
          assumption: assumptionFormatValue,
          percentageComplete: 0,
          overrideComplete: false,
          canDelete: true,
          directProductCosts: values.directProductCosts,
          products: products.filter(item => item.productDto.checked === true).map(item => item.productDto["id"]),
          overheads: overheads.filter(item => item.overheadDto.checked === true).map(item => item.overheadDto["id"]),
          salaries: salaries.filter(item => item.headCountDto.checked === true).map(item => item.headCountDto["id"]),
          productsReleaseProfile: productsReleaseProfiles,
          overheadsReleaseProfile: overheadsReleaseProfiles,
          salariesReleaseProfile: headcountReleaseProfiles,
          paymentFrequencyDtos: paymentFrequencies
        })
        .then(async function(response) {

          //update the atoms - Fixed Asset & Balance
          await axios.get("currentassets/" + getCurrentProject().id).then(function(response) {
            setCurrentAssetsAtom(response.data);
          });

          await axios.get("revenuecos/" + getCurrentProject().id).then(function(response) {
            setProductsAtom(response.data);
          });

          await axios.get("balance/" + getCurrentProject().id).then(function(response) {
            setBalanceSheet(response.data);
          });


          products.forEach(item => item.productDto.checked = false);
          overheads.forEach(item => item.overheadDto.checked = false);
          salaries.forEach(item => item.headCountDto.checked = false);

          setProductsReleaseProfiles([]);
          setHeadcountsReleaseProfiles([]);
          setOverheadsReleaseProfiles([]);
          setSubmittingForm(false);
          resetForm();
          setOpen(false);
        })
        .catch(function (error) {
          console.log(error);
          setSubmittingForm(false);
          setOpen(false);
        });


    } catch (error) {
      setErrors({ submit: error.message });
      setSubmitting(false);
      setOpen(false);
    }
  };

  const handleChangeAssumption = (event) => {
    setAssumptionFormatValue(event.target.value);
  };

  const removeReleaseProfile = (itemId, type, setProfile) =>  {

    console.log('removeReleaseProfile', itemId, type)

    if (type === "product") {
      let tempList = structuredClone(productsReleaseProfiles);

      // Find the index of the existing productId
      const existingIndex = tempList.findIndex(item => item.item === itemId);

      // If the productId exists, remove it from the list
      if (existingIndex !== -1) {
        tempList.splice(existingIndex, 1);
      }

      setProductsReleaseProfiles(tempList);
    }

    if (type === "overhead") {
      let tempList = structuredClone(productsReleaseProfiles);

      // Find the index of the existing productId
      const existingIndex = tempList.findIndex(item => item.item === itemId);

      // If the productId exists, remove it from the list
      if (existingIndex !== -1) {
        tempList.splice(existingIndex, 1);
      }

      setOverheadsReleaseProfiles(tempList);
    }

    if (type === "salary") {
      let tempList = structuredClone(productsReleaseProfiles);

      // Find the index of the existing productId
      const existingIndex = tempList.findIndex(item => item.item === itemId);

      // If the productId exists, remove it from the list
      if (existingIndex !== -1) {
        tempList.splice(existingIndex, 1);
      }

      setHeadcountsReleaseProfiles(tempList);
    }

    setProfile('')

  }

  const removePaymentFrequency = (itemId, type) =>  {

    console.log('removePaymentFrequency', itemId, type, paymentFrequencies);

    //find the item id from the paymentFrequency list and remove it

    let tempList = structuredClone(paymentFrequencies);

    // Find the index of the existing productId
    const existingIndex = tempList.findIndex(item => item.item === itemId);

    // If the productId exists, remove it from the list
    if (existingIndex !== -1) {
       tempList.splice(existingIndex, 1);
    }

    console.log('removed', tempList);

    setPaymentFrequencies(tempList);

  }

  const handlePaymentFrequencyChange = (paymentFrequencyWrapper) => {

    console.log('handlePaymentFrequencyChange', paymentFrequencyWrapper)

    let tempList = structuredClone(paymentFrequencies);

    // Find the index of the existing item
    const existingIndex = tempList.findIndex(item => item.item === paymentFrequencyWrapper.item);

    // If the item exists, remove it from the list
    if (existingIndex !== -1) {
      tempList.splice(existingIndex, 1);
    }

    tempList.push(paymentFrequencyWrapper);

    setPaymentFrequencies(tempList);
  };

  const handleDropdownChange = (selectValue, itemId, type, setProfile) => {

    if (type === "product") {
      let tempList = structuredClone(productsReleaseProfiles);

      // Find the index of the existing productId
      const existingIndex = tempList.findIndex(item => item.item === itemId);

      // If the productId exists, remove it from the list
      if (existingIndex !== -1) {
        tempList.splice(existingIndex, 1);
      }

      // Add the new productId with its releaseProfile
      tempList.push({ item: itemId, releaseProfile: selectValue, linkTypeEnum: 'PRODUCT' });

      setProductsReleaseProfiles(tempList);
    }

    if (type === "overhead") {
      let tempList = structuredClone(overheadsReleaseProfiles);

      // Find the index of the existing productId
      const existingIndex = tempList.findIndex(item => item.item === itemId);

      // If the productId exists, remove it from the list
      if (existingIndex !== -1) {
        tempList.splice(existingIndex, 1);
      }

      // Add the new productId with its releaseProfile
      tempList.push({ item: itemId, releaseProfile: selectValue, linkTypeEnum: 'OVERHEAD' });

      setOverheadsReleaseProfiles(tempList);
    }

    if (type === "salary") {
      let tempList = structuredClone(headcountReleaseProfiles);

      // Find the index of the existing productId
      const existingIndex = tempList.findIndex(item => item.item === itemId);

      // If the productId exists, remove it from the list
      if (existingIndex !== -1) {
        tempList.splice(existingIndex, 1);
      }

      // Add the new productId with its releaseProfile
      tempList.push({ item: itemId, releaseProfile: selectValue, linkTypeEnum: 'SALARY' });

      setHeadcountsReleaseProfiles(tempList);
    }

    setProfile(selectValue);

  };

  return (
    <React.Fragment>
      <Grid container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Grid item xs={12}>
              <Button
                mr={2}
                variant="contained"
                color="primary"
                sx={{ marginBottom: 2 , minWidth : 160}}
                onClick={() => setOpen(true)}
              >
                Current Asset
                <Add />
              </Button>

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false);
                    setTriggerRefresh([]);
                    values.currentAssetName = "";
                  }
                }}
                aria-labelledby="form-dialog-title"
                fullWidth={["PERCENTAGE_OF_CERTAIN_OVERHEADS", "PERCENTAGE_OF_CERTAIN_REVENUE", "ACCRUAL", "PREPAYMENTS"].includes(assumptionFormatValue)}
                maxWidth={"xl"}
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Add Current Asset</DialogTitle>

                  {submittingForm &&  (
                    <DialogContent>
                      <DialogContentText>
                        Adding Current Asset...
                        <LinearProgress my={2} />
                      </DialogContentText>
                    </DialogContent>
                  )}

                  {!submittingForm &&  (
                  <DialogContent sx={{ paddingBottom: 0 }}>

                    <Grid container spacing={3}>
                      <Grid item  sx={{ maxWidth: 500, minWidth: 500,  marginRight: 2 }} >
                        <DialogContentText>
                          Enter the name of the current asset you wish to add.
                        </DialogContentText>
                        <TextField
                          error={Boolean(touched.currentAssetName && errors.currentAssetName)}
                          name="currentAssetName"
                          autoFocus
                          placeholder={"Current Asset Name"}
                          margin="dense"
                          id="currentAssetName"
                          label="Current Asset Name"
                          value={values.currentAssetName}
                          onChange={handleChange}
                          type="text"
                          helperText={touched.currentAssetName && errors.currentAssetName}
                          fullWidth
                        />

                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Assumption Format
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="assumptionFormat"
                          value={assumptionFormatValue}
                          onChange={handleChangeAssumption}
                        >
                          <FormControlLabel key="SIMPLE_CORKSCREW" value="SIMPLE_CORKSCREW" control={<Radio />} label="Simple Corkscrew"/>
                          <FormControlLabel key="PERCENTAGE_OF_DEBTORS" value="PERCENTAGE_OF_DEBTORS" control={<Radio />} label="Percentage of debtors"/>
                          <FormControlLabel key="PERCENTAGE_OF_CERTAIN_OVERHEADS" value="PERCENTAGE_OF_CERTAIN_OVERHEADS" control={<Radio />} label="Percentage of overheads"/>
                          <FormControlLabel key="PERCENTAGE_OF_CERTAIN_REVENUE" value="PERCENTAGE_OF_CERTAIN_REVENUE" control={<Radio />} label="Percentage of revenue"/>
                          <FormControlLabel key="LOAN_ASSET" value="LOAN_ASSET" control={<Radio />} label="Loan"/>
                          <FormControlLabel key="ACCRUAL" value="ACCRUAL" control={<Radio />} label="Uneven Receipts" />
                          <FormControlLabel key="PREPAYMENTS" value="PREPAYMENTS" control={<Radio />} label="Prepayments" />
                        </RadioGroup>

                      </Grid>

                      {assumptionFormatValue === "PREPAYMENTS" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">

                          <FormGroup>

                            <FormLabel component="legend">Overheads To include in Prepayments
                              calculation</FormLabel>

                            {overheads &&
                              overheads.map((overhead) => (
                                <Grid container spacing={2} alignItems="center" sx={{paddingBottom: 3}}>
                                  <Grid item xs={4} >
                                    <FormControlLabel
                                      key={Math.random() * 10000000}
                                      control={
                                        <Checkbox
                                          checked={overhead.overheadDto.checked}
                                          onChange={handleChangeOverhead}
                                          name={overhead.overheadDto.name}
                                          id={`${overhead.overheadDto.id}`}
                                        />
                                      }
                                      label={overhead.overheadDto.name}
                                    />
                                  </Grid>

                                  <Grid item xs={8}>
                                    <PaymentFrequencySelect hidden={!overhead.overheadDto.checked}
                                                            itemId={overhead.overheadDto.id}
                                                            type={'overhead'}
                                                            removePaymentFrequency={removePaymentFrequency}
                                                            hideFirstMonthOfAccrual={true}
                                                            handlePaymentFrequencyChange={handlePaymentFrequencyChange}/>
                                  </Grid>
                                </Grid>
                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                      {assumptionFormatValue === "ACCRUAL" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Products Revenue To include in percentage of accrual
                            calculation</FormLabel>
                          <FormGroup>


                            {products &&
                              products.map((product) => (
                                  <Grid container spacing={2} alignItems="center" sx={{paddingBottom: 3}}>
                                    <Grid item xs={9}>
                                      <FormControlLabel
                                        key={Math.random() * 10000000}
                                        control={
                                          <Checkbox
                                            checked={product.productDto.checked}
                                            onChange={handleChangeProduct}
                                            name={product.productDto.name}
                                            id={`${product.productDto.id}`}
                                          />
                                        }
                                        label={product.productDto.name}
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <ReleaseSelect hidden={!product.productDto.checked}
                                                     handleChangeOverride={handleDropdownChange}
                                                     itemId={product.productDto.id}
                                                     type={'product'}
                                                     removeReleaseProfile={removeReleaseProfile}/>
                                    </Grid>
                                  </Grid>
                              ))}


                            <FormLabel component="legend">Overheads To include in percentage of accrual
                              calculation</FormLabel>


                            {overheads &&
                              overheads.map((overhead) => (
                                <Grid container spacing={2} alignItems="center" sx={{paddingBottom: 3}}>
                                  <Grid item xs={9}>
                                    <FormControlLabel
                                      key={Math.random() * 10000000}
                                      control={
                                        <Checkbox
                                          checked={overhead.overheadDto.checked}
                                          onChange={handleChangeOverhead}
                                          name={overhead.overheadDto.name}
                                          id={`${overhead.overheadDto.id}`}
                                        />
                                      }
                                      label={overhead.overheadDto.name}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <ReleaseSelect hidden={!overhead.overheadDto.checked}
                                                   handleChangeOverride={handleDropdownChange}
                                                   itemId={overhead.overheadDto.id}
                                                   type={'overhead'}
                                                   removeReleaseProfile={removeReleaseProfile}/>
                                  </Grid>
                                </Grid>
                              ))}

                            <FormLabel component="legend">Salaries To include in percentage of accrual
                              calculation</FormLabel>


                            {salaries &&
                              salaries.map((salary) => (
                                <Grid container spacing={2} alignItems="center" sx={{paddingBottom: 3}}>
                                  <Grid item xs={9}>
                                    <FormControlLabel
                                      key={Math.random() * 10000000}
                                      control={
                                        <Checkbox
                                          checked={salary.headCountDto.checked}
                                          onChange={handleChangeSalary}
                                          name={salary.headCountDto.name}
                                          id={`${salary.headCountDto.id}`}
                                        />
                                      }
                                      label={salary.headCountDto.name}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <ReleaseSelect hidden={!salary.headCountDto.checked}
                                                   handleChangeOverride={handleDropdownChange}
                                                   itemId={salary.headCountDto.id}
                                                   type={'salary'}
                                                   removeReleaseProfile={removeReleaseProfile}/>
                                  </Grid>
                                </Grid>
                              ))}


                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }


                      {assumptionFormatValue === "PERCENTAGE_OF_CERTAIN_REVENUE" && <Grid item xs>
                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Products To include in percentage of revenue
                              calculation</FormLabel>
                            <FormGroup>

                              {products &&
                                products.map((product) => (
                                  <FormControlLabel key={Math.random() * 10000000}
                                    control={
                                      <Checkbox checked={product.productDto.checked} onChange={handleChangeProduct}
                                                name={product.productDto.name} id={product.productDto.id + ""} />
                                    }
                                    label={product.productDto.name}
                                  />

                                ))}

                            </FormGroup>

                          </FormControl>
                        </Grid>
                      }
                      {assumptionFormatValue === "PERCENTAGE_OF_CERTAIN_OVERHEADS" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Expenses To include in percentage of overheads
                            calculation</FormLabel>
                          <FormGroup>

                            {overheads &&
                              overheads.map((overhead) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                  control={
                                    <Checkbox checked={overhead.overheadDto.checked} onChange={handleChangeOverhead}
                                              name={overhead.overheadDto.name} id={overhead.overheadDto.id + ""} />
                                  }
                                  label={overhead.overheadDto.name}
                                />

                              ))}

                            <FormControlLabel key={Math.random() * 10000000}
                                              control={
                                                <Checkbox checked={values.directProductCosts}

                                                          onChange={handleChange}
                                                          name={"directProductCosts"}/>
                                              }
                                              label={"Direct Product Costs"}
                            />

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                      {assumptionFormatValue === "PERCENTAGE_OF_CERTAIN_OVERHEADS" && <Grid item xs>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Salaries To include in percentage of overheads
                            calculation</FormLabel>
                          <FormGroup>

                            {salaries &&
                              salaries.map((salary) => (
                                <FormControlLabel key={Math.random() * 10000000}
                                                  control={
                                                    <Checkbox checked={salary.headCountDto.checked} onChange={handleChangeSalary}
                                                              name={salary.headCountDto.name} id={salary.headCountDto.id + ""} />
                                                  }
                                                  label={salary.headCountDto.name}
                                />

                              ))}

                          </FormGroup>

                        </FormControl>
                      </Grid>
                      }

                    </Grid>

                  </DialogContent>
                  )}
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" disabled={submittingForm}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={submittingForm}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
          )}
        </Formik>
      </Grid>
      <UpdatePowdrModel ref={updatePowdrRef}/>
    </React.Fragment>
  );
}


export default AddCurrentAsset;
