import React, {useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";

import {Card as MuiCard, CardContent, Skeleton, Table, TableBody, TableCell, TableHead, TableRow,} from "@mui/material";
import {spacing} from "@mui/system";
import axios from "axios";
import useProject from "../../../../../hooks/useProject";
import PowdrCell from "../../../SharedComponents/PowdrCell/PowdrCell";
import MonthHeader, {generateXaxisHeaders} from "../../../SharedComponents/MonthHeader";
import RowTitle from "../../../SharedComponents/RowTitle";
import {AssumptionLabel} from "../../../SharedComponents/AssumptionLabel";
import getProjectId, {getCurrentProject} from "../../../SharedComponents/ProjectServices";
import EditDebt from "./EditDebt";
import PowdrNavBar from "../../../SharedComponents/PowdrNavBar";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {zoomState} from "../../../SharedState/ZoomState";
import {debtsState} from "../../../SharedState/DebtsState";
import {balanceSheetState} from "../../../SharedState/BalanceSheetState";
import {DebtType} from "../../../SharedComponents/Types";
import UpdatePowdrModelGlobal from "../../../SharedComponents/UpdatePowdr/UpdatePowdrModelGlobal";
import {identifyPotentialError} from "../../../SharedComponents/calculations/Calculations";
import ErrorNoteModal from "../../../SharedComponents/ErrorNoteModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddAdjustment from "../../../Analysis/Covenance/components/AddAdjustment";

const Card = styled(MuiCard)(spacing);

const tableWidth = {
  width: "100%",
};


function DebtInput(props) {
  const { key } = useProject();
  const { project } = useProject();

  const updatePowdrModelGlobalRef = useRef();

  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const tableRef = useRef(null);
  const zoomAtom = useRecoilValue(zoomState);

  const [isLoading, setIsLoading] = useState(true);
  const [showErrorNoteDialog, setShowErrorNoteDialog] = useState(false);
  const [clickedCell, setClickedCell] = useState(null);

  const setDebts = useSetRecoilState(debtsState);
  const setBalanceSheet = useSetRecoilState(balanceSheetState);

  const [addAdjustmentDialogOpen, setAddAdjustmentDialogOpen] = useState(false);
  const [clickedRowId, setClickedRowId] = useState(null);

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);


  const {
    debtItem,
    debt,
    refreshData,
    setEditData,
    editData,
    setDebt,
    loadData
  } = props;

  const deleteDebt = (handleClose) => {
    const config = {
      method: "delete",
      url: "debt",
      data: debtItem.debtDto,
    };

    axios(config)
      .then(async function(response) {

        let project = await getProjectId();

        setDebts(structuredClone(response.data))

        await axios.get("balance/" + project.id).then(function(response) {
          setBalanceSheet(response.data);
        });

        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteAdjustment = (handleClose) => {
    const config = {
      method: "delete",
      url: "debt/adjustment",
      data: {
        project: project.id,
        categoryId: clickedRowId
      }
    };
    axios(config)
      .then(async function (response) {
        await axios.get("debt/" + project.id).then(function (response) {
          setDebts(response.data);
        });
        updatePowdrModelGlobalRef.current.updatePowdrModelGlobal();
        handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstBalanceForecast));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id) {
    setEditData(id);
  }

  const handleRightClick = (e, cellData, assumption, type) => {
    if(e.type === "contextmenu" && identifyPotentialError(cellData, assumption, type)) {
      setClickedCell(cellData);
      setShowErrorNoteDialog(true);
    }
    e.preventDefault();
  }

  const getName = (row) => {
    return (row.overrideName !== null && row.name === "Adjustment") ?
      row.overrideName :
      row.name;
  }


  function renderHeavyContent() {
    return (
      <div>
        <PowdrNavBar
          item={debtItem.debtDto}
          categories={debtItem.debtCategoryDtos}
          deleteMethod={deleteDebt}
          refreshDataMethod={loadData}
          editAssumptionComponent={<EditDebt
                                             debt={debtItem.debtDto}
                                             open={openEdit}
                                             setOpen={setOpenEdit}
                                             updatePowdrModelGlobalRef={updatePowdrModelGlobalRef}>
          </EditDebt>}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          showExportToExcel={true}
          exportToExcel={{
            fileName: "Powdr-Debt-" + debtItem.debtDto.name + "-" + new Date().toDateString(),
            sheet: "Debt",
            currentTableRef: tableRef.current
          }}
          shallowUpdateUrl={DebtType.shallowUpdateUrl}
          type={DebtType}
        ></PowdrNavBar>


        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0
          }}
          id={debtItem.debtDto.name.replaceAll(" ", "-") + "-table"}
        >
          <CardContent sx={{ paddingTop: 0, zoom: zoomAtom }}>
            <Table ref={tableRef}>
              {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
              <TableHead>
                <TableRow
                  key={debtItem.debtDto.name + "-ROW"}
                  id={debtItem.debtDto.name + "-ROW"}
                >
                  <TableCell
                    key={debtItem.debtDto.name + "-BASE"}
                    id={debtItem.debtDto.name + "-BASE"}
                    style={{ position: "sticky", left: 0 }}
                    sx={{
                      minWidth: 200,
                      maxWidth: 200,
                      width: 200,
                      backgroundColor: "#F7F9FC",
                      zIndex: 10, // Ensure the header is above other content
                    }}
                  >
                    <h2>{debtItem.debtDto.name}</h2>
                    <AssumptionLabel label={debtItem.debtDto.assumptionDesc} color="success" />
                  </TableCell>
                  {XaxisHeaders.map((yHeader, index) => (
                    <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={debtItem.debtDto.name}
                                 index={index}></MonthHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>

                {debtItem.debtCategoryDtos.map((row) => (
                  <>
                  {row.name === "Closing" && debtItem.debtDto.assumption === "SIMPLE_CORKSCREW" &&
                    <TableRow sx={{
                      justifyContent: 'center',
                      paddingY: "5px"
                    }}>
                      <AddCircleOutlineIcon
                        sx={{
                          cursor: "pointer",
                          color: "black",
                          left: 100,
                          position: "sticky",
                          '&:hover': {
                            color: "#2f66cb",
                          }
                        }}
                        onClick={() => setAddAdjustmentDialogOpen(true)}
                      />
                    </TableRow>}

                    <TableRow key={row.id} id={row.id} onClick={() => handleClick(row.id)}>

                      <RowTitle name={getName(row)}
                                canDelete={
                                  row.name === "Adjustment" &&
                                  debtItem.debtDto.assumption === "SIMPLE_CORKSCREW"
                                }
                                deleteFunction={deleteAdjustment}
                                rowId={row.id}
                                setSelectedRowId={setClickedRowId}
                      />

                      {row.debtMonthDtos.map((month) => (
                        <PowdrCell
                          key={month.id + "-CELL"}
                          cellData={month}
                          ownerId={row.id}
                          editTableEnabled={editData}
                          items={debt}
                          refreshItemData={refreshData}
                          setItems={setDebt}
                          categoryName={row.name}
                          parentName={debtItem.debtDto.name}
                          category={row}
                          assumption={debtItem}
                          monthIndex={month.monthIndex}
                          monthAttributeName={'debtMonthDtos'}
                          handleRightClick={handleRightClick}
                          type={DebtType}
                        ></PowdrCell>
                      ))}
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
        {clickedCell && <ErrorNoteModal open={showErrorNoteDialog} setOpen={setShowErrorNoteDialog}
                                        item={clickedCell} type={DebtType} projectId={project.id}
                                        setItems={setDebts} items={debt}/>}

        <AddAdjustment
          open={addAdjustmentDialogOpen}
          setOpen={setAddAdjustmentDialogOpen}
          assumptionDto={debtItem.debtDto}
          setAssumptionAtom={setDebts}
          assumptionType={DebtType}
        />
      </div>
    );
  }

  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default DebtInput;
