import {Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import React, {useState} from "react";
import axios from "axios";
import useProject from "../../../../../hooks/useProject";


export default function AddAdjustment(props) {
  const { open, setOpen, assumptionDto, adjustmentType, setAssumptionAtom, assumptionType } = props;
  const [adjustmentName, setAdjustmentName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const { project } = useProject();

  function handleClose() {
    setAdjustmentName("");
    setOpen(false);
  }

  function saveAdjustment() {
    setSubmitting(true);
    axios.put(assumptionType.baseUrl.substring(1) + "/adjustment", {
      assumptionDto,
      adjustmentName,
      adjustmentType
    }).then(async (res) => {
      await axios.get(assumptionType.baseUrl.substring(1) + "/" + project.id).then(function (response) {
        setAssumptionAtom(response.data);
      });
      setSubmitting(false);
      setAdjustmentName("");
      setOpen(false);
    })
      .catch(() => {
        setSubmitting(false);
        setAdjustmentName("");
        setOpen(false);
      });
  }

  function handleAdjustmentNameChange(event) {
    setAdjustmentName(event.target.value);
  }

  return(
    <Dialog
    open={open}
    onClose={handleClose}
    fullWidth={true}

    >

      <DialogTitle>Add a new adjustment</DialogTitle>

      <DialogContent>
        <Typography gutterBottom>
          Name for this adjustment:</Typography>
        <TextField
          name="commentField"
          autoFocus
          multiline
          id="commentField"
          fullWidth
          value={adjustmentName}
          onChange={handleAdjustmentNameChange}
          type="text"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {submitting ? <CircularProgress size={24} /> : "Close"}
        </Button>
        <Button onClick={saveAdjustment} color="primary" autoFocus>
          {submitting ? <CircularProgress size={24} /> : "Save"}
        </Button>
      </DialogActions>

    </Dialog>
  )
}