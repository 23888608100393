import React, { useRef, useState} from "react";
import {
  Box,
  Card as MuiCard,
  CardContent,
  Grid,
  LinearProgress,
  Paper,
  Typography
} from "@mui/material";
import styled from "@emotion/styled";
import axios from "axios";
import { BackupTable, Delete, SettingsBackupRestore } from "@mui/icons-material";
import { spacing } from "@mui/system";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import IconButton from '@mui/material/IconButton';

import Tooltip from '@mui/material/Tooltip';

import Edit from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CommentIcon from '@mui/icons-material/Comment';
import KeyIcon from '@mui/icons-material/Key';

import DeleteModal from "./DeleteModal";
import YearNav from "./YearNav";
import { hiddenWhenMobile } from "./utils/Utils";
import { createExport } from "../Export/calculations/Calculations";
import UpdatePowdrModelGlobal from "./UpdatePowdr/UpdatePowdrModelGlobal";
import CommentModal from "./CommentModal";
import CellKey from "./Cellkey";

const Card = styled(MuiCard)(spacing);

function AccountMenu(props) {

  const updatePowdrModelGlobalRef = useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openKey, setOpenKey] = React.useState(false);


  // const [openEdit, setOpenEdit] = React.useState(false);
  const open = Boolean(anchorEl);

  const { item,
    categories,
    deleteMethod,
    refreshDataMethod,
    editAssumptionComponent,
    setOpenEdit,
    showExportToExcel = false,
    shallowUpdateUrl,
    showProgressBar,
    showEdit,
    showDelete,
    setOpenComment,
    isAnnual = false,
    } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);

    if (event.target.outerText === 'Delete') {
      setOpenDelete(true);
    }

    if (event.target.outerText === 'Edit') {
      setOpenEdit(true);
    }

    if (event.target.outerText === 'Comment') {
      setOpenComment(true);
    }

    if (event.target.outerText === 'Export') {

      createExport(categories, item, isAnnual)
      //onDownload();
    }


    if (event.target.outerText === 'Mark Complete') {
      try {
        //axios call to update the mark complete
        item.overrideComplete = !item.overrideComplete;

        axios.put(shallowUpdateUrl, item).then(function(response) {
          //then when it returns do an update
          refreshDataMethod();
        })
      } catch (e) {}

    }

    if(event.target.outerText === 'Key') {
      setOpenKey(true);
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', float: 'right' }}>
        <Tooltip title="More Options">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVertIcon></MoreVertIcon>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {showEdit &&
          <MenuItem value="edit" disabled={item.name === "Exceptionals" || item.assumption === "LOAN_ASSET_SISTER_PRODUCT"}>
            <ListItemIcon value="edit">
              <Edit fontSize="small">
                Edit
              </Edit>
            </ListItemIcon>
            Edit
          </MenuItem>
        }
        {showDelete &&
        <MenuItem value="delete" disabled={item.canDelete !== undefined && item.canDelete === false}>
          <ListItemIcon value="delete">
            <Delete fontSize="small" >
              Delete
            </Delete>
          </ListItemIcon>
          Delete
        </MenuItem>
        }
        <MenuItem value="export" hidden={showExportToExcel === false}>
          <ListItemIcon value="export">
            <BackupTable fontSize="small" >
              Export
            </BackupTable>
          </ListItemIcon>
          Export
        </MenuItem>

        <MenuItem value="export">
          <ListItemIcon value="export">
            <CommentIcon fontSize="small" >
              Comment
            </CommentIcon>
          </ListItemIcon>
          Comment
        </MenuItem>
        {showProgressBar &&
          <MenuItem value="markComplete">
            <ListItemIcon value="markComplete">
              <SettingsBackupRestore fontSize="small">
                Mark Complete
              </SettingsBackupRestore>
            </ListItemIcon>
            Mark Complete
          </MenuItem>
        }

        <MenuItem value="key">
          <ListItemIcon value="key">
            <KeyIcon fontSize="small">
              Key
            </KeyIcon>
          </ListItemIcon>
          Key
        </MenuItem>
      </Menu>


      <CellKey
      open={openKey}
      setOpen={setOpenKey} />

      <DeleteModal
        itemToDelete={item}
        deleteMethod={deleteMethod}
        name={item.name}
        open={openDelete}
        setOpen={setOpenDelete}
      ></DeleteModal>


      {editAssumptionComponent}

      <UpdatePowdrModelGlobal ref={updatePowdrModelGlobalRef}/>
    </React.Fragment>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingBottom: 10,
  color: theme.palette.text.secondary,
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


function getPercentageComplete(item) {

  if (item.overrideComplete === true) {
    return 100;
  } else {
    return item.percentageComplete;
  }

}

function PowdrNavBar(props) {

  const [openComment, setOpenComment] = useState(false)

  const {
    item,
    categories,
    deleteMethod,
    refreshDataMethod,
    showContextMenu = true,
    editAssumptionComponent,
    openEdit,
    setOpenEdit,
    showExportToExcel = false,
    exportToExcel,
    shallowUpdateUrl,
    showProgressBar = true,
    showEdit = true,
    showDelete = true,
    showNavigation = true,
    isAnnual = false,
    type} = props;

  return (
    <React.Fragment>
      <CommentModal open={openComment} setOpen={setOpenComment} item={item} shallowUpdateUrl={shallowUpdateUrl} type={type}></CommentModal>
      <Card
        key={item.name.replace(" ", "-") + "PI"}
        sx={{ pb: 0 }}
        style={{ pb: 0 }}
        id={item.name.replace(" ", "-") + "PI"}
      >
        <CardContent sx={{ pb: 0 }}>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            style={{ position: "sticky", left: 0, paddingBottom: 0 }}
          >
            {/* NAVIGATION A,1,2,3,4,5 */}
            <Grid item xs={4}>
              {showNavigation &&
                <Item
                  style={{ position: "sticky", minWidth: 272 }}
                  id={"YEAR-NAV"}
                  key={"YEAR-NAV"}
                >
                  <YearNav productName={item.name.replaceAll(" ", "-")}></YearNav>

                  {item.comment !== null && item.comment !== undefined &&
                    <CommentIcon visibility={0} onClick={() => setOpenComment(true)}
                                 sx={{ marginLeft: 5, cursor: 'pointer' }}></CommentIcon>
                  }

                </Item>
              }

            </Grid>
            {/* PERCENTAGE COMPLETE */}
            <Grid item xs={3} sx={hiddenWhenMobile}>
              {showProgressBar &&
              <Item
                style={{ position: "sticky", left: 0 }}
                id={"LINEAR-PROGRESS"}
                key={"LINEAR-PROGRESS"}
              >
                  Percentage Complete:{" "}
                  <LinearProgressWithLabel value={getPercentageComplete(item)} />
              </Item>
              }
            </Grid>
            {/* DELETE PRODUCT */}
            <Grid item xs={2} id={"BLANK"} key={"BLANK"}></Grid>
            <Grid item xs={3} >
              {showContextMenu &&
                <Item id={"DELETE"} key={"DELETE"}>
                  <AccountMenu item={item}
                               categories={categories}
                               deleteMethod={deleteMethod}
                               refreshDataMethod={refreshDataMethod}
                               editAssumptionComponent={editAssumptionComponent}
                               openEdit={openEdit} setOpenEdit={setOpenEdit}
                               showExportToExcel={showExportToExcel}
                               exportToExcel={exportToExcel}
                               shallowUpdateUrl={shallowUpdateUrl}
                               showProgressBar={showProgressBar}
                               showEdit={showEdit}
                               showDelete={showDelete}
                               setOpenComment={setOpenComment}
                               isAnnual={isAnnual}></AccountMenu>
                </Item>
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>


    </React.Fragment>
  );
}

export default PowdrNavBar;