import React, {useState} from "react";
import {TableCell} from "@mui/material";
import DeleteModal from "./DeleteModal";
import {Delete} from "@mui/icons-material";

const xAxisStyling = {
  minWidth: 200,
  maxWidth: 200,
  fontWeight: "bolder",
  color: "#2052c2",
  backgroundColor: "#F7F9FC",
};

export default function RowTitle(props) {
  const {name, alt, defaultBackground = "#F7F9FC", canDelete, deleteFunction, rowId, setSelectedRowId} = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  function handleClick() {
    setSelectedRowId(rowId);
    setShowDeleteModal(true);
  }

  return (
    <TableCell
      title={alt}
      component="th"
      scope="row"
      variant={"body"}
      sx={{...xAxisStyling, backgroundColor: "#F7F9FC"}}
      style={{
        position: "sticky",
        left: 0,
        backgroundColor: defaultBackground,
        zIndex: 1,
      }}
    >
      {name}
      {
        canDelete && <Delete
          fontSize="small"
          sx={{
            position: "absolute",
            right: "5px",
            color: "black",
            '&:hover': {
              color: "#2f66cb",
            },
            cursor: "pointer",
          }}
        onClick={handleClick}/>
      }
      {canDelete &&
        <DeleteModal
          deleteMethod={deleteFunction}
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          name={name}
        >

        </DeleteModal>}
    </TableCell>
  );
}

