import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import {
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";


import Settings from "../../../../components/Settings";
import AddDebt from "./components/AddDebt";
import {  calculateDebt } from "./calculations/Calculations";
import DebtInput from "./components/DebtInput";
import AssumptionNotification from "../../SharedComponents/AssumptionNotificaiton";
import PageHeader from "../../SharedComponents/PageHeader";
import ControlsBar from "../../SharedComponents/ControlsBar";
import { calculatePercentageComplete } from "../../SharedComponents/utils/PercentageComplete";
import DebtSummary from "./components/DebtSummary";
import { useRecoilState, useRecoilValue } from "recoil";
import { debtsState } from "../../SharedState/DebtsState";
import UpdatePowdrModel from "../../SharedComponents/UpdatePowdr/UpdatePowdrModel";
import { processAlert } from "../../SharedComponents/utils/NotificationUtils";
import { hasCellValueChanged } from "../../SharedComponents/utils/Utils";
import { debtAssumptionsMap } from "../../SharedComponents/AssumptionMaps";
import { balanceSheetState } from "../../SharedState/BalanceSheetState";
import {DebtType} from "../../SharedComponents/Types";

const Divider = styled(MuiDivider)(spacing);

function Debt() {

  const updatePowdrRef = useRef();

  const [openAlert, setOpenAlert] = useState(false);
  const [debts, setDebts] = useState([]);
  const [editTableEnabled, setEditTableEnabled] = useState("");

  const [debtsAtom, setDebtsAtom] = useRecoilState(debtsState);
  const balanceAtom = useRecoilValue(balanceSheetState);

  async function loadDebt() {

    processAlert(setOpenAlert, 6);

    let debts_ = structuredClone(debtsAtom);

    calculatePercentageComplete(debts_, DebtType);

    calculateDebt(debts_, structuredClone(balanceAtom))

    setDebts(debts_);
  }

  function updatePorwdrModel(cellData) {
    updatePowdrRef.current.updatePowdrModel(cellData,
      debts,
      "debt",
      "DEBT",
      debtAssumptionsMap);
  }

  function refreshData(cellData) {

    if (hasCellValueChanged(cellData)) {

      calculatePercentageComplete(debts, DebtType);

      calculateDebt(debts, structuredClone(balanceAtom))

      updatePorwdrModel(cellData);

      setDebtsAtom(structuredClone(debts))
    }
  }

  useEffect(() => {

    loadDebt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtsAtom]);


  return (
    <React.Fragment>
      <PageHeader pageName={"Debts"} parentName={"Assumptions"}/>

      <AssumptionNotification assumptionName={"debts"} openAlert={openAlert} setOpenAlert={setOpenAlert}></AssumptionNotification>

      <Divider my={6} />

      <ControlsBar addAssumptionElement={
          <AddDebt/>
      }/>

      <DebtSummary/>

      {!!debts &&
        debts.map((debt) => (
          <DebtInput
            key={debt.debtDto.id}
            debtItem={debt}
            debt={debts}
            refreshData={refreshData}
            setEditData={setEditTableEnabled}
            editData={editTableEnabled}
            setDebt={setDebts}
            loadData={loadDebt}
          ></DebtInput>
        ))}

      <UpdatePowdrModel ref={updatePowdrRef}/>
      <Settings />

    </React.Fragment>
  );
}

export default Debt;
