import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import useProject from "../../../hooks/useProject";
import { useSetRecoilState } from "recoil";
import { revenueAndCostState } from "../SharedState/RevenueAndCostState";
import { overheadsState } from "../SharedState/OverheadsState";
import { headcountsState } from "../SharedState/HeadcountsState";
import { fixedAssetsState } from "../SharedState/FixedAssetsState";
import { currentAssetsState } from "../SharedState/CurrentAssetsState";
import { currentLiabilitiesState } from "../SharedState/CurrentLiabilitiesState";
import { debtsState } from "../SharedState/DebtsState";
import { equitiesState } from "../SharedState/EquitiesState";
import { pnlState } from "../SharedState/PnLState";
import { balanceSheetState } from "../SharedState/BalanceSheetState";
import { covenantState } from "../SharedState/CovenantState";
import {
  BalanceType, CovenantType,
  CurrentAssetsType, CurrentLiabilitiesType, DebtType, EquityType,
  FixedAssetsType,
  HeadcountType,
  OverheadsType, PNLType,
  ProductsType
} from "./Types";

export default function CommentModal(props) {
  const { project } = useProject();

  const setCovenant = useSetRecoilState(covenantState);
  const setPnl = useSetRecoilState(pnlState);
  const setBalanceSheet = useSetRecoilState(balanceSheetState);
  const setProductsAtom = useSetRecoilState(revenueAndCostState);
  const setOverheadsAtom = useSetRecoilState(overheadsState);
  const setHeadcountsAtom = useSetRecoilState(headcountsState);
  const setFixedAssetAtom = useSetRecoilState(fixedAssetsState);
  const setCurrentAssets = useSetRecoilState(currentAssetsState);
  const setCurrentLiabilities = useSetRecoilState(currentLiabilitiesState);
  const setDebts = useSetRecoilState(debtsState);
  const setEquities = useSetRecoilState(equitiesState);

  const { open, setOpen, item, shallowUpdateUrl, type } = props;

  const [commentText, setCommentText] = useState(item.comment || ""); // Initialize with the comment's content
  const [shallowUpdateUrlState, setShallowUpdateUrlState] = useState("");

  useEffect(() => {

    setShallowUpdateUrlState(shallowUpdateUrl);
    setCommentText(item.comment);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  function handleClose() {
    setOpen(false);
  }

  function handleCommentChange(event) {
    setCommentText(event.target.value); // Update state with the new comment text
  }

  function saveComment() {

    // Use commentText from state, which is updated on every change in the TextField
    axios.put(shallowUpdateUrlState, {...item, comment : commentText})
      .then(async function(response) {

        if (type === ProductsType) {
          await axios.get("revenuecos/" + project.id).then(function(response) {
            setProductsAtom(response.data);
          });
        }

        if (type === OverheadsType) {
          await axios.get("overheads/" + project.id).then(function(response) {
            setOverheadsAtom(response.data);
          });
        }

        if (type === HeadcountType) {
          await axios.get("headcounts/" + project.id).then(function(response) {
            setHeadcountsAtom(response.data);
          });
        }

        if (type === FixedAssetsType) {
          await axios.get("fixedassets/" + project.id).then(function(response) {
            setFixedAssetAtom(response.data);
          });
        }

        if (type === CurrentAssetsType) {
          await axios.get("currentassets/" + project.id).then(function(response) {
            setCurrentAssets(response.data);
          });
        }

        if (type === CurrentLiabilitiesType) {
          await axios.get("currentliabilities/" + project.id).then(function(response) {
            setCurrentLiabilities(response.data);
          });
        }

        if (type === DebtType) {
          await axios.get("debt/" + project.id).then(function(response) {
            setDebts(response.data);
          });
        }

        if (type === EquityType) {
          await axios.get("equity/" + project.id).then(function(response) {
            setEquities(response.data);
          });
        }

        if (type === PNLType) {
          await axios.get("pnl/" + project.id).then(function(response) {
            setPnl(response.data);
          });
        }

        if (type === BalanceType) {
          await axios.get("balance/" + project.id).then(function(response) {
            setBalanceSheet(response.data);
          });
        }

        if (type === CovenantType) {
          await axios.get("covenant/" + project.id).then(function(response) {
            setCovenant(response.data);
          });
        }

        setOpen(false);
        // Optionally, reset commentText state or do additional handling here
      })
      .catch(function(error) {
        // Handle error here
        console.error("Error saving comment: ", error);
      });
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">Comment</DialogTitle>
        <DialogContent>
          {/* Directly use Typography without DialogContentText for complex components */}
          <Typography gutterBottom>Comment for this assumption:</Typography>
          <TextField
            name="commentField"
            autoFocus
            multiline
            rows={6}
            id="commentField"
            fullWidth
            value={commentText} // Use state for controlled input
            onChange={handleCommentChange} // Handle changes
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={saveComment} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
