import React, { useState } from "react";
import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  FormControlLabel, FormGroup,
  FormLabel,
  Grid, LinearProgress,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Add } from "@mui/icons-material";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import { useSetRecoilState } from "recoil";
import { covenantState } from "../../../SharedState/CovenantState";
import useProject from "../../../../../hooks/useProject";


function AddCovenant(props) {

  const { project } = useProject();

  const [open, setOpen] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState([]);
  const setCovenant = useSetRecoilState(covenantState);
  const [assumptionFormatValue, setAssumptionFormatValue] = useState("LEVERAGE_COVENANT");
  const [covenantPeriod, setCovenantPeriod] = useState("LAST_12_MONTHS");

  let { names, debts } = props;

  const validationSchema = Yup.object().shape({
    covenantName: Yup.string()
      .min(5, "Must be at least 5 characters")
      .max(50, "Must be less than 50 characters")
      .required("Required")
      .notOneOf(names, "Must have a unique name"),
  });

  const initialValues = {
    covenantName: "",
    assumptionFormat: "",
    includeCashOnBalance: false,
  };

  const handleChangeDebt = (event) => {

    let debtChanged = debts
      .filter(debt => debt.debtDto.name === event.target.name)[0];

    debtChanged.debtDto.checked = event.target.checked;

    //console.log(debts.filter(item => item.debtDto.checked === true).map(item => item.debtDto["id"]));

    setTriggerRefresh({
      ...triggerRefresh
    })

  };


  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      setSubmittingForm(true)
      axios
        .post("covenant", {
          name: values.covenantName,
          project: getCurrentProject().id,
          assumption: assumptionFormatValue,
          percentageComplete: 0,
          overrideComplete: false,
          canDelete: true,
          includeCashOnBalance: values.includeCashOnBalance,
          debts: debts.filter(item => item.debtDto.checked === true).map(item => item.debtDto["id"]),
          covenantPeriod: covenantPeriod
        })
        .then(async function(response) {
          console.log('covent refresh from comment modal')
          await axios.get("covenant/" + project.id).then(function(response) {
            console.log(response.data)
            setCovenant(response.data);
          });
          setSubmittingForm(false);
          resetForm();
          setOpen(false);
        })
        .catch(function (error) {
          console.log(error);
          setSubmittingForm(false);
        });


    } catch (error) {
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const handleChangeAssumption = (event) => {
    setAssumptionFormatValue(event.target.value);
  };

  const handleChangeCovenantPeriod = (event) => {
    setCovenantPeriod(event.target.value);
  }

  return (
    <React.Fragment>
      <Grid container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Grid item xs={12}>
              <Button
                mr={2}
                variant="contained"
                color="primary"
                sx={{ marginBottom: 2 , minWidth : 160}}
                onClick={() => setOpen(true)}
              >
                Add Covenant
                <Add />
              </Button>

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setOpen(false);
                    setTriggerRefresh([]);
                    values.covenantName = "";
                  }
                }}
                aria-labelledby="form-dialog-title"
                fullWidth={["LEVERAGE_COVENANT"].includes(assumptionFormatValue)}
                maxWidth={"md"}
              >
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">Add Covenant</DialogTitle>

                  {submittingForm &&  (
                    <DialogContent>
                      <DialogContentText>
                        Adding Covenant...
                        <LinearProgress my={2} />
                      </DialogContentText>
                    </DialogContent>
                  )}

                  {!submittingForm &&  (
                  <DialogContent sx={{ paddingBottom: 0 }}>

                    <Grid container spacing={3}>
                      <Grid item xs>
                        <DialogContentText>
                          Enter the name of the covenant you wish to add.
                        </DialogContentText>
                        <TextField
                          error={Boolean(touched.covenantName && errors.covenantName)}
                          name="covenantName"
                          autoFocus
                          placeholder={"Covenant Name"}
                          margin="dense"
                          id="covenantName"
                          label="Covenant Name"
                          value={values.covenantName}
                          onChange={handleChange}
                          type="text"
                          helperText={touched.covenantName && errors.covenantName}
                          fullWidth
                        />

                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Assumption Format
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="assumptionFormat"
                          value={assumptionFormatValue}
                          onChange={handleChangeAssumption}
                        >
                          <FormControlLabel key="LEVERAGE_COVENANT" value="LEVERAGE_COVENANT" control={<Radio />} label="Leverage Covenant"/>
                          <FormControlLabel key="MINIMUM_CASH_COVENANT" value="MINIMUM_CASH_COVENANT" control={<Radio />} label="Minimum Cash Covenant"/>
                        </RadioGroup>

                      </Grid>


                      {assumptionFormatValue === "LEVERAGE_COVENANT" &&
                        <Grid item xs>

                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Include Cash at bank</FormLabel>
                            <FormGroup>


                                  <FormControlLabel key={Math.random() * 10000000}
                                                    control={
                                                      <Checkbox checked={values.includeCashOnBalance} onClick={handleChange}
                                                                name={"includeCashOnBalance"} id={"cashOnBalanceId"} />
                                                    }
                                                    label="Cash On Balance"
                                  />



                            </FormGroup>

                          </FormControl>


                          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Debts To include in leverage
                              calculation</FormLabel>
                            <FormGroup>

                              {debts &&
                                debts.map((debt) => (
                                  <FormControlLabel key={Math.random() * 10000000}
                                    control={
                                      <Checkbox checked={debt.debtDto.checked} onChange={handleChangeDebt}
                                                name={debt.debtDto.name} id={debt.debtDto.id + ""} />
                                    }
                                    label={debt.debtDto.name}
                                  />

                                ))}

                            </FormGroup>

                          </FormControl>
                        </Grid>
                      }

                      {assumptionFormatValue !== "MINIMUM_CASH_COVENANT" &&
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Covenant calculated over</FormLabel>
                            <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="covenantCalculationPeriod"
                            value={covenantPeriod}
                          onChange={handleChangeCovenantPeriod}
                        >
                          <FormControlLabel key="LAST_1_MONTH" value="LAST_1_MONTH" control={<Radio />} label="Last 1 Month"/>
                          <FormControlLabel key="LAST_3_MONTHS" value="LAST_3_MONTHS" control={<Radio />} label="Last 3 Months"/>
                          <FormControlLabel key="LAST_6_MONTHS" value="LAST_6_MONTHS" control={<Radio />} label="Last 6 Months"/>
                          <FormControlLabel key="LAST_12_MONTHS" value="LAST_12_MONTHS" control={<Radio />} label="Last 12 Months (Standard)"/>
                          <FormControlLabel key="NEXT_12_MONTHS" value="NEXT_12_MONTHS" control={<Radio />} label="Next 12 Months"/>
                        </RadioGroup>
                      </FormControl>

                      }

                    </Grid>

                  </DialogContent>
                  )}
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" disabled={submittingForm}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={submittingForm}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
          )}
        </Formik>
      </Grid>
    </React.Fragment>
  );
}


export default AddCovenant;
