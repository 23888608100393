import {Card, Typography} from "@mui/material";
import React from "react";
import {calculateCovenantBreaches} from "../calculations/Calculations";
import {CovenantType} from "../../../SharedComponents/Types";
import {useNavigate} from "react-router-dom";


export default function CovenantCard({covenant}) {
  const navigate = useNavigate();

  const numOfBreaches = calculateCovenantBreaches(covenant);
  return (
    <Card sx={{
      backgroundColor: "#F7F9FC",
      textAlign: "center",
      height: "150px",
      border: "1px solid #ddd",
      borderRadius: "8px",
    }}>

      <Typography sx={{
        marginTop: 2,
        fontWeight: "bold",
        color: "#2f66cb"
      }}>
        {covenant[CovenantType.dtoProperty].name}
      </Typography>

      <Typography sx={{
        color: "#2f66cb"
      }}>
        ({covenant[CovenantType.dtoProperty].assumptionDesc})
      </Typography>

      <Typography sx={{
        marginTop: 5,
        fontWeight: "bolder",
        color: numOfBreaches > 0 ? "red" : "#000",
        fontSize: "20px",
        cursor: "pointer"
      }}
      onClick={() => navigate(CovenantType.redirectPath)}>
        {numOfBreaches} { numOfBreaches === 1 ? "Breach" : "Breaches"}
      </Typography>
    </Card>
  )
}