import React, { useState } from "react";
import {
  Box,
  Card as MuiCard,
  CardContent,
  Grid,
  Paper,
} from "@mui/material";
import YearNav from "../../../SharedComponents/YearNav";
import styled from "@emotion/styled";

import { spacing } from "@mui/system";


import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import IconButton from '@mui/material/IconButton';

import Tooltip from '@mui/material/Tooltip';

import Adjust from '@mui/icons-material/Adjust'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ActualiseDialog from "../../../Actualise/ActualiseDialog";
import CommentModal from "../../../SharedComponents/CommentModal";
import CommentIcon from "@mui/icons-material/Comment";
import {BalanceType} from "../../../SharedComponents/Types";
import KeyIcon from "@mui/icons-material/Key";
import CellKey from "../../../SharedComponents/Cellkey";


const Card = styled(MuiCard)(spacing);

function BurgerMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openActualise, setOpenActualise] = React.useState(false);
  const [openKey, setOpenKey] = React.useState(false);

  const open = Boolean(anchorEl);
  const { refreshDataMethod, projectId, actualiseType, setOpenComment} = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = (event) => {
    setAnchorEl(null);

    if (event.target.outerText === 'Actualise') {

      setOpenActualise(true)
    }

    if (event.target.outerText === 'Comment') {
      setOpenComment(true);
    }

    if(event.target.outerText === 'Key') {
      setOpenKey(true);
    }
  };
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', float: 'right' }}>
        <Tooltip title="More Options">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVertIcon></MoreVertIcon>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem value="actualise">
          <ListItemIcon value="actualise">
            <Adjust fontSize="small" >
              Actualise
            </Adjust>
          </ListItemIcon>
          Actualise
        </MenuItem>
        <MenuItem value="export">
          <ListItemIcon value="export">
            <CommentIcon fontSize="small" >
              Comment
            </CommentIcon>
          </ListItemIcon>
          Comment
        </MenuItem>
        <MenuItem value="key">
          <ListItemIcon value="key">
            <KeyIcon fontSize="small">
              Key
            </KeyIcon>
          </ListItemIcon>
          Key
        </MenuItem>
      </Menu>

      <CellKey
        open={openKey}
        setOpen={setOpenKey} />

      <ActualiseDialog
        open={openActualise}
        setOpen={setOpenActualise}
        loadData={refreshDataMethod}
        actualiseType={actualiseType}
        projectId={projectId}>
      </ActualiseDialog>

    </React.Fragment>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingBottom: 10,
  color: theme.palette.text.secondary,
}));

function BalanceNavBar(props) {
  const { item, refreshDataMethod, projectId, parent, shallowUpdateUrl} = props;

  const [openComment, setOpenComment] = useState(false)

  return (
    <React.Fragment>
      <CommentModal open={openComment} setOpen={setOpenComment} item={item} shallowUpdateUrl={shallowUpdateUrl} type={BalanceType}></CommentModal>

      <Card
        key={item.name.replace(" ", "-") + "PI"}
        sx={{ pb: 0 }}
        style={{ pb: 0 }}
        id={item.name.replace(" ", "-") + "PI"}
      >
        <CardContent sx={{ pb: 0 }}>
          <Grid
            container
            spacing={2}
            style={{ position: "sticky", left: 0, paddingBottom: 0 }}
          >
            {/* NAVIGATION A,1,2,3,4,5 */}
            <Grid item xs={4}>
              <Item
                style={{ position: "sticky", minWidth: 272 }}
                id={"YEAR-NAV"}
                key={"YEAR-NAV"}
              >
                <YearNav productName={item.name.replaceAll(" ", "-")}></YearNav>

                {item.comment !== null &&
                  <CommentIcon visibility={0} onClick={() => setOpenComment(true)}
                               sx={{ marginLeft: 5, cursor: 'pointer' }}></CommentIcon>
                }
              </Item>
            </Grid>
            {/* PERCENTAGE COMPLETE */}
            <Grid item xs={3}>
            </Grid>
            {/* DELETE PRODUCT */}
            <Grid item xs={2} id={"BLANK"} key={"BLANK"}></Grid>
            <Grid item xs={3} >
                <Item id={"DELETE"} key={"DELETE"}>
                  <BurgerMenu item={parent}
                              refreshDataMethod={refreshDataMethod}
                              projectId={projectId}
                              actualiseType={"balance"}
                              setOpenComment={setOpenComment}></BurgerMenu>
                </Item>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default BalanceNavBar;
