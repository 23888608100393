export const ProductsType = {
  dtoProperty: "productDto",
  categoryListProperty: "productCategoryDtoList",
  monthListProperty: "productMonthDtoList",
  type: "PRODUCT",
  shallowUpdateUrl: "revenuecos/productShallow",
  redirectPath: "/revenueandcos",
  baseUrl: "/revenuecos",
  isPositive: true
}

export const OverheadsType = {
  dtoProperty: "overheadDto",
  categoryListProperty: "overheadCategoryDtoList",
  monthListProperty: "overheadMonthDtoList",
  type: "OVERHEAD",
  shallowUpdateUrl: "overheads/overheadShallow",
  redirectPath: "/overheads",
  baseUrl: "/overheads",
  isPositive: false
}

export const HeadcountType = {
  dtoProperty: "headCountDto",
  categoryListProperty: "headCountCategoryDtos",
  monthListProperty: "headCountMonthDtoList",
  type: "HEADCOUNT",
  shallowUpdateUrl: "headcounts/headCountShallow",
  redirectPath: "/headcount",
  baseUrl: "/headcounts",
  isPositive: false
}

export const FixedAssetsType = {
  dtoProperty: "fixedAssetDto",
  categoryListProperty: "fixedAssetCategoryDtos",
  monthListProperty: "fixedAssetMonthDtos",
  type: "FIXEDASSET",
  shallowUpdateUrl: "fixedassets/fixedAssetShallow",
  redirectPath: "/fixedassets",
  baseUrl: "/fixedassets",
  isPositive: true
}

export const CurrentAssetsType = {
  dtoProperty: "currentAssetDto",
  categoryListProperty: "currentAssetCategoryDtos",
  monthListProperty: "currentAssetMonthDtos",
  type: "CURRENTASSET",
  shallowUpdateUrl: "currentassets/currentAssetShallow",
  redirectPath: "/currentassets",
  baseUrl: "/currentassets",
  isPositive: true
}

export const CurrentLiabilitiesType = {
  dtoProperty: "currentLiabilityDto",
  categoryListProperty: "currentLiabilityCategoryDtos",
  monthListProperty: "currentLiabilityMonthDtos",
  type: "CURRENTLIABILITY",
  shallowUpdateUrl: "currentliabilities/currentLiabilityShallow",
  redirectPath: "/currentliabilities",
  baseUrl: "/currentliabilities",
  isPositive: false
}

export const DebtType = {
  dtoProperty: "debtDto",
  categoryListProperty: "debtCategoryDtos",
  monthListProperty: "debtMonthDtos",
  type: "DEBT",
  shallowUpdateUrl: "debt/debtShallow",
  redirectPath: "/debt",
  baseUrl: "/debt",
  isPositive: false
}

export const EquityType = {
  dtoProperty: "equityDto",
  categoryListProperty: "equityCategoryDtos",
  monthListProperty: "equityMonthDtos",
  type: "EQUITY",
  shallowUpdateUrl: "equity/equityShallow",
  redirectPath: "/equity",
  baseUrl: "/equity",
  isPositive: true
}

export const BalanceType = {
  type: "BALANCE",
  shallowUpdateUrl: "balance/shallowUpdate"
}

export const PNLType = {
  type: "PNL",
  shallowUpdateUrl: "pnl/shallowUpdate",
  monthListProperty: "pnLMonthDtoList"
}


export const CovenantType = {
  type: "COVENANT",
  shallowUpdateUrl: "covenant/shallowUpdate",
  dtoProperty: "covenantDto",
  categoryListProperty: "covenantCategoryDtos",
  monthListProperty: "covenantMonthDtos",
  redirectPath: "/covenant",
  baseUrl: "/covenant"
}