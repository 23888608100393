import {Table, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import ReviewRow from "./ReviewRow";
import React from "react";

const tableStyling = {
  fontWeight: "bolder",
  color: "white",
  backgroundColor: "#2f66cb",
};

function ReviewInput({assumptions, type, vatTypes}) {
  return (
    <TableContainer style={{
      overflowY: "auto",
      maxHeight: "40rem"
    }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              variant={"head"}
              align="center"
              sx={{
                ...tableStyling,
                width: "20%",
              }}>Assumption Name</TableCell>

            <TableCell
              variant={"head"}
              align="center"
              sx={{
                ...tableStyling,
                width: "5%",
              }}>Comments Against Assumption</TableCell>

            <TableCell variant={"head"}
                       align="center"
                       sx={{
                         ...tableStyling,
                         width: "40%"
                       }}
            >Assumption Type</TableCell>

            <TableCell variant={"head"}
                       align="center"
                       sx={{
                         ...tableStyling,
                         width: "15%"
                       }}
            >VAT Input Treatment</TableCell>

            <TableCell variant={"head"}
                       align="center"
                       sx={{
                         ...tableStyling,
                         width: "15%"
                       }}
            >VAT Output Treatment</TableCell>

            <TableCell variant={"head"}
                       align="center"
                       sx={{
                         ...tableStyling,
                         width: "10%"
                       }}
            >Number of Overrides</TableCell>

            <TableCell variant={"head"}
                       align="center"
                       sx={{
                         ...tableStyling,
                         width: "10%"
                       }}
            >Potential Errors</TableCell>
          </TableRow>

        </TableHead>
        {assumptions.map((assumption) => {
          return (
            <ReviewRow key={assumption.id} assumption={assumption} type={type} vatTypes={vatTypes}/>)
        })
        }
      </Table>
    </TableContainer>
  )
}

export default ReviewInput;