import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Card as MuiCard,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead
} from "@mui/material";
import { spacing } from "@mui/system";

import useProject from "../../../../../hooks/useProject";
import MonthHeader, { generateXaxisHeaders } from "../../../SharedComponents/MonthHeader";
import { getCurrentProject } from "../../../SharedComponents/ProjectServices";
import PnLNavBar from "./PnLNavBar";
import { useRecoilValue } from "recoil";
import { zoomState } from "../../../SharedState/ZoomState";
import { Skeleton } from "@mui/material";
import Paper from "@mui/material/Paper";
import { TableContainerPowdr } from "../../../SharedComponents/utils/Utils";
import PnlRow from "./PnlRow";
import { overheadsState } from "../../../SharedState/OverheadsState";
import { headcountsState } from "../../../SharedState/HeadcountsState";
import { projectState } from "../../../SharedState/ProjectState";
import {PNLType} from "../../../SharedComponents/Types";
const Card = styled(MuiCard)(spacing);


const tableWidth = {
  width: "100%",
};

function PnLInput(props) {
  const { project, key } = useProject();
  const [XaxisHeaders, setXaxisHeaders] = useState([]);
  const zoomAtom = useRecoilValue(zoomState);
  const [itemsToIgnore, setItemsToIgnore] = useState([]);

  const overheadsAtom = useRecoilValue(overheadsState);
  const headCountAtom = useRecoilValue(headcountsState);
  const projectAtom = useRecoilValue(projectState);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Defer heavy rendering to allow loading indicator to show
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);


  /**
   * Get a list of names of default groups to ignore (as they are empty)
   * @param arr
   * @returns {*}
   */
  const isEmptyDefaultGroup = (arr) => {

    if (getCurrentProject().hideDefaultGroups === true){
      return arr.filter(item => item.overheadDto.name.endsWith("(Default Overhead Group)") && item.overheadDto.overheads.length === 0).map(item => item.overheadDto.name);
    } else {
      return [];
    }

  };

  /**
   * Get a list of names of default groups to ignore (as they are empty)
   * @param arr
   * @returns {*}
   */
  const isEmptyHeadcountsDefaultGroup = (arr) => {

    if (getCurrentProject().hideDefaultGroups === true){
      return arr.filter(item => item.headCountDto.name.endsWith("(Default Headcount Group)") && item.headCountDto.headcounts.length === 0).map(item => item.headCountDto.name);
    } else {
      return [];
    }

  };

  const {
    tableRef,
    pnLItem,
    pnl,
    refreshPnLData,
    setPnl,
    loadPnLData,
    moveOverhead,
    setEditData,
    editData,
    deletePnlCategory
  } = props;

  function updateXAxis() {
    let overheadsToIgnore = isEmptyDefaultGroup(overheadsAtom);
    let headcountsToIgnore = isEmptyHeadcountsDefaultGroup(headCountAtom);
    let itemsToIgnore = overheadsToIgnore.concat(headcountsToIgnore);
    setItemsToIgnore(itemsToIgnore);

    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstForecast));
  }

  useEffect(() => {

    updateXAxis()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateXAxis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectAtom]);

  function loadDataAndRefreshHeaders() {
    setXaxisHeaders(generateXaxisHeaders(getCurrentProject().startDate, getCurrentProject().firstForecast));
    loadPnLData();
  }

  function renderHeavyContent() {
    return (
      <div>
        <PnLNavBar
          item={pnLItem.pnLDto}
          parent={pnLItem}
          refreshDataMethod={loadDataAndRefreshHeaders}
          projectId={project.id}
          shallowUpdateUrl={PNLType.shallowUpdateUrl}
        ></PnLNavBar>

        <Card
          mb={6}
          sx={{
            ...tableWidth,
            overflowX: "auto",
            overflow: "scroll",
            paddingTop: 0,
          }}
          id={pnLItem.pnLDto.name.replaceAll(" ", "-") + "-table"}
        >
          <CardContent  sx={{ paddingTop: 0, zoom: zoomAtom }}>

            <TableContainerPowdr component={Paper} sx={{ overflow: 'auto' }}>

              <Table ref={tableRef} >
                {/* X AXIS - PRODUCT NAME, JAN, FEB, MAR, ETC... */}
                <TableHead>
                  <TableRow
                    key={pnLItem.pnLDto.name + "-ROW"}
                    id={pnLItem.pnLDto.name + "-ROW"}
                  >
                    <TableCell
                      key={pnLItem.pnLDto.name + "-BASE"}
                      id={pnLItem.pnLDto.name + "-BASE"}
                      style={{ position: "sticky", left: 0 }}
                      sx={{
                        minWidth: 200,
                        width: 200,
                        backgroundColor: "#F7F9FC",
                        zIndex: 10, // Ensure the header is above other content
                      }}
                    >

                    </TableCell>
                    {XaxisHeaders.map((yHeader, index) => (
                      <MonthHeader key={Math.random() * 10000000} yHeader={yHeader} name={pnLItem.pnLDto.name} index={index}></MonthHeader>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {pnLItem.pnLCategoryDtoList.filter(category => !itemsToIgnore.includes(category.overrideName)).map((row) => (

                    <PnlRow key={row.id}
                            row={row}
                            setEditData={setEditData}
                            moveOverhead={moveOverhead}
                            loadPnLData={loadPnLData}
                            deletePnlCategory={deletePnlCategory}
                            editData={editData}
                            refreshPnLData={refreshPnLData}
                            setPnl={setPnl}
                            pnl={pnl}
                            pnLItem={pnLItem}
                    ></PnlRow>

                  ))}
                </TableBody>
              </Table>

            </TableContainerPowdr>

          </CardContent>
        </Card>
      </div>
    )
  }
  
  return (
    <React.Fragment key={key}>
      {isLoading ? (
        <Skeleton /> // Loading widget or spinner
      ) : (
        renderHeavyContent() // Rendered heavy content
      )}
    </React.Fragment>
  );
}

export default PnLInput;
