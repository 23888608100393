import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Button, TextField, Typography} from "@mui/material";
import axios from "axios";

export default function ErrorNoteModal(props) {
  const { open, setOpen, item, type, projectId, setItems, items } = props;

  const [noteText, setNoteText] = useState(item.errorNote || ""); // Initialize with the note's content

  useEffect(() => {
    setNoteText(item.errorNote);
    item.previousErrorNote = item.errorNote;
  }, [item]);

  function handleClose() {
    setOpen(false);
  }

  function handleCommentChange(event) {
    setNoteText(event.target.value); // Update state with the new note text
  }

  function saveComment() {
    const url = `${type.baseUrl}/${projectId}/errorNote`;
    item.errorNote = noteText;

    axios
      .put(url, item)
      .then(() => {
        setItems(items);
        setOpen(false);
      })
      .catch(function(error) {
        // Handle error here
        console.error("Error saving note: ", error);
        setOpen(false);
      });
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">Error Note</DialogTitle>
        <DialogContent>
          {/* Directly use Typography without DialogContentText for complex components */}
          <Typography gutterBottom>Note for this error:</Typography>
          <TextField
            name="commentField"
            autoFocus
            multiline
            rows={6}
            id="commentField"
            fullWidth
            value={noteText} // Use state for controlled input
            onChange={handleCommentChange} // Handle changes
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={saveComment} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
