import _ from "lodash";
import {ifNanThenZero} from "./CalculateVat";
import {calculateCorkscrew} from "../../../SharedComponents/calculations/SimpleCorkscrew";
import {getCurrentProject} from "../../../SharedComponents/ProjectServices";
import {percentage} from "../../../SharedComponents/utils/PercentageUtils";
import {getNumberOfDaysInMonth, getValueOrOverriddenValue} from "../../../SharedComponents/utils/Utils";
import {CurrentLiabilitiesType} from "../../../SharedComponents/Types";


function populateProductReleaseProfilesMatrix(accrual, productsAtom, releaseProfilesAtom, releaseMatrix) {

  if (releaseProfilesAtom === undefined || releaseProfilesAtom.length === 0) {
    return;
  }

  _.forEach(accrual.currentLiabilityDto.productsReleaseProfile, function(accrualProduct) {
    //for each accrual item, find its matching release profile

    //find the product by the item
    let product = productsAtom.find(product => product.productDto.id === accrualProduct.item);

    //find the releaseProfile
    let releaseProfile = releaseProfilesAtom[0].releaseProfileCategoryDtos.find(releaseProfile => releaseProfile.id === accrualProduct.releaseProfile);

    if (product !== undefined && releaseProfile !== undefined) {

      //for each month of the model (72) calculate the release
      //find the closing category (or equivelant) for the calcualation (for overheads and salaries we just pull them from the P&L)
      let revenueCategory = product.productCategoryDtoList.find(category => category.name === "Direct product costs");

      //add these values to an array { accrualItem : {item attributes..., itemReleaseMonths: []}}
      let matrix = {
        name: product.productDto.name,
        itemId: accrualProduct.item,
        rows: []
      };

      _.forEach(revenueCategory.productMonthDtoList, function(month, index) {

        //so for each months value, we calculate the entire release profile, effectively you end up with 72x72 grid matrix, the release profile is applied to all of them
        let matrixRow = buildRowForMatrix(releaseProfile, index, month);
        matrix.rows.push(matrixRow);
      });

      releaseMatrix.indexes.push(matrix);
    }

  });
}

function buildRowForMatrix(releaseProfile, index, month) {

  let matrixRow = [];

  // Fill with zeros until we reach this month's index
  for (let i = 0; i < index; i++) {
    matrixRow.push({ index: month.monthIndex, value: 0 });
  }

  _.forEach(releaseProfile.releaseProfileMonthDtos, function(releaseMonth, n) {
    let newAccrualMonthValue = (getValueOrOverriddenValue(month) / 100) * releaseMonth.value; // Work out the percentage value

    // Make the newAccrualMonthValue a negative number
    newAccrualMonthValue *= -1;

    // Push the result into the matrix row
    matrixRow.push({ index: month.monthIndex, value: newAccrualMonthValue });
  });

  return matrixRow;
}

function populateOverheadAndSalaryReleaseProfilesMatrix(pnlAtom, releaseProfilesAtom, releaseMatrix, releaseProfileAccrualItems, pnlAttribute) {

  if (releaseProfilesAtom === undefined || releaseProfilesAtom.length === 0) {
    return;
  }

  _.forEach(releaseProfileAccrualItems, function(accrualOverhead) {
    //for each accrual item, find its matching release profile

    //find the product by the item
    let overheadPnlCategory = pnlAtom.find(category => category[pnlAttribute] === accrualOverhead.item);
    //find the releaseProfile
    let releaseProfile = releaseProfilesAtom[0].releaseProfileCategoryDtos.find(releaseProfile => releaseProfile.id === accrualOverhead.releaseProfile);

    if (overheadPnlCategory !== undefined && releaseProfile !== undefined) {

      //for each month of the model (72) calculate the release
      //find the closing category (or equivelant) for the calcualation (for overheads and salaries we just pull them from the P&L)

      //add these values to an array { accrualItem : {item attributes..., itemReleaseMonths: []}}
      let matrix = {
        name: overheadPnlCategory.overrideName,
        itemId: accrualOverhead.item,
        rows: []
      };

      _.forEach(overheadPnlCategory.pnLMonthDtoList, function(month, index) {

        //so for each months value, we calculate the entire release profile, effectively you end up with 72x72 grid matrix, the release profile is applied to all of them
        let matrixRow = buildRowForMatrix(releaseProfile, index, month);
        matrix.rows.push(matrixRow);
      });

      releaseMatrix.indexes.push(matrix);
    }

  });
}

function calculateOverheadAndSalaryNewAccrualValues(releaseProfileAccrualItems, newAccrualsArray, pnlAtom, pnlAttribute) {

  _.forEach(releaseProfileAccrualItems, function(accrualOverhead) {
    //for each accrual item, find its matching release profile

    //find the product by the item
    let overheadPnlCategory = pnlAtom.find(category => category[pnlAttribute] === accrualOverhead.item);

    if (overheadPnlCategory !== undefined) {
      _.forEach(overheadPnlCategory.pnLMonthDtoList, function(month, index) {

        newAccrualsArray[index] += month.value;
      });
    }

  });

}

function calculateNewAccrualValues(accrual, productsAtom, pnlAtom) {

  let newAccrualsArray = new Array(72).fill(0);

  //find all the products and their categories and then create a entry in the newAccrualsArray
  _.forEach(accrual.currentLiabilityDto.productsReleaseProfile, function(accrualProduct) {

    //find the product by the item
    let product = productsAtom.find(product => product.productDto.id === accrualProduct.item);

    if (product !== undefined) {

      let productCategoryObject = product.productCategoryDtoList.find(category => category.name === 'Direct product costs');

      _.forEach(productCategoryObject.productMonthDtoList, function(month, index) {

        newAccrualsArray[index] += getValueOrOverriddenValue(month);
      });
    }

  });

  //do the same for overheads and salaries
  calculateOverheadAndSalaryNewAccrualValues(accrual.currentLiabilityDto.overheadsReleaseProfile, newAccrualsArray, pnlAtom, "overheadId");

  calculateOverheadAndSalaryNewAccrualValues(accrual.currentLiabilityDto.salariesReleaseProfile, newAccrualsArray, pnlAtom, "headcountId");

  return newAccrualsArray;
}

function createAccrualReleaseTotals(releaseMatrix) {
  let accrualReleaseArray = new Array(72).fill(0);

  //lets' build up our totals
  accrualReleaseArray.forEach((item, rowIndex) => {

    releaseMatrix.indexes.forEach(product => {

      product.rows.forEach((row) => {
        accrualReleaseArray[rowIndex] += row[rowIndex].value;
      });

    });

  });

  return accrualReleaseArray;
}

function calculateUnevenPayments(currentLiabilities, releaseProfilesAtom, productsAtom, pnlAtom) {

  let accruals = currentLiabilities.filter(currentliability => currentliability.currentLiabilityDto.assumption === "ACCRUAL");

  _.forEach(accruals, function(accrual, i) {

    let newAccrualArray = calculateNewAccrualValues(accrual, productsAtom, pnlAtom);

    //calculate the release profiles for each of the accrual items
    let releaseMatrix = {indexes: []};

    populateProductReleaseProfilesMatrix(accrual, productsAtom, releaseProfilesAtom, releaseMatrix);

    populateOverheadAndSalaryReleaseProfilesMatrix(pnlAtom, releaseProfilesAtom, releaseMatrix, accrual.currentLiabilityDto.overheadsReleaseProfile, "overheadId");

    populateOverheadAndSalaryReleaseProfilesMatrix(pnlAtom, releaseProfilesAtom, releaseMatrix, accrual.currentLiabilityDto.salariesReleaseProfile, "headcountId");

    let accrualReleaseTotals = createAccrualReleaseTotals(releaseMatrix);

    //it's now basically like a regular corkscrew
    let openingCategory = accrual.currentLiabilityCategoryDtos.find(category => category.name === "Opening"); //opening
    let newAccrualCategory = accrual.currentLiabilityCategoryDtos.find(category => category.name === "New Accrual"); //New Accrual
    let accrualReleaseCategory = accrual.currentLiabilityCategoryDtos.find(category => category.name === "Accrual Release"); //Accrual Release
    let adjustmentCategory = accrual.currentLiabilityCategoryDtos.find(category => category.name === "Adjustment"); //Adjustment
    let closingCategory = accrual.currentLiabilityCategoryDtos.find(category => category.name === "Closing"); //closing

    _.forEach(openingCategory.currentLiabilityMonthDtos, function(openingMonth, i) {

      if (i > getCurrentProject().firstBalanceForecast - 1) {

        openingCategory.currentLiabilityMonthDtos[i].value = closingCategory.currentLiabilityMonthDtos[i - 1].value;
        newAccrualCategory.currentLiabilityMonthDtos[i].value = newAccrualArray[i];
        accrualReleaseCategory.currentLiabilityMonthDtos[i].value = accrualReleaseTotals[i];

        closingCategory.currentLiabilityMonthDtos[i].value =
          openingCategory.currentLiabilityMonthDtos[i].value +
          newAccrualCategory.currentLiabilityMonthDtos[i].value +
          accrualReleaseCategory.currentLiabilityMonthDtos[i].value +
          adjustmentCategory.currentLiabilityMonthDtos[i].value;
      }

    });

  });

}


export function calculateCurrentLiabilities(corporationTax,
                                            currentLiabilities,
                                            vatPackageAtom,
                                            cashflow,
                                            pnl,
                                            releaseProfileAtom,
                                            productsAtom) {

  const currentLiabilityTradeCreditor = currentLiabilities
    .find(currentLiability => currentLiability.currentLiabilityDto.assumption === "TRADE_CREDITORS");

  let vatPackage = vatPackageAtom;

  if (pnl !== undefined
      && cashflow !== undefined
      && productsAtom !== undefined
      && releaseProfileAtom !== undefined
      && productsAtom !== undefined) {

    calculateCorkscrew(currentLiabilities, CurrentLiabilitiesType);
    calculateNICandPAYE(currentLiabilities, pnl);
    calculateVatCorkscrew(currentLiabilities, vatPackage);
    calculateTradeCreditors(vatPackage, currentLiabilityTradeCreditor, cashflow);
    calculatePercentageOfCreditors(currentLiabilities)
    calculatePercentageOfRevenue(vatPackage, currentLiabilities);
    calculatePercentageOfOverheads(vatPackage, currentLiabilities, pnl);
    calculateCorporationTax(pnl, cashflow,  currentLiabilities, corporationTax[0].vatCategoryDtos);
    calculateUnevenPayments(currentLiabilities, releaseProfileAtom, productsAtom, pnl);
    calculateAccruals(currentLiabilities, pnl,productsAtom, "ACCRUALS");
    calculateDeferredRevenue(currentLiabilities, productsAtom);
  }

}

// noinspection JSUnusedLocalSymbols
/**
 * DATA FROM MODEL:
 *
 * Take EBITDA from P&L
 * Interest from the P&L
 * Capex from the cash flow
 *
 * ASSUMPTIONS:
 * Allow user to select a percentage of their interest that is tax deductible.
 * Allow user to select a percentage of their CAPEX that is tax deductible.
 * A manual input of “other adjustments”
 *
 * CALCULATIONS:
 *
 * Multiply (2) by a
 * Multiply (3) by b
 * Add the above to EBITDA and the manual input in (c). This gives you your taxable profit
 *
 * NEXT ASSUMPTION:
 *
 * Allow user to enter corporation tax rate on monthly basis. - done in the config
 *
 * Create a corkscrew:
 *
 * Opening corporation tax payable
 * New tax incurred = Tax rate x profit
 * Tax Paid: Allow user to input tax payment manually
 * Closing corporation tax payable
 *
 * @param pnl
 * @param cashflow
 * @param pnl2
 * @param currentLiabilities
 */
// eslint-disable-next-line
function calculateCorporationTax(pnl, cashflow,  currentLiabilities, corporationTaxRates) {

  const standardCorporationTaxRate = corporationTaxRates.find(corporationTaxRate => corporationTaxRate.name === "Standard");
  const ebitdaCategory = pnl.find(category => category.name === 'EBITDA');
  const interestCategory = pnl.find(category => category.name === 'Net Interest Expense');
  const cashflowCapex = cashflow.cashflowCategoryDtos.find(category => category.name === "Capital Expenditure");


  const corporationTaxLiability = currentLiabilities.find(currentLiability => currentLiability.currentLiabilityDto.assumption === "CORPORATION_TAX");
  const taxableProfit = corporationTaxLiability.currentLiabilityCategoryDtos.find(category => category.name === 'Taxable Profit');

  // CALCULATIONS:
  //
  // Multiply (2) by a
  // Multiply (3) by b
  // Add the above to EBITDA and the manual input in (c). This gives you your taxable profit

  // interestCategory * Allow user to select a percentage of their interest that is tax deductible.
  _.forEach(corporationTaxLiability.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos, function(currentLiabilitiesMonthDto, i) {

    // THIS SECTION COPIES THE EBITDA,INTEREST & CAPEX into the TOP 3 ROWS of the Corporation Tax assumption
    corporationTaxLiability.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos[i].value
      = ebitdaCategory.pnLMonthDtoList[i].value;

    corporationTaxLiability.currentLiabilityCategoryDtos[1].currentLiabilityMonthDtos[i].value
      = interestCategory.pnLMonthDtoList[i].value;

    corporationTaxLiability.currentLiabilityCategoryDtos[2].currentLiabilityMonthDtos[i].value
      = cashflowCapex.cashflowMonthDtos[i].value;
    //END OF COPYING


    //Taxable profit line = total of all lines above it (EBITDA, Interest, Capital Expenditure...,etc...Other Adjustments)

    taxableProfit.currentLiabilityMonthDtos[i].value =
      corporationTaxLiability.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos[i].value    //EBITDA
      + corporationTaxLiability.currentLiabilityCategoryDtos[1].currentLiabilityMonthDtos[i].value  //Interest
      + corporationTaxLiability.currentLiabilityCategoryDtos[2].currentLiabilityMonthDtos[i].value  //Capital Expenditure
      + corporationTaxLiability.currentLiabilityCategoryDtos[3].currentLiabilityMonthDtos[i].value  //Add back non-deductable interest
      + corporationTaxLiability.currentLiabilityCategoryDtos[4].currentLiabilityMonthDtos[i].value  //Add back non-deductable capex
      + corporationTaxLiability.currentLiabilityCategoryDtos[5].currentLiabilityMonthDtos[i].value; //Other Adjustment




    if (i >= getCurrentProject().firstBalanceForecast) {

      // interestCategory calculate the value from the interest
      // let interestVal = calculatePercentValue(corporationTaxLiability.currentLiabilityCategoryDtos[1].currentLiabilityMonthDtos[i].value,
      //   interestCategory.pnLMonthDtoList[i].value);
      //
      // // Allow user to select a percentage of their CAPEX that is tax deductible.
      // let capexVal = calculatePercentValue(corporationTaxLiability.currentLiabilityCategoryDtos[2].currentLiabilityMonthDtos[i].value,
      //   cashflow.cashflowMonthDtos[i].value);
      //
      // //Multiply "Interest from the P&L" by "Allow user to select a percentage of their interest that is tax deductible."
      //
      // let taxibleProfit = ebitdaCategory.pnLMonthDtoList[i].value                                     //EBITDA
      //   + corporationTaxLiability.currentLiabilityCategoryDtos[6].currentLiabilityMonthDtos[i].value  //Taxable Profit
      //   + capexVal
      //   + interestVal;

      let taxibleProfit = taxableProfit.currentLiabilityMonthDtos[i].value;

      // Additions = Tax rate x profit
      // We're just using the standard rate of tax - speak to joe to see if this is correct or if we have to select it
      corporationTaxLiability.currentLiabilityCategoryDtos[9].currentLiabilityMonthDtos[i].value =
        addPercentageAndValue(taxibleProfit, standardCorporationTaxRate.vatMonthDtos[i].value);

      // set the opening value from the previous month closing (CLOSING IS CALLED - "Opening Tax Liability")
      corporationTaxLiability.currentLiabilityCategoryDtos[8].currentLiabilityMonthDtos[i].value
          = corporationTaxLiability.currentLiabilityCategoryDtos[11].currentLiabilityMonthDtos[i - 1].value;

      //get the value of the closing, from the previous month
      corporationTaxLiability.currentLiabilityCategoryDtos[11].currentLiabilityMonthDtos[i].value =  //Closing Tax Liability
        corporationTaxLiability.currentLiabilityCategoryDtos[8].currentLiabilityMonthDtos[i].value +
        corporationTaxLiability.currentLiabilityCategoryDtos[9].currentLiabilityMonthDtos[i].value +
        corporationTaxLiability.currentLiabilityCategoryDtos[10].currentLiabilityMonthDtos[i].value;

    }

  });


}

function calculatePercentageOfOverheads(vatPackage, currentLiabilities, pnl) {
  if (Array.isArray(vatPackage) && vatPackage.length === 0) {
    console.log('vat rate is empty')
    return;
  }

  const directProductCosts = pnl
    .find(category => category.overrideName === "Direct product costs");

  const currentLiabilityPercentageOfOverheads = currentLiabilities
    .filter(currentLiability => currentLiability.currentLiabilityDto.assumption === "PERCENTAGE_OF_CERTAIN_OVERHEADS");

  // FOR each instance, we need to sum up all of the selected products (including VAT)
  // Then we simply times this by the percentage, like we do with PAYE or Percentage of creditors/debtors
  _.forEach(currentLiabilityPercentageOfOverheads, function(currentLiabilityPercentageOfOverhead) {

    let overheadsToSumUp = vatPackage.overheads
      .filter(product => currentLiabilityPercentageOfOverhead.currentLiabilityDto.overheads.includes(product.overheadDto.id));

    let salariesToSumUp = pnl
      .filter(category => category.headcountId !== null && currentLiabilityPercentageOfOverhead.currentLiabilityDto.salaries.includes(category.headcountId));

    _.forEach(currentLiabilityPercentageOfOverhead.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos, function(currentLiabilityMonthDto, i) {

      let sum = 0;

      //Sum up all overheads
      _.forEach(overheadsToSumUp, function(overhead, c) {

        let valueInPnlCategory = overhead.overheadCategoryDtoList.find(category => category.name === "Value In P&L");
        if (!_.isNil(valueInPnlCategory)) {
          sum += isNanReturn0(valueInPnlCategory.overheadMonthDtoList[i].expenseIncludingVat);
        }
      });

      //Sum up Salaries and add to overheads sum
      _.forEach(salariesToSumUp, function(salary) {

        sum += isNanReturn0(salary.pnLMonthDtoList[i].value);

      });

      if (currentLiabilityPercentageOfOverhead.currentLiabilityDto.directProductCosts) {
        sum += directProductCosts.pnLMonthDtoList[i].value;
      }

      currentLiabilityMonthDto.value = sum;

      if (i < getCurrentProject().firstBalanceForecast) {

        currentLiabilityPercentageOfOverhead.currentLiabilityCategoryDtos[1].currentLiabilityMonthDtos[i].value =
          percentage(currentLiabilityPercentageOfOverhead.currentLiabilityCategoryDtos[2].currentLiabilityMonthDtos[i].value,
            currentLiabilityPercentageOfOverhead.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos[i].value);

      }

      if (i > getCurrentProject().firstBalanceForecast - 1) {

        currentLiabilityPercentageOfOverhead.currentLiabilityCategoryDtos[2].currentLiabilityMonthDtos[i].value =
          isNanReturn0(((currentLiabilityPercentageOfOverhead.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos[i].value / 100 ) *
            currentLiabilityPercentageOfOverhead.currentLiabilityCategoryDtos[1].currentLiabilityMonthDtos[i].value ));
      }

    })


  })

}

function calculatePercentageOfRevenue(vatPackage, currentLiabilities) {
  if (Array.isArray(vatPackage) && vatPackage.length === 0) {
    console.log('vat rate is empty')
    return;
  }

  const currentLiabilityPercentageOfRevenues = currentLiabilities
    .filter(currentLiability => currentLiability.currentLiabilityDto.assumption === "PERCENTAGE_OF_CERTAIN_REVENUE");

  // FOR each instance, we need to sum up all of the selected products (including VAT)
  // Then we simply times this by the percentage, like we do with PAYE or Percentage of creditors/debtors
  _.forEach(currentLiabilityPercentageOfRevenues, function(currentLiabilityPercentageOfRevenue) {

    let productsToSumUp = vatPackage.products
      .filter(product => currentLiabilityPercentageOfRevenue.currentLiabilityDto.products.includes(product.productDto.id));

    _.forEach(currentLiabilityPercentageOfRevenue.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos, function(currentLiabilityMonthDto, i) {

      let sum = 0;
      _.forEach(productsToSumUp, function(product, c) {
        let revenueCategory = product.productCategoryDtoList.find(category => category.name === "Revenue" || category.name === "Closing recurring revenue");
        if (!_.isNil(revenueCategory)) {
          sum += isNanReturn0(revenueCategory.productMonthDtoList[i].valueIncludingVat);
        }
      });

      currentLiabilityMonthDto.value = sum;

      if (i < getCurrentProject().firstBalanceForecast) {

        currentLiabilityPercentageOfRevenue.currentLiabilityCategoryDtos[1].currentLiabilityMonthDtos[i].value =
          percentage(currentLiabilityPercentageOfRevenue.currentLiabilityCategoryDtos[2].currentLiabilityMonthDtos[i].value,
            currentLiabilityPercentageOfRevenue.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos[i].value);

      }

      if (i > getCurrentProject().firstBalanceForecast - 1) {

        currentLiabilityPercentageOfRevenue.currentLiabilityCategoryDtos[2].currentLiabilityMonthDtos[i].value =
          isNanReturn0(((currentLiabilityPercentageOfRevenue.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos[i].value / 100 ) *
            currentLiabilityPercentageOfRevenue.currentLiabilityCategoryDtos[1].currentLiabilityMonthDtos[i].value ));
      }

    })



  })

}

function calculatePercentageOfCreditors(currentLiabilities) {

  const currentLiabilitiesPercentageOfDebtors = currentLiabilities
    .filter(currentLiabilities => currentLiabilities.currentLiabilityDto.assumption === "PERCENTAGE_OF_CREDITORS");

  const currentLiabilityTradeCreditor = currentLiabilities
    .filter(currentLiability => currentLiability.currentLiabilityDto.assumption === "TRADE_CREDITORS")[0];


  // calculate closing value
  // Opening + additions - deductions = closing. This is called a CORKSCREW
  _.forEach(currentLiabilitiesPercentageOfDebtors, function(currentLiabilities) {
    _.forEach(currentLiabilities.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos, function(currentLiabilitiesMonthDto, i) {


      let closingCategory = currentLiabilityTradeCreditor.currentLiabilityCategoryDtos.find(category => category.name === 'Creditors in the balance sheet')

      // set the trade credit row values
      currentLiabilities.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos[i].value =
        closingCategory.currentLiabilityMonthDtos[i].value;

      if (i < getCurrentProject().firstBalanceForecast) {



        //set the value of the PAYE value
        currentLiabilities.currentLiabilityCategoryDtos[1].currentLiabilityMonthDtos[i].value =
          percentage(currentLiabilities.currentLiabilityCategoryDtos[2].currentLiabilityMonthDtos[i].value,
            currentLiabilities.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos[i].value);

      }

      if (i > getCurrentProject().firstBalanceForecast - 1) {

        //set the value of the PAYE value
        currentLiabilities.currentLiabilityCategoryDtos[2].currentLiabilityMonthDtos[i].value =
          ((currentLiabilities.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos[i].value / 100 ) *
            currentLiabilities.currentLiabilityCategoryDtos[1].currentLiabilityMonthDtos[i].value );
      }
    });
  });


}

function calculateDays(overheadsAndTheirDaysReceivable, project, calculatedDebtArr) {


  _.forEach(overheadsAndTheirDaysReceivable, function(overheadAndTheirDaysReceivable, y) {

    if (overheadAndTheirDaysReceivable.name !== "Days Receivable" && overheadAndTheirDaysReceivable.name !== "Days Payable Capex" ) {

      _.forEach(overheadAndTheirDaysReceivable.currentLiabilityMonthDtos, function(currentLiabilityMonthDto, x) {

        // if the days is less than the number of days in this month, then its x 50%
        // if its the same number of days in the month, then its 100%
        // if it's great then the same number of days then, its relative percentage of the no. days in the month
        // ie 30 days in june, 45 in the days received, percentage is 150%

        // to get this month, we need the starting month of the project + monthIndex
        // then we can get the number of days in the month
        //project.startDate
        let projectStartDate = new Date(getCurrentProject().startDate);
        projectStartDate.setDate(1);

        projectStartDate.setMonth(projectStartDate.getMonth() + currentLiabilityMonthDto.monthIndex);

        const daysInMonth = getNumberOfDaysInMonth(projectStartDate.getMonth(), projectStartDate.getFullYear()); // Returns number of days for the month

        //console.log(daysInMonth, projectStartDate)

          let daysReceivable = overheadsAndTheirDaysReceivable.find(category => category.overheadId === overheadsAndTheirDaysReceivable[y].overheadId && ['Days Receivable', 'Days Payable Capex'].includes(category.name)).currentLiabilityMonthDtos[x].value;

          //let daysReceivable = overheadsAndTheirDaysReceivable[y + 1].currentLiabilityMonthDtos[x].value;

          let percentage = isWhatPercentOf(daysReceivable, daysInMonth);

          let addPercentageVal = addPercentage(currentLiabilityMonthDto.value, percentage);

          //console.log("Calculated Debtors:", calculatedDebtors.currentLiabilityMonthDtos[x].value, "days in month:", daysInMonth, "days receivable:", daysReceivable, "percentage", percentage, "addPercentage:", addPercentage(currentLiabilityMonthDto.value, percentage), "calculatedDebtors.currentLiabilityMonthDtos[i].value", calculatedDebtors.currentLiabilityMonthDtos[i].value, "x is: ", x)

          if (isNaN(calculatedDebtArr[x])) {
            calculatedDebtArr[x] = 0;
          }
          calculatedDebtArr[x] += addPercentageVal;


      });

    }

  });
}

function populateDirectProductCosts(currentLiabilityTradeCreditor, vatPackage) {
  if (Array.isArray(vatPackage) && vatPackage.length === 0) {
    console.log('vat rate is empty')
    return;
  }
  //Set the Direct Product Costs from the VatRates directProductCostsIncVat
  let currentLiabilityTradeCreditorDirectProductCosts = currentLiabilityTradeCreditor.currentLiabilityCategoryDtos
    .find(currentLiabilityCategoryDto => currentLiabilityCategoryDto.overrideName === "Direct Product Costs" && currentLiabilityCategoryDto.name === "Expense Name");

  if (currentLiabilityTradeCreditorDirectProductCosts !== undefined) { //some older projects don't have a direct product costs on the Trade creditors
    _.forEach(currentLiabilityTradeCreditorDirectProductCosts.currentLiabilityMonthDtos, function(currentLiabilityMonth, i) {
      currentLiabilityMonth.value = vatPackage.directProductCostsIncVatAndOffset[i];
    });
  }

}

/**
 * For trade creditors:
 *
 *
 *
 * DATA FROM MODEL/ CALCULATION: Take the revenue of every product individually (from the revenue tab) and layer them in a grid (row after row). Add VAT to each revenue line.
 *
 * 1ST ASSUMPTION: In the next section, allow the user to add an assumption for each line for the number of days the invoice is paid after it is recorded as revenue. This is called “days receivable”.
 *
 * CALCULATION: Next you have to calculate the proportion of the days in the month that using the assumption in (2). For example, If the assumption is 30 and the month is June, the calculation is 100% of revenue.
 *
 * If the number is less than the days of the month it is a proportion of that month’s revenue (e.g 15 = 50% of June’s revenue).
 *
 * FOR MVP: if the calculation is >100%, still use the proportion of that month’s revenue e.g 45 = 150% of June’s revenue. Note – we will improve this in later versions of the model (v.2.0) but this is actually how most modelling is done. I have a proprietary calculation method that is more accurate.
 *
 * Once you know the output, this is called “calculated debtors”
 *
 * 2ND ASSUMPTION: There is often a difference between “calculated debtors” and closing debtors on balance sheet. This is known as “aged debtors”. This should be a simple corkscrew. The data for this doesn’t come from the balance sheet – the user has to input it directly. The middle part of the corkscrew should read “new aged debt” and “aged debt released”
 *
 * Closing debtors that feeds into the balance sheet is “calculated debtors + aged debtors”
 *
 * Trade creditors works in exactly the same way but for every cost line – except headcount costs - and for capex (both in place of revenue in the steps above). Also replace the word debtor with creditor.
 *
 * @param vatPackage
 * @param currentliabilitys
 */
function calculateTradeCreditors(vatPackage, currentLiabilityTradeCreditor, cashflow) {

  const storedProject = sessionStorage.getItem("project");

  const cashflowCapex = cashflow.cashflowCategoryDtos.find(category => category.name === "Capital Expenditure");

  let project = JSON.parse(storedProject);

  // for each overhead in the vatPackage
  _.forEach(vatPackage.overheads, function(overhead) {


    // find the companion row in the trade creditor current liabilities
    let currentLiabilityTradeCreditorExpenseCategory = currentLiabilityTradeCreditor.currentLiabilityCategoryDtos
      .filter(currentLiabilityCategoryDto => currentLiabilityCategoryDto.overheadId === overhead.overheadDto.id && currentLiabilityCategoryDto.name === "Expense Name")[0];

    if (overhead.overheadDto.name === 'Direct product costs') {

      currentLiabilityTradeCreditorExpenseCategory = currentLiabilityTradeCreditor.currentLiabilityCategoryDtos
        .filter(currentLiabilityCategoryDto => currentLiabilityCategoryDto.overrideName === 'Direct Product Costs' && currentLiabilityCategoryDto.name === "Expense Name")[0];
    }


    let overheadValueInPL = overhead.overheadCategoryDtoList
      .filter(overheadCategoryDto => overheadCategoryDto.name === "Value In P&L")[0];

    _.forEach(overheadValueInPL.overheadMonthDtoList, function(overheadRevenueMonth, i) {

      if (overhead.overheadDto.name === 'Direct product costs') {
        if (!_.isNil(currentLiabilityTradeCreditorExpenseCategory)) { //if there is a direct product costs category
          currentLiabilityTradeCreditorExpenseCategory.currentLiabilityMonthDtos[i].value = getValueOrOverriddenValue(overheadRevenueMonth);
        }
      } else {
        if (!_.isNil(currentLiabilityTradeCreditorExpenseCategory)) {
          // set the value of the overhead in the current asset (including the vat)
          currentLiabilityTradeCreditorExpenseCategory.currentLiabilityMonthDtos[i].value = overheadRevenueMonth.expenseIncludingVat;
        }
      }
    });

  });

  //Set the Direct Product Costs from the VatRates directProductCostsIncVat
  populateDirectProductCosts(currentLiabilityTradeCreditor, vatPackage);


  const tradeCreditorCapex = currentLiabilityTradeCreditor.currentLiabilityCategoryDtos
    .filter(currentLiabilityCategoryDto => currentLiabilityCategoryDto.name === "Capital Expenditure")[0];

  _.forEach(cashflowCapex.cashflowMonthDtos, function(capexMonth, i) {

    tradeCreditorCapex.currentLiabilityMonthDtos[i].value = capexMonth.value;
  });


  // ok, now we have the fixed rows, the formula is listed in the description

  const calculatedCreditors = currentLiabilityTradeCreditor.currentLiabilityCategoryDtos
    .filter(currentLiabilityCategoryDto => currentLiabilityCategoryDto.name === "Calculated Creditors")[0];

  let calculatedDebtArr = [];

  // for each overhead work out the value based on the days receivable
  const overheadsAndTheirDaysReceivable = currentLiabilityTradeCreditor.currentLiabilityCategoryDtos
    .filter(currentLiabilityCategoryDto => currentLiabilityCategoryDto.overheadId !== null || currentLiabilityCategoryDto.overrideName === "Direct Product Costs"); //we need to include the Direct Product Costs row here

  const capex = currentLiabilityTradeCreditor.currentLiabilityCategoryDtos
    .find(currentLiabilityCategoryDto => currentLiabilityCategoryDto.name === "Capital Expenditure");

  const capexDailys = currentLiabilityTradeCreditor.currentLiabilityCategoryDtos
    .find(currentLiabilityCategoryDto => currentLiabilityCategoryDto.overheadId === null
      &&  currentLiabilityCategoryDto.name === "Days Payable Capex");

  let capexArr = [capex, capexDailys]

  calculateDays(overheadsAndTheirDaysReceivable, project, calculatedDebtArr);

  calculateDays(capexArr, project, calculatedDebtArr);

  // Then we'll do the corkscrew for the aged debtors
  _.forEach(calculatedCreditors.currentLiabilityMonthDtos, function(currentLiabilityMonthDto, i) {
    currentLiabilityMonthDto.value = calculatedDebtArr[i];
  });

  // NOW FOR THE CORKSCREW - Aged Debtors
  calculateTradeDebtorCorkscrew(currentLiabilityTradeCreditor, project);
}


function calculateTradeDebtorCorkscrew(currentLiabilityTradeDebtor, project) {
  // calculate closing value
  // Opening + additions - deductions = closing. This is called a CORKSCREW

  const calculatedDebtors = currentLiabilityTradeDebtor.currentLiabilityCategoryDtos
    .filter(currentLiabilityCategoryDto => currentLiabilityCategoryDto.name === "Calculated Creditors")[0];

  const agedDebtorsOpening = currentLiabilityTradeDebtor.currentLiabilityCategoryDtos
    .filter(currentLiabilityCategoryDto => currentLiabilityCategoryDto.name === "Opening Aged creditors")[0];

  const newAgedDebt = currentLiabilityTradeDebtor.currentLiabilityCategoryDtos
    .filter(currentLiabilityCategoryDto => currentLiabilityCategoryDto.name === "Additional aged creditors accrued")[0];

  const agedDebtReleased = currentLiabilityTradeDebtor.currentLiabilityCategoryDtos
    .filter(currentLiabilityCategoryDto => currentLiabilityCategoryDto.name === "Aged credit released")[0];

  const agedDebtClosing = currentLiabilityTradeDebtor.currentLiabilityCategoryDtos
    .filter(currentLiabilityCategoryDto => currentLiabilityCategoryDto.name === "Closing Aged creditors")[0];

  const closingDebtors = currentLiabilityTradeDebtor.currentLiabilityCategoryDtos
    .filter(currentLiabilityCategoryDto => currentLiabilityCategoryDto.name === "Creditors in the balance sheet")[0];

  _.forEach(currentLiabilityTradeDebtor.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos, function(currentLiabilityMonthDto, i) {


    if (i >= project.firstBalanceForecast) {
      // everything other than the first month
      agedDebtorsOpening.currentLiabilityMonthDtos[i].value = agedDebtClosing.currentLiabilityMonthDtos[i - 1].value;
    }


    agedDebtClosing.currentLiabilityMonthDtos[i].value =
      agedDebtorsOpening.currentLiabilityMonthDtos[i].value +
      newAgedDebt.currentLiabilityMonthDtos[i].value +
      agedDebtReleased.currentLiabilityMonthDtos[i].value;

    if (i >= project.firstBalanceForecast) { //don't apply to the actuals. They are set in the balance sheet.

      closingDebtors.currentLiabilityMonthDtos[i].value
        = calculatedDebtors.currentLiabilityMonthDtos[i].value + agedDebtClosing.currentLiabilityMonthDtos[i].value;
    }

  });

}


function addPercentageAndValue(value, percentageToAdd) {
  if (percentageToAdd === 0) {
    return 0;
  }

  let valueWithPercentageAdded = value + ((value / 100) * percentageToAdd);

  if (isNaN(valueWithPercentageAdded)) {
    return 0;
  } else {
    return -(valueWithPercentageAdded - value); //25k - 20k
  }
}

/**
 * TODO review this method, might want to rename it, as its actually just returning the percentage and not adding it
 * @param value
 * @param percentageToAdd
 * @returns {number}
 */
function addPercentage(value, percentageToAdd) {

  if (percentageToAdd === 0) {
    return 0;
  }

  let valueWithPercentageAdded = ((value / 100) * percentageToAdd);

  if (isNaN(valueWithPercentageAdded)) {
    return 0;
  } else {
    return valueWithPercentageAdded;
  }
}

function isWhatPercentOf(x, y) {
  return (x / y) * 100;
}

export function calculateVatCorkscrewAndTriggerBalanceUpdate(currentLiabilities, vatPackage) {
  calculateVatCorkscrew(currentLiabilities, vatPackage);

  //axios.put('currentliabilities/' + currentLiabilities[0].currentLiabilityDto.project + '/updateBalance', currentLiabilities).then(r => console.log('updated balance with VAT current liabilities'));
}

function orderCurrentLiabilityRows(vatItem) {
  const categories = vatItem[CurrentLiabilitiesType.categoryListProperty];
  const adjustments = categories.filter((category) => category.name === "Adjustment");

  vatItem[CurrentLiabilitiesType.categoryListProperty] = [
    ...categories.slice(0, 3),
    ...adjustments,
    categories.find((category) => category.name.includes("Closing"))
  ];
}

export function calculateVatCorkscrew(currentLiabilities, vatPackage) {
  if (Array.isArray(vatPackage) && vatPackage.length === 0) {
    console.log('vat rate is empty')
    return;
  }

  let currentLiabilitiesCorkScrew = currentLiabilities
    .filter(currentLiability => currentLiability.currentLiabilityDto.assumption === "VAT_LIABILITY");

  // calculate closing value
  // Opening + additions - deductions = closing. This is called a CORKSCREW
  _.forEach(currentLiabilitiesCorkScrew, function(currentLiability) {
    orderCurrentLiabilityRows(currentLiability);
    _.forEach(currentLiability.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos, function(currentLiabilityMonthDto, i) {

      if (i > getCurrentProject().firstBalanceForecast -1) {
        const categories = currentLiability.currentLiabilityCategoryDtos;

        categories[1].currentLiabilityMonthDtos[i].value = vatPackage.netVat[i];

        const closing = categories.find((category) => category.name === "Closing VAT");

        categories[0].currentLiabilityMonthDtos[i].value = ifNanThenZero(closing.currentLiabilityMonthDtos[i - 1].value);

        calculateClosingVAT(categories, i);
    }

    });
  });


  const currentLiabilitiesCorkScrewMonthly = currentLiabilities
    .filter(currentLiability => currentLiability.currentLiabilityDto.assumption === "VAT_MONTHLY_LIABILITY");

  // calculate closing value
  // Opening + additions - deductions = closing. This is called a CORKSCREW

  _.forEach(currentLiabilitiesCorkScrewMonthly, function(vatMonthlyLiability) {
    orderCurrentLiabilityRows(vatMonthlyLiability);
    _.forEach(vatMonthlyLiability.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos, function(currentLiabilityMonthDto, i) {

      if (i > getCurrentProject().firstBalanceForecast - 1) {
        const categories = vatMonthlyLiability.currentLiabilityCategoryDtos;

        categories[1].currentLiabilityMonthDtos[i].value = vatPackage.netVat[i];

        const closing = categories.find((category) => category.name === "Closing VAT");
        categories[0].currentLiabilityMonthDtos[i].value = ifNanThenZero(closing.currentLiabilityMonthDtos[i - 1].value);


        let vatPaid;
        if (categories[0].currentLiabilityMonthDtos[i].value > 0){ //if opening is negative
          vatPaid = -Math.abs(closing.currentLiabilityMonthDtos[i - parseInt(vatMonthlyLiability.currentLiabilityDto.numberOfMonths)].value);
        } else {
          vatPaid =  Math.abs(closing.currentLiabilityMonthDtos[i - parseInt(vatMonthlyLiability.currentLiabilityDto.numberOfMonths)].value);
        }

        categories[2].currentLiabilityMonthDtos[i].value = vatPaid

        calculateClosingVAT(categories, i);
      }

    });
  });


  const currentLiabilitiesCorkScrewQuarterly = currentLiabilities
    .filter(currentLiability => currentLiability.currentLiabilityDto.assumption === "VAT_QUARTERLY_LIABILITY");

  //console.log('VAT_QUARTERLY_LIABILITY', currentLiabilitiesCorkScrewQuarterly)

  // calculate closing value
  // Opening + additions - deductions = closing. This is called a CORKSCREW

  _.forEach(currentLiabilitiesCorkScrewQuarterly, function(vatQuarterlyLiability) {
    orderCurrentLiabilityRows(vatQuarterlyLiability);
    _.forEach(vatQuarterlyLiability.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos, function(currentLiabilityMonthDto, i) {

      if (i > getCurrentProject().firstBalanceForecast - 1) {
        const categories = vatQuarterlyLiability.currentLiabilityCategoryDtos;

        categories[1].currentLiabilityMonthDtos[i].value = vatPackage.netVat[i];

        const closing = categories.find((category) => category.name === "Closing VAT");
        categories[0].currentLiabilityMonthDtos[i].value = ifNanThenZero(closing.currentLiabilityMonthDtos[i - 1].value);

        let vatPaid;
        if (categories[0].currentLiabilityMonthDtos[i].value > 0){ //if opening is negative
          vatPaid = -Math.abs(closing.currentLiabilityMonthDtos[i - parseInt(vatQuarterlyLiability.currentLiabilityDto.numberOfMonths)].value);
        } else {
          vatPaid = Math.abs(closing.currentLiabilityMonthDtos[i - parseInt(vatQuarterlyLiability.currentLiabilityDto.numberOfMonths)].value);
        }

        let projectStartDate = new Date(getCurrentProject().startDate);

        if (checkDateWithOffset(projectStartDate, currentLiabilityMonthDto.monthIndex, parseInt(vatQuarterlyLiability.currentLiabilityDto.numberOfMonths))){
          categories[2].currentLiabilityMonthDtos[i].value = vatPaid;
        } else {
          categories[2].currentLiabilityMonthDtos[i].value = 0;
        }

        calculateClosingVAT(categories, i);
      }

    });
  });
}

function calculateClosingVAT(categories, i) {
  const closingCategory = categories.find((category) => category.name === "Closing VAT");

  const closingIndex = categories.indexOf(closingCategory);
  let total = 0;
  for (let j = 0; j < closingIndex; j++) {
    total += isNanReturn0(categories[j][CurrentLiabilitiesType.monthListProperty][i].value);
  }
  closingCategory[CurrentLiabilitiesType.monthListProperty][i].value = isNanReturn0(total);
}

function checkDateWithOffset(date, offsetInMonths, numberOfMonths) {
  // Create a new Date object based on the input date
  const newDate = new Date(date);
  newDate.setDate(1);
  // Add the offset in months to the new date
  newDate.setMonth(newDate.getMonth() + offsetInMonths + 1); //the plus 1 is because - if we don't were off by 1 month

  // Get the new month
  const newMonth = newDate.getMonth();

  // Define the month sets for each numberOfMonths value
  const set1 = [3, 6, 9, 0]; // April, July, October, January
  const set2 = [4, 7, 10, 1]; // May, August, November, February
  const set3 = [5, 8, 11, 2]; // June, September, December, March

  // Check the conditions based on numberOfMonths
  if (numberOfMonths === 1 && set1.includes(newMonth)) {
    return true;
  }

  if (numberOfMonths === 2 && set2.includes(newMonth)) {
    return true;
  }

  if (numberOfMonths === 3 && set3.includes(newMonth)) {
    return true;
  }

  return false;
}

function isNanReturn0(value) {
  if (isNaN(value)) {
    return 0;
  } else {
    return Math.round(value);
  }
}

/**
 * Method will take a percentage, and a whole value and then calculate the percentage value of the whole value
 * ie. 50% and 100 will return 50
 * @param percentage
 * @param valueToCalculateFrom
 */
// function calculatePercentValue(percentage, valueToCalculateFrom) {
//
//   let val = (valueToCalculateFrom / 100) * percentage;
//   if (isNaN(val)){
//     return 0;
//   } else {
//     return val;
//   }
//
// }

// function percentage(partialValue, totalValue) {
//   let val = (100 * partialValue) / totalValue;
//   if (isNaN(val) || !isFinite(val)){
//     return 0;
//   } else {
//     return val;
//   }
// }

function calculateNICandPAYE(currentLiabilities, pnl) {

  const currentLiabilitiesPaye = currentLiabilities
    .filter(currentLiability => currentLiability.currentLiabilityDto.assumption === "NIC_PAYE");


  let salaryCategories = pnl.filter(category => category.headcountId !== null);

  //total up all the salaries
  let sums = Array(72).fill(0);

  _.forEach(salaryCategories, function(salary) {

    _.forEach(salary.pnLMonthDtoList, function(pnLMonthDto, i) {

      sums[i] += pnLMonthDto.value;

    });

  });


  _.forEach(currentLiabilitiesPaye, function(currentLiability) {


    _.forEach(currentLiability.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos, function(currentLiabilityMonthDto, i) {

      currentLiability.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos[i].value = sums[i];

      if (i < getCurrentProject().firstBalanceForecast) {

        //set the value of the PAYE value
        currentLiability.currentLiabilityCategoryDtos[1].currentLiabilityMonthDtos[i].value =
          percentage(currentLiability.currentLiabilityCategoryDtos[2].currentLiabilityMonthDtos[i].value,
            currentLiability.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos[i].value);

      }

      if (i > getCurrentProject().firstBalanceForecast -1) {

        //set the value of the PAYE value
        currentLiability.currentLiabilityCategoryDtos[2].currentLiabilityMonthDtos[i].value =
          ((currentLiability.currentLiabilityCategoryDtos[0].currentLiabilityMonthDtos[i].value / 100 ) *
          currentLiability.currentLiabilityCategoryDtos[1].currentLiabilityMonthDtos[i].value );
      }

    });


  });

  // get the growth rate

  // time the total of the headcounts by the % growth rate

  // and then set the NIC and PAYE value
}

function calculateAccruals(currentLiability, pnl) {

  let accrualAdvanced = [];

  accrualAdvanced = currentLiability.filter(currentLiability => currentLiability.currentLiabilityDto.assumption === "ACCRUAL_ADVANCED");

  _.forEach(accrualAdvanced, function(accrual, i) {

    //it's now basically like a regular corkscrew
    let openingCategory = accrual.currentLiabilityCategoryDtos.find(category => category.name === "Opening"); //opening
    let adjustmentCategory = accrual.currentLiabilityCategoryDtos.find(category => category.name === "Adjustment"); //Adjustment
    let closingCategory = accrual.currentLiabilityCategoryDtos.find(category => category.name === "Closing"); //closing

    //populate the Accrual rows
    //for each row, find the matching overhead from the OverheadsAtom
    //then get the 'closing' category from that overhead
    //then copy the values to the Accruals row
    let accrualCategories = accrual.currentLiabilityCategoryDtos.filter(category => category.name === 'P&L release');
    let paymentCategories = accrual.currentLiabilityCategoryDtos.filter(category => category.name === 'Payment');

    _.forEach(accrualCategories, function(accrualCategory, i) {
      //find the matching overhead
      let accrualOverheadPnlCategory = pnl.find(pnlCategory => pnlCategory.overheadId === accrualCategory.overheadId);

      _.forEach(accrualCategory.currentLiabilityMonthDtos, function(accrualMonth, i) {
        accrualMonth.value = accrualOverheadPnlCategory.pnLMonthDtoList[i].value;
      });

    });

    let startDate = new Date(getCurrentProject().startDate);
    let projectStartMonthIndex = startDate.getMonth() + 1; // adjusting for the 0 base

    //calculate the totals - based on the 'payment frequency'  'payment month' & 'first month of accrual'
    //OK - for ANNUAL - we take the 12 months - sum them up, then place the value in the paymentMonth
    //OK - for QUARTERLY - we take the 3 months - sum them up, then place the value in the paymentMonth
    //OK - for SEMI-ANNUAL - we take the 6 months - sum them up, then place the value in the paymentMonth
    // if the start of the project is first instance February, and the firstMonthOfAccrual is January

    //then we place that value in the firstMonthOfAccrual field
    _.forEach(paymentCategories, function(paymentCategory, i) {

      let paymentFrequencyDto;

      paymentFrequencyDto = accrual.currentLiabilityDto.paymentFrequencyDtos.find(paymentFrequencyDto => paymentFrequencyDto.item === paymentCategory.overheadId);


      let paymentFrequency = paymentFrequencyDto.paymentFrequencyEnum;
      let firstMonthOfAccrual = paymentFrequencyDto.firstMonthOfAccrual; //for prepayments these are identical
      let paymentMonth = paymentFrequencyDto.paymentMonth;

      let periodLength; // Number of months between payments

      // Determine period length based on frequency
      if (paymentFrequency === 'ANNUAL') {
        periodLength = 12;
      } else if (paymentFrequency === 'SEMI_ANNUAL') {
        periodLength = 6;
      } else if (paymentFrequency === 'QUARTERLY') {
        periodLength = 3;
      } else {
        throw new Error('Invalid frequency. Must be ANNUAL, SEMI_ANNUAL, or QUARTERLY.');
      }

      let initialRange;
      let firstPaymentMonth;

      if (paymentFrequency === "SEMI_ANNUAL") {
        firstPaymentMonth = getFirstMonthInSemiAnnualGroup(projectStartMonthIndex, firstMonthOfAccrual);
      }

      if (paymentFrequency === 'QUARTERLY') {
        firstPaymentMonth = getFirstMonthInQuarterGroup(projectStartMonthIndex, firstMonthOfAccrual);
      }

      if (paymentFrequency === 'ANNUAL') {
        firstPaymentMonth = getFirstMonthInAnnualGroup(projectStartMonthIndex, firstMonthOfAccrual);
      }

      paymentMonth = firstPaymentMonth;

      if (projectStartMonthIndex === firstPaymentMonth) {
        initialRange = 1;
      } else {
        initialRange = Math.abs(firstPaymentMonth - projectStartMonthIndex) + 1;
      }

      //find the matching accrual row
      let matchingAccrualCategory = accrualCategories.find(accrualCategory => accrualCategory.overrideName === paymentCategory.overrideName);

      // ie Output: [1, 13, 25, 37, 49, 61] - if January Start Date and Annual
      const paymentIndexes = calculatePaymentIndexes(paymentFrequency, projectStartMonthIndex, paymentMonth, 78);

      //console.log(paymentIndexes)
      //console.log('initialIndex', initialIndex, 'initialRange', initialRange)

      let cutoffIndex = initialRange - 1; // e.g., if initialRange=2 => cutoffIndex=1

      //let range = initialRange;
      let total = 0;
      let totalsArray = [];

      _.forEach(matchingAccrualCategory.currentLiabilityMonthDtos, function(monthDto, i) {
        // Add current value
        if (monthDto !== undefined) {
          total += monthDto.value;
        }

        // If we've hit the cutoff, push the total and reset
        if (i === cutoffIndex) {
          totalsArray.push(Number(Math.abs(Math.round(total)).toFixed(2)));
          total = 0;
          cutoffIndex += periodLength; // move the cutoff for the next group
        }
        // If we reach the end and haven't pushed yet, push the remainder
        else if (i === matchingAccrualCategory.currentLiabilityMonthDtos.length - 1) {
          totalsArray.push(Number(Math.abs(Math.round(total)).toFixed(2)));
        }
      });


      console.log('totals array', totalsArray)

      _.forEach(paymentCategory.currentLiabilityMonthDtos, function(paymentMonth, i) {

        if (paymentIndexes.includes(paymentMonth.monthIndex)) {
          const index = paymentIndexes.indexOf(paymentMonth.monthIndex);
          paymentMonth.value = totalsArray[index];
        }
      });

    });

    // Now calculate the corkscrew
    _.forEach(openingCategory.currentLiabilityMonthDtos, function(openingMonth, i) {
      if (i > getCurrentProject().firstBalanceForecast - 1) {
        // Set opening value to previous month's closing value
        openingCategory.currentLiabilityMonthDtos[i].value = closingCategory.currentLiabilityMonthDtos[i - 1].value || 0;

        // Sum over all 'accrualCategories' for month 'i'
        let totalAccruals = _.sumBy(accrualCategories, function(accrualCategory) {
          return accrualCategory.currentLiabilityMonthDtos[i].value || 0;
        });

        // Sum over all 'paymentCategories' for month 'i'
        let totalPayments = _.sumBy(paymentCategories, function(paymentCategory) {
          return paymentCategory.currentLiabilityMonthDtos[i].value || 0;
        });

        // Calculate closing value
        closingCategory.currentLiabilityMonthDtos[i].value =
          openingCategory.currentLiabilityMonthDtos[i].value +
          totalPayments +
          totalAccruals +
          adjustmentCategory.currentLiabilityMonthDtos[i].value;
      }
    });

  });

}


/**
 * Returns the first occurrence of `firstPaymentMonthIndex` that is >= `projectStartMonthIndex`,
 * in a repeating 12-month cycle.
 *
 * Example:
 *   projectStartMonthIndex = 3 (April),
 *   firstPaymentMonthIndex = 2 (March),
 *   -> returns 14 (the “next” March in a 0-based sequence of months).
 *
 */
function getFirstMonthInAnnualGroup(projectStartMonthIndex, firstPaymentMonthIndex) {
  // Keep adding 12 until the firstPaymentMonthIndex is >= projectStartMonthIndex
  while (firstPaymentMonthIndex < projectStartMonthIndex) {
    firstPaymentMonthIndex += 12;
  }
  return firstPaymentMonthIndex;
}


function getFirstMonthInSemiAnnualGroup(projectStartMonth, firstPaymentMonth) {
  // 6 groups, each with 2 months
  // sorted in ascending order inside each group, so no extra `.sort()` needed
  const semiAnnualGroups = [
    [0, 6],  // Jan, Jul
    [1, 7],  // Feb, Aug
    [2, 8],  // Mar, Sep
    [3, 9],  // Apr, Oct
    [4, 10], // May, Nov
    [5, 11], // Jun, Dec
    [6, 12], // July, Dec
  ];

  // 1) Find which group the firstPaymentMonth belongs to
  let foundGroup = null;
  for (const group of semiAnnualGroups) {
    if (group.includes(firstPaymentMonth)) {
      foundGroup = group;
      break;
    }
  }

  if (!foundGroup) {
    throw new Error("firstPaymentMonth not found in the semi-annual groups.");
  }

  // 2) Among that group’s 2 months, pick the first that is >= projectStartMonth
  const result = foundGroup.find((m) => m >= projectStartMonth);

  // 3) If none is >= projectStartMonth, use the first element (wrap around)
  return result === undefined ? foundGroup[0] : result;
}


/**
 * projectStartMonth: number (0-based, Jan=0, Feb=1, etc.)
 * firstPaymentMonth: number (0-based, Jan=0, Feb=1, etc.)
 *
 * Returns the first month in the quarterly group (that the firstPaymentMonth
 * belongs to) which is >= projectStartMonth. If none is >=, returns
 * the smallest month in that group (wrap-around).
 */
function getFirstMonthInQuarterGroup(projectStartMonth, firstPaymentMonth) {
  // Groups already in ascending order:
  // Group A => [2 (Mar), 5 (Jun), 8 (Sep), 11 (Dec)]
  // Group B => [0 (Jan), 3 (Apr), 6 (Jul), 9 (Oct)]
  // Group C => [1 (Feb), 4 (May), 7 (Aug), 10 (Nov)]
  const quarterlyGroups = [
    [2, 5, 8, 11], // Group A (ascending)
    [0, 3, 6, 9],  // Group B
    [1, 4, 7, 10], // Group C
  ];

  // 1) Find the group containing the firstPaymentMonth
  let foundGroup = null;
  for (const group of quarterlyGroups) {
    if (group.includes(firstPaymentMonth)) {
      foundGroup = group;
      break;
    }
  }
  if (!foundGroup) {
    throw new Error("firstPaymentMonth does not appear in any quarterly group.");
  }

  // 2) Find the first month in that group >= projectStartMonth
  const result = foundGroup.find((m) => m >= projectStartMonth);

  // 3) If no month in the group is >= projectStartMonth, return the group’s first element
  return (typeof result === "undefined") ? foundGroup[0] : result;
}


export function calculatePercentageComplete(dataSetDtoList) {
  try {
    _.forEach(dataSetDtoList, function(dataSetDto) {
        dataSetDto.currentLiabilityDto.percentageComplete = 0;
        var totalNumberOfEntries = 0;
        var percentageComplete = 0

        _.forEach(
          dataSetDto.currentLiabilityCategoryDtos,
          function(categoryDto) {

            _.forEach(
              categoryDto.currentLiabilityMonthDtos,
              function(monthDto) {


                if (monthDto.value !== 0 && monthDto.cellType !== "DISABLED") {
                  percentageComplete++;
                }

                if (monthDto.cellType === "DISABLED") {
                  percentageComplete++;
                }

                totalNumberOfEntries++;

              }
            );

          }
        );
        dataSetDto.currentLiabilityDto.percentageComplete = ((percentageComplete / totalNumberOfEntries) * 100)
      }
    );
  } catch (e) {
    
  }
  
}


function calculateDeferredRevenue(currentLiabilities, products) {

  let deferredRevenue = currentLiabilities.filter(currentLiability => currentLiability.currentLiabilityDto.assumption === "DEFERRED_REVENUE");

  _.forEach(deferredRevenue, function(defrev, i) {

    //it's now basically like a regular corkscrew
    let openingCategory = defrev.currentLiabilityCategoryDtos.find(category => category.name === "Opening"); //opening
    let adjustmentCategory = defrev.currentLiabilityCategoryDtos.find(category => category.name === "Adjustment"); //Adjustment
    let closingCategory = defrev.currentLiabilityCategoryDtos.find(category => category.name === "Closing"); //closing

    //populate the Accrual rows
    //for each row, find the matching overhead from the OverheadsAtom
    //then get the 'closing' category from that overhead
    //then copy the values to the Accruals row
    let accrualCategories = defrev.currentLiabilityCategoryDtos.filter(category => category.name === 'P&L release');
    let paymentCategories = defrev.currentLiabilityCategoryDtos.filter(category => category.name === 'Payment');

    let categoriesToFind = ["Revenue", "Closing recurring revenue"]

    _.forEach(accrualCategories, function(accrualCategory, i) {

      let accrualProduct = products.find(product => product.productDto.id === accrualCategory.productId);
      let accrualOverheadPnlCategory = accrualProduct.productCategoryDtoList.find(category => categoriesToFind.includes(category.name));

      _.forEach(accrualCategory.currentLiabilityMonthDtos, function(accrualMonth, i) {
        accrualMonth.value = getValueOrOverriddenValue(accrualOverheadPnlCategory.productMonthDtoList[i]);
      });

    });


    let startDate = new Date(getCurrentProject().startDate);
    let startMonthIndex = startDate.getMonth() + 1; // adjusting for the 0 base

    //calculate the totals - based on the 'payment frequency'  'payment month' & 'first month of accrual'
    //OK - for ANNUAL - we take the 12 months - sum them up, then place the value in the paymentMonth
    //OK - for QUARTERLY - we take the 3 months - sum them up, then place the value in the paymentMonth
    //OK - for SEMI-ANNUAL - we take the 6 months - sum them up, then place the value in the paymentMonth
    // if the start of the project is first instance February, and the firstMonthOfAccrual is January

    //then we place that value in the firstMonthOfAccrual field
    _.forEach(paymentCategories, function(paymentCategory, i) {

      let paymentFrequencyDto = defrev.currentLiabilityDto.paymentFrequencyDtos.find(paymentFrequencyDto => paymentFrequencyDto.item === paymentCategory.productId);

      let paymentFrequency = paymentFrequencyDto.paymentFrequencyEnum;
      let firstMonthOfAccrual = paymentFrequencyDto.firstMonthOfAccrual; //for prepayments these are identical

      let periodLength; // Number of months between payments

      // Determine period length based on frequency
      if (paymentFrequency === 'ANNUAL') {
        periodLength = 12;
      } else if (paymentFrequency === 'SEMI_ANNUAL') {
        periodLength = 6;
      } else if (paymentFrequency === 'QUARTERLY') {
        periodLength = 3;
      } else {
        throw new Error('Invalid frequency. Must be ANNUAL, SEMI_ANNUAL, or QUARTERLY.');
      }

      //find the matching accrual row
      let matchingAccrualCategory = accrualCategories.find(accrualCategory => accrualCategory.overrideName === paymentCategory.overrideName);

      const paymentIndexes = calculatePaymentIndexes(paymentFrequency, startMonthIndex, firstMonthOfAccrual, 78);

      _.forEach(paymentCategory.currentLiabilityMonthDtos, function(prepaymentMonth, i) {

        //first lets get to the payment Month
        if (paymentIndexes.includes(prepaymentMonth.monthIndex)) {

          //we have found the first payment month (ie January)
          //now we sum up the values for the next 12/6/3 months and place it in that cell
          let count = 0; // Initialize counter

          let total = 0;

          while (count < periodLength) {

            if (matchingAccrualCategory.currentLiabilityMonthDtos[i + count] !== undefined) {
              total = total + matchingAccrualCategory.currentLiabilityMonthDtos[i + count].value;
            }

            count++; // Increment the counter
          }

          prepaymentMonth.value = -Math.abs(total);

        }

      });


      // ie Output: [1, 13, 25, 37, 49, 61] - if January Start Date and Annual
      // const paymentIndexes = calculatePaymentIndexes(paymentFrequency, startMonthIndex, firstMonthOfAccrual, 72);



    });

    // Now calculate the corkscrew
    _.forEach(openingCategory.currentLiabilityMonthDtos, function(openingMonth, i) {
      if (i > getCurrentProject().firstBalanceForecast - 1) {
        // Set opening value to previous month's closing value
        openingCategory.currentLiabilityMonthDtos[i].value = closingCategory.currentLiabilityMonthDtos[i - 1].value || 0;

        // Sum over all 'accrualCategories' for month 'i'
        let totalAccruals = _.sumBy(accrualCategories, function(accrualCategory) {
          return accrualCategory.currentLiabilityMonthDtos[i].value || 0;
        });

        // Sum over all 'paymentCategories' for month 'i'
        let totalPayments = _.sumBy(paymentCategories, function(paymentCategory) {
          return paymentCategory.currentLiabilityMonthDtos[i].value || 0;
        });

        // Calculate closing value
        closingCategory.currentLiabilityMonthDtos[i].value =
          openingCategory.currentLiabilityMonthDtos[i].value +
          totalPayments +
          totalAccruals +
          adjustmentCategory.currentLiabilityMonthDtos[i].value;
      }
    });

  });

}


function calculatePaymentIndexes(frequency, startDateMonthIndex, firstPaymentMonthIndex, maxItems = 78) {
  const indexes = [];
  let periodLength; // Number of months between payments

  // Determine period length based on frequency
  if (frequency === 'ANNUAL') {
    periodLength = 12;
  } else if (frequency === 'SEMI_ANNUAL') {
    periodLength = 6;
  } else if (frequency === 'QUARTERLY') {
    periodLength = 3;
  } else {
    throw new Error('Invalid frequency. Must be ANNUAL, SEMI_ANNUAL, or QUARTERLY.');
  }

  // Calculate months until first payment
  let monthsUntilFirstPayment = (firstPaymentMonthIndex - startDateMonthIndex + 12) % 12 + 1;

  // Calculate the index of the first payment after the project starts
  let paymentIndex = monthsUntilFirstPayment - 1;

  // Generate payment indexes
  while (paymentIndex <= maxItems) {
    indexes.push(paymentIndex); // Convert to 1-based index
    paymentIndex += periodLength;
  }

  return indexes;
}

