import axios from "axios";

export async function getVatRates(projectId, setVatRates) {

  let vatRates = [];

  const config = {
    method: "get",
    url: "vat/vatrate/" + projectId,
  };

  await axios(config)
    .then(function (response) {
      //list of products
      vatRates = response.data[0].vatCategoryDtos;
    })
    .catch(function (error) {
      console.log(error);
    });

  setVatRates(vatRates);

  return vatRates
}
