import React, {useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";

import {Divider as MuiDivider} from "@mui/material";
import {spacing} from "@mui/system";
import AddProduct from "./components/AddProduct";
import ProductInput from "./components/ProductInput";
import {calculateRevenueAndCOS, calculateRevenueSummaryTotals} from "./calculations/Calculations";
import ProductsSummary from "./components/ProductsSummary";
import Settings from "../../../../components/Settings";

import PageHeader from "../../SharedComponents/PageHeader";
import {useRecoilState, useRecoilValue} from "recoil";
import {revenueAndCostState} from "../../SharedState/RevenueAndCostState";
import {overheadsState} from "../../SharedState/OverheadsState";
import ControlsBar from "../../SharedComponents/ControlsBar";
import {headcountsState} from "../../SharedState/HeadcountsState";
import UpdatePowdrModelFullStack from "../../SharedComponents/UpdatePowdr/UpdatePowdrModelFullStack";
import {hasCellValueChanged} from "../../SharedComponents/utils/Utils";
import {calculatePercentageComplete} from "../../SharedComponents/utils/PercentageComplete";
import OverrideValueModal from "../../SharedComponents/OverrideValueModal";
import {revenueAssumptionMap} from "../../SharedComponents/AssumptionMaps";
import {revenueAndCostConsolidationState} from "../../SharedState/RevenueAndCostConsolidationState";
import {pnlState} from "../../SharedState/PnLState";
import {getCurrentProject} from "../../SharedComponents/ProjectServices";
import {ProductsType} from "../../SharedComponents/Types";


const Divider = styled(MuiDivider)(spacing);

function RevenueAndCos() {
  const updatePowdrFullStackRef = useRef();

  const [products, setProducts] = useState([]);
  const [editTableEnabled, setEditTableEnabled] = useState("");

  const [revenueAtom, setRevenueAtom] = useRecoilState(revenueAndCostState);

  const overheadAtom = useRecoilValue(overheadsState);

  const headcountsAtom = useRecoilValue(headcountsState);
  const productsAtom = useRecoilValue(revenueAndCostState);

  const revenueAndCostConsolidation = useRecoilValue(revenueAndCostConsolidationState);
  const [pnlAtom, setPnlAtom] = useRecoilState(pnlState);

  function loadProductData() {
    let products_ = structuredClone(revenueAtom);

    calculatePercentageComplete(products_, ProductsType);

    calculateRevenueAndCOS(products_, headcountsAtom, revenueAndCostConsolidation);

    setProducts(products_)

    calculateRevenueSummaryTotals(products_, pnlAtom, setPnlAtom);
  }

  function updatePorwdrModel(cellData) {
    updatePowdrFullStackRef.current.updatePowdrModelFullStack(cellData,
      products,
      "product",
      revenueAssumptionMap,
      structuredClone(overheadAtom), products);
  }

  function overrideTrigger() {
  }

  function refreshProductData(cellData) {
    if (hasCellValueChanged(cellData)) {

      calculatePercentageComplete(products, ProductsType);

      calculateRevenueAndCOS(products, headcountsAtom, revenueAndCostConsolidation);

      updatePorwdrModel(cellData);

      setRevenueAtom(structuredClone(products))
    }
  }

  useEffect(() => {
    loadProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAtom]);

  return (
    <React.Fragment>

      <PageHeader pageName={"Revenue & COS"} parentName={"Assumptions"}/>

      <Divider my={6}/>

      <ControlsBar addAssumptionElement={
        <AddProduct/>
      }/>

      <ProductsSummary products={products}/>

      {!!products &&
        products.filter(product => !(
          getCurrentProject().hideDefaultGroups === true &&
          product.productDto.products.length === 0 &&
          product.productDto.name.endsWith("(Default Product Group)")
        )).map((product) => (
          <ProductInput
            key={product.productDto.id}
            productItem={product}
            products={products}
            refreshProductData={refreshProductData}
            setEditData={setEditTableEnabled}
            editData={editTableEnabled}
            setProducts={setProducts}
            loadProductData={loadProductData}
          ></ProductInput>
        ))}

      <OverrideValueModal items={structuredClone(products)} assumptionType={ProductsType} callBack={overrideTrigger}/>

      <UpdatePowdrModelFullStack ref={updatePowdrFullStackRef}/>
      <Settings/>
    </React.Fragment>
  );
}

export default RevenueAndCos;
